import * as Gapi from '../Helpers/Gapi';

const loading = kind => ({type: 'LOADING_BANNERS'});
const loadBannersDataSuccess = banners => ({type: 'LOAD_BANNERS_SUCCESS', banners});
const loadBannerDataSuccess = banner => ({type: 'LOAD_BANNER_SUCCESS', banner});
const loadError = error => ({type: 'LOAD_ERROR', error});


export const loadBanner = (banner_path) => (dispatch,getState) => {
    dispatch(loading());
    const {context} = getState();
    Gapi.getBanner(banner_path, context.domain.replace('.', '_'))
      .then(banner => {
        dispatch(loadBannerDataSuccess(banner));
      }).catch(error => {
        console.log(error);
        dispatch(loadError(error))
      })
}


export const loadBanners = domain => dispatch => {
    if(!domain) {
      console.warn('DEVELOPER ISSUE - you are trying to load banners without domain, please check your call life cicle to this method');
    } else {
      dispatch(loading());
      Gapi.getBanners(domain.replace('.', '_'))
        .then(result => {
          dispatch(loadBannersDataSuccess({...result}));
        }).catch(error => dispatch(loadError(error)))     
    } 
}


export const setBannerForm = bannerForm => dispatch => {
    dispatch({type: 'SET_BANNER_FORM', bannerForm});
};


export const submitBannerForm = (bannerForm, domain, enqueueSnackbar, navigate) => {
  var form = bannerForm;
  delete form.submitting;
  return dispatch => {
    dispatch({type: 'SUBMITTING_BANNER_FORM_START'});
    Gapi.saveBanner(domain.replace('.', '_'), form)
      .then(banner => {
        navigate('/banners');
        enqueueSnackbar('The banner was saved successfully.', { variant: 'success' });
        dispatch({type: 'SUBMITTING_BANNER_FORM_END'});
      }).catch(error => {
        dispatch({type: 'SUBMITTING_BANNER_FORM_END'});
      });
  };
}

export const saveBanner = (banner) => (dispatch,getState) => {

}