
import React from "react";
import { Box } from '@mui/material';

import NumberInputComponent from "./NumberInputComponent";

export default ({ label, value, step, min, max, readOnly, onChange }) => {
  return (
    <>
      {label ? (
        <Box flexGrow={1}>
          <h5>{label}</h5>
        </Box>
      ) : null}
      <Box>
        <NumberInputComponent
          value={value}
          step={step}
          min={min}
          max={max}
          readOnly={readOnly}
          onChange={onChange}
        />
      </Box>
    </>
  );
};
