import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import PropTypes from "prop-types";
import React from "react";

function DomainSelector(
  { selectedDomain = "", domains = [], onChange = () => {} },
) {
  return (
    <Box sx={{ minWidth: 320 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Domain</InputLabel>
        <Select
          disabled={true} //TODO: remove once Google scope is updated and verified, from here -----------
          sx={{
            "& .MuiSelect-icon": {
              display: "none",
            },
            "& .Mui-disabled": {
              color: "black !important",
              opacity: "1 !important",
              WebkitTextFillColor: "black !important",
            },
          }}
          // to here ------------------------------------------------------------------------------------
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedDomain}
          label="Domain"
          onChange={(e) => {
            onChange(e.target.value);
          }}
        >
          {domains.slice(0, 1).map((domain) => (
            <MenuItem key={domain} value={domain}>{domain}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

DomainSelector.propTypes = {
  domains: PropTypes.arrayOf(PropTypes.string),
  selectedDomain: PropTypes.string,
  onChange: PropTypes.func,
};

export default DomainSelector;
