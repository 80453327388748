import React from 'react';
import { useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import closeFill from '@iconify/icons-eva/close-fill';
import { Icon } from '@iconify/react';

function SnackbarCloseButton({ snackbarKey }) {
    const { closeSnackbar } = useSnackbar();
  
    return (
      <IconButton size="small" color="inherit" onClick={() => closeSnackbar(snackbarKey)}>
        <Icon icon={closeFill} width={24} height={24} />
      </IconButton>
    );
}
  
export default SnackbarCloseButton;