import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const DialogUserWarn = ({open, onClose, onCancel, onConfirm}) => {
  
    return (
      <Dialog open={open} onClose={onClose} fullWidth id="dialog480">
        <DialogTitle id="alert-dialog-title" sx={{pb: 3}}>
          Are you sure you want to leave the page without saving the signature?
        </DialogTitle>
        <DialogContent sx={{pb: 0}}>
          <DialogContentText id="alert-dialog-description" sx={{color: "#212B36", fontSize: "14px"}}>
            You are about to leave the page without saving the signature.<br />
            To save the changes, cancel this action and press “Save the Signature” button. <br />
            If you confirm this action, the changes will be disregarded.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} variant="outlined" style={{
              color: "#212B36",
              borderColor: "rgba(145, 158, 171, 0.32)"
          }}>Cancel</Button>
          <Button onClick={onConfirm} autoFocus variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
}

export default DialogUserWarn;