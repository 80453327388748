const initialState = {
    path: false,
};
  
const navigation = (state = initialState, action) => {
    switch (action.type) {
        case 'RedirectTo':
            return {
                ...state, path: action.path
            }
        case 'RedirectSuccess':
            return {
                ...state, path: false
            }
        default: return state;
    }
}

export default navigation;
