import React from 'react';
import Drawer from '../../UI/Base/Drawer';
import useSettings from '../../hooks/useSettings';
import { Container, Button, Grid } from '@mui/material';
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { Link } from "react-router-dom";
import { PATH_DISCLAIMERS_NEW } from '../../Navigation';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import DisclaimerListContainer from '../../UI/containers/DisclaimerListContainer';

const Disclaimers = () => {
 
const { themeStretch } = useSettings(); 
  return (
    <Drawer>
        <Page title="SignPro | Disclaimers">
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <HeaderBreadcrumbs 
                            heading="Disclaimers"
                            action={
                                <Button
                                    variant="contained"
                                    startIcon={<Icon icon={plusFill} />}
                                    component={Link} 
                                    to={PATH_DISCLAIMERS_NEW}
                                    >
                                    New Disclaimer
                                </Button>
                            } />
                    </Grid>
                    <DisclaimerListContainer />
                </Grid>
            </Container>
            
        </Page>
    </Drawer>
  );
};

export default Disclaimers;