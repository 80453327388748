import React from 'react';
import useSettings from '../../hooks/useSettings';
import { Container, Grid, Card } from '@mui/material';
import Drawer from '../../UI/Base/Drawer';
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import DisclaimerFormContainer from '../../UI/containers/DisclaimerFormContainer';

const DisclaimersNew = () => {
    const { themeStretch } = useSettings(); 
    return (
        <Drawer title="Disclaimers">
            <Page title="SignPro | New Disclaimer">
                <Container maxWidth={themeStretch ? false : 'lg'}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <HeaderBreadcrumbs 
                                heading="Disclaimers"
                                links={[{ name: 'Disclaimers', href: "/disclaimers" }, { name: 'New Disclaimer' }]} />
                        </Grid>
                        <Grid item xs={12}>
                            <Card sx={{p: 5}}>
                                <DisclaimerFormContainer />
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </Page>
        </Drawer>
    );
};

export default DisclaimersNew;