import React, { useEffect, useState } from "react";


import NumberInputComponent from "./NumberInputComponent"
import { Box, Grid, Switch } from "@mui/material";

export default ({ label, value, onChange }) => {
  const [showAll, setShowAll] = useState(true);

  const [border, setBorder] = useState(0);

  const [left, setLeft] = useState(0);
  const [right, setRight] = useState(0);
  const [top, setTop] = useState(0);
  const [bottom, setBottom] = useState(0);

  useEffect(() => {
    let s = new Option().style;
    s.borderWidth = value;

    console.log("AQUI: ", s);

    const [bl, br, bt, bb] = [
      Number.parseInt(s.borderLeftWidth),
      Number.parseInt(s.borderRightWidth),
      Number.parseInt(s.borderTopWidth),
      Number.parseInt(s.borderBottomWidth),
    ];

    setValues(bl, br, bt, bb);
  }, [value]);

  const setValues = (left, right, top, bottom) => {
    setLeft(left);
    setRight(right);
    setTop(top);
    setBottom(bottom);
    if (left === right && left === top && left === bottom) {
      setBorder(left);
    } else {
      setBorder(0);
    }
  };

  const handleShowAll = (evt, value) => {
    setShowAll(value);
    setBorder(border);
    if (!value) {
      handleChangeAll(border, border, border, border);
    }
  };

  const handleChangeAll = (left, right, top, bottom) => {
    setValues(left, right, top, bottom);
    onChange(`${top}px ${right}px ${bottom}px ${left}px`);
  };

  const handleChangeBorder = (value) => {
    handleChangeAll(value, value, value, value);
  };
  const handleChangeLeft = (value) => {
    handleChangeAll(value, right, top, bottom);
  };
  const handleChangeRight = (value) => {
    handleChangeAll(left, value, top, bottom);
  };
  const handleChangeTop = (value) => {
    handleChangeAll(left, right, value, bottom);
  };
  const handleChangeBottom = (value) => {
    handleChangeAll(left, right, top, value);
  };

  const BorderInput = ({ label, value, onChange }) => (
    <Grid item container direction="row" alignItems="center" justifyContent="end">
      <Grid item width="64px">
        <h5>{label}</h5>
      </Grid>
      <Grid item style={{ textAlign: "right" }}>
        <NumberInputComponent value={value} step={5} min={0} max={40} onChange={onChange} />
      </Grid>
    </Grid>
  );

  return (
    <>
      <Grid
        container
        direction="column"
        spacing={0}
        style={{ paddingBottom: 8 }}
      >
        <Grid item>
          <Box display="flex" flexWrap="wrap" alignItems="center">
            <Box flexGrow={1}>
              <h5>{label}</h5>
            </Box>
            <Box marginLeft={2}>
              <span style={{fontSize: "14px"}}>More</span>
            </Box>
            <Box marginLeft={0}>
              <Switch
                checked={showAll}
                onChange={handleShowAll}
                color="primary"
                inputProps={{ "aria-label": "show All" }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item container direction="row" spacing={0}>
          <Grid item container xs={12} direction="column" spacing={1}>
            {showAll ? (
              <>
                <BorderInput
                  label="Left"
                  value={left}
                  onChange={(value) => handleChangeLeft(value)}
                />
                <BorderInput
                  label="Right"
                  value={right}
                  onChange={(value) => handleChangeRight(value)}
                />
                <BorderInput
                  label="Top"
                  value={top}
                  onChange={(value) => handleChangeTop(value)}
                />
                <BorderInput
                  label="Bottom"
                  value={bottom}
                  onChange={(value) => handleChangeBottom(value)}
                />
              </>
            ) : (
              <>
                <BorderInput
                  label="All"
                  value={border}
                  onChange={(value) => handleChangeBorder(value)}
                />
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};