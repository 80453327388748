import * as Gapi from '../Helpers/Gapi';

const getJWT = () => async dispatch => {
        const { jwt, userId, message } = await Gapi.getJWT();
        if(message) {
            dispatch({ type: 'setLoginError', message });
            Gapi.signOut().then(() => dispatch({ type: 'SignOut' }));
        } else {            
            Gapi.setUserId(userId);
            const user = Gapi.getCurrentUser();
            dispatch(setUser(user));
            dispatch(setJWT(jwt));
        }
    }

export const setEnqueueSnackbar = (enqueueSnackbar) => Gapi.setEnqueueSnackbar(enqueueSnackbar);
export const setGapiJWT = (jwt) => Gapi.setJWT(jwt);

const setJWT = (jwt, userId) => dispatch => 
    dispatch({ type: 'ChangeJWT', jwt, userId })
    
const setUser = (user) => dispatch => 
    dispatch({ type: 'SetUser', user })

const clearState = () => dispatch => {
    dispatch(setJWT(undefined))
    dispatch({ type: 'SignOut' })
}

const authSessionListener = (isSignedIn) => dispatch => {
    isSignedIn ? dispatch(getJWT()) : dispatch(clearState());
}

export const gapiLoad = () => {
   return dispatch => {     
    dispatch({type: 'LOADING_DATA', kind: 'gapi'});
    Gapi.load(
        isGapiLoaded => dispatch({ type: 'ChangeGapiState', isGapiLoaded}),
        isSignedIn => {
            dispatch({type: 'ChangeSignStatus', isSignedIn});
            dispatch(authSessionListener(isSignedIn));
        }
    );
  }
}
export const signOut = () => dispatch =>
    Gapi.signOut().then(() => dispatch({ type: 'SignOut' }));