import React from "react";
// import PropTypes from "prop-types";


import { Box, styled, ToggleButton, ToggleButtonGroup } from "@mui/material";
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: "1px solid !important",
    '&.Mui-disabled': {
      border: 0,
    },
    '&.Mui-selected': {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      backgroundColor: "inherit"
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

export default ({ label, value, onChange }) => {
  const handleChange = (event, newAlignment) => {
    if (newAlignment) onChange(newAlignment);
  };

  return (
    <>
      <Box flexGrow={1}>
        <h5>{label}</h5>
      </Box>
      <Box>
        <StyledToggleButtonGroup
          size="small"
          color="primary"
          value={value}
          exclusive
          onChange={handleChange}
        >
          <ToggleButton value="left">
            <FormatAlignLeftIcon fontSize="small" />
          </ToggleButton>
          <ToggleButton value="center">
            <FormatAlignCenterIcon fontSize="small" />
          </ToggleButton>
          <ToggleButton value="right">
            <FormatAlignRightIcon fontSize="small" />
          </ToggleButton>
        </StyledToggleButtonGroup>
      </Box>
    </>
  );
};
