import React, {useState, useEffect} from 'react';
import { connect } from "react-redux";
import { getDisclaimers } from '../../Helpers/Gapi';
import Loading from '../../Screens/Loading';
import DisclaimerList from '../components/DisclaimerList';


const mapStateToProps = ({context}) => ({context});

const DisclaimerListContainer = connect(mapStateToProps)(({context}) => {
    const [disclaimers, setDisclaimers] = useState(null);
    const [loading, setLoading] = useState(false);

    const loadDisclaimers = (domain) => {
        setLoading(true);
        getDisclaimers(domain)
            .then(result => {
                setDisclaimers(result);
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                setLoading(false);
            });
    }

    useEffect(() => {
        loadDisclaimers(context.domain);
    }, []);
    
    return (
        <>{loading ? <Loading /> :<DisclaimerList disclaimers={disclaimers} />}</>
    )
});

export default DisclaimerListContainer;
