import React, {useEffect} from 'react';
import { Table, Paper, TableBody, TableHead, TableCell, TableContainer, TableRow, Typography, Stack, Chip } from '@mui/material';
import SignatureListMoreMenu from "./components/SignatureListMoreMenu"
import Label from '../components/Label';
import { fDate } from '../utils/formatTime';
import { node } from 'prop-types';

export const SignaturesTreeView = ({ signatures, onDelete, onDuplicate, defaultSignatureKey, onRadioSelect }) => {
  const Tree = ({ path, signatures, count }) => {
    const [,key] = defaultSignatureKey.split('/');
    const nodes = Object.entries(signatures).reduce((p,[k,n]) => key === k ? [[k,n], ...p] : [...p,[k,n]],[]);
    return (
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow >
              <TableCell >
                Name
              </TableCell>
              <TableCell align='center'>
                Validity
              </TableCell>
              <TableCell align='center'>
                Status
              </TableCell>
              <TableCell align='center'>
                Users
              </TableCell>
              <TableCell ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {nodes.map(([key, nodes], index) => (
              <Node key={index} path={[path, key].join("/")} nodes={nodes} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const Node = ({ path, nodes }) => {
    const isLeaf =
      typeof nodes.html === 'string' || typeof nodes.schema === 'string';
    const isTree = !isLeaf;
    const STATUS_LIST = {inactive: "Inactive", live: "Live", unassigned: "Unassigned", scheduled: "Scheduled"}
    const [status, setStatus] = React.useState();

    // console.log("TreeView: ", nodes);

    const onDeleteClick = (evt) => {
      evt.preventDefault();
      onDelete(nodes._id, nodes);
    };

    const onDuplicateClick = (evt) => {
      evt.preventDefault();
      onDuplicate(path, nodes);
    };

    const getStatus = () => {
      if (defaultSignatureKey === nodes._id) {
        // DEFAULT SIGNATURE
        if (!nodes.usersTotal || nodes.usersTotal <= 0) {
          return STATUS_LIST.unassigned;
        } else {
          let found = nodes.aliases.find(alias => alias.currentSignature == null || alias.currentSignature == "");
          if (found)
            return STATUS_LIST.live;
          else
            return STATUS_LIST.inactive;
        }
      } else {
        // CUSTOM SIGNATURE
        let currentDate = new Date().setHours(0,0,0,0);
        if (nodes.to) {
          let toDate = new Date(nodes.to).setHours(0,0,0,0);
          if (currentDate > toDate) {
            return STATUS_LIST.inactive;
          }
        }
        if (!nodes.usersTotal || nodes.usersTotal <= 0) {
          return STATUS_LIST.unassigned;
        } else {
          if (nodes.from) {
            let fromDate = new Date(nodes.from).setHours(0,0,0,0);
            if (currentDate < fromDate) {
              return STATUS_LIST.scheduled;
            }
          } 
          
          if (!nodes.usersLive || nodes.usersLive < 1) {
            return STATUS_LIST.inactive;
          } else {
            return STATUS_LIST.live;
          }
        }
      }
    }

    useEffect(() => {
      setStatus(getStatus());
    }, []);

    const onDefaultChange = (evt) => {
      evt.preventDefault();
      if (defaultSignatureKey === nodes._id) return;
      onRadioSelect(nodes._id, nodes);
    }

    const validityPeriod = (nodes.from && nodes.to ) ? `${fDate(nodes.from)} - ${fDate(nodes.to)}` : ""

    // console.log("NODE: ", nodes);

    return (
      <>
        {isTree ? (
          <Tree path={path} signatures={nodes} />
        ) : (
          <TableRow label={path} hover={true}>
            <TableCell component="th" scope="row" padding="none" width="60%">
              <Stack direction="row" alignItems="center" spacing={2}>
                <img style={{height: "42px", width: "42px", objectFit: "cover", borderRadius: "10px"}} src={nodes.banner != null ? nodes.banner.imageUrl : 'https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty.jpg'} alt="SignPro" />
                <Stack>
                  <Typography variant="subtitle2" noWrap>{nodes.name}</Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>{nodes.banner != null ? nodes.banner.title : "No banner included"}</Typography>
                </Stack>
              </Stack>
            </TableCell>
            <TableCell width="20%" align='center'>
              {defaultSignatureKey === nodes._id ? 
                <Label color="success">
                  DEFAULT SIGNATURE
                </Label>
              : nodes.from && nodes.to ? (
                <>
                  {validityPeriod}
                </>
              ) : (<></>)}
              
            </TableCell> 
            <TableCell width="10%" align='center'>
              <Label
                color={status === STATUS_LIST.live ? 'success' : status === STATUS_LIST.inactive ? 'default' : status === STATUS_LIST.unassigned ? 'warning' : 'info'}
              >
                {status}
              </Label>
            </TableCell>   
            <TableCell width="10%" align='center'>{nodes.usersTotal ? nodes.usersTotal : 0}</TableCell>     
            <TableCell align="right">
              <SignatureListMoreMenu onDeleteClick={onDeleteClick} usersTotal={nodes.usersTotal ? nodes.usersTotal : 0} isDefault={defaultSignatureKey === nodes._id} onDuplicateClick={onDuplicateClick} path={`/${nodes._id}`} onDefaultClick={onDefaultChange} />
            </TableCell>
          </TableRow>
        )}
      </>
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table  aria-label="a table">
        <TableBody>
          <Tree path="" signatures={signatures} count={0} />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

