import React from "react";


import NumberInputComponent from "./NumberInputComponent"
import ConfiguratorColorWidget from "./ConfiguratorColorWidget";
import { Box, Grid, List, ListItem, MenuItem, OutlinedInput, Select, Typography } from "@mui/material";

const BorderWidthInput = ({ value, onChange }) => {
  return <NumberInputComponent value={value} step={1} min={0} max={40} onChange={onChange} />
};

const BorderStyleInput = ({ value, onChange }) => {
  const handleChange = (evt) => onChange(evt.target.value);

  const options = ["solid", "dashed", "dotted"];

  return (
    <Select
      labelId="border-style-select-helper-label"
      id="border-style-select-helper"
      value={value || "solid"}
      onChange={handleChange}
      size="small"
      sx={{width: "194px"}}
    >
      {options.map((option, index) => (
        <MenuItem key={index} value={option}>
          <Box style={{ width: "100%", height: "1rem" }}>
            <div
              style={{
                position: "relative",
                top: "50%",
                borderBottomWidth: "2px",
                borderBottomStyle: option,
                borderBottomColor: "#999",
              }}
            ></div>
          </Box>
        </MenuItem>
      ))}
    </Select>
  );
};

const BorderInput = ({ value, onChange }) => {
  let s = new Option().style;
  s.border = value;
  
  const width = Number.parseInt(s.borderWidth);
  const style = s.borderStyle;
  const color = s.borderColor.match("rgb")
    ? `#${s.borderColor
        .match(/\d+/g)
        .map((c) => Number.parseInt(c).toString(16).padStart(2, "0"))
        .join("")}`
    : s.borderColor;

  return (
    <List disablePadding sx={{width: "100%"}}>
      <ListItem sx={{paddingRight: "0px", paddingLeft: "24px"}}>
        <Box flexGrow={1}>
          <Typography variant="body2">Line width</Typography>
        </Box>
        <Box>
          <BorderWidthInput
            value={width}
            onChange={(width) => {
              onChange(`${width}px ${style} ${color}`);
            }}
          />
        </Box>
      </ListItem>
      <ListItem sx={{paddingRight: "0px", paddingLeft: "24px"}}>
        <Box flexGrow={1}>
          <Typography variant="body2">Line style</Typography>
        </Box>
        <Box>
          <BorderStyleInput
            value={style}
            onChange={(style) => {
              onChange(`${width}px ${style} ${color}`);
            }}
          />
        </Box>
      </ListItem>
      <ListItem sx={{paddingRight: "0px", paddingLeft: "24px"}}>
        <Box flexGrow={1}>
          <Typography variant="body2">Line colour</Typography>
        </Box>
        <Box>
          <ConfiguratorColorWidget 
            value={color}
            onChange={(color) => {
              onChange(`${width}px ${style} ${color}`);
            }}
          />
        </Box>
      </ListItem>
    </List>
  );
};


export default ({ label, value, onChange }) => {
  return (
    <>
      <Grid
        container
        direction="column"
        spacing={2}
      >
        <Grid item>
          <Box display="flex" flexWrap="wrap" alignItems="center">
            <h5 style={{marginBottom: ".5em"}}>{label}</h5>
          </Box>
        </Grid>
        
        <Grid item container direction="row" spacing={2}>
          <BorderInput value={value} onChange={onChange} />
        </Grid>
      </Grid>
    </>
  );
};