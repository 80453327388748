import { combineReducers } from 'redux'
import context from './Context'
import companyForm from './CompanyForm'
import bannerForm from './BannerForm'
import auth from './Auth'
import banners from './Banners'
import navigation from './Navigation'

export default combineReducers({
  auth,
  navigation,
  context,
  companyForm,
  bannerForm,
  banners
})