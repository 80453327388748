import React from 'react';

import { CssBaseline, Grid, Container, CircularProgress } from '@mui/material';

export default () => {
  return (<Container component="main" maxWidth="xs">
  <CssBaseline />
  <Grid 
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justify="center"
    style={{ minHeight: '100vh' }}
  >
    <CircularProgress />
  </Grid>
</Container>)
}
  
