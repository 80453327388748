import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";
import { useSnackbar } from 'notistack';
import * as Gapi from '../../Helpers/Gapi';
import DisclaimerDetails from '../components/DisclaimerDetails';

const mapStateToProps = ({context}) => ({context});

const DisclaimerDetailsContainer = connect(mapStateToProps)(({context, disclaimerPath}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [disclaimer, setDisclaimer] = useState({
        text: "",
        title: ""
    });

    const loadDisclaimer = () => {
        setLoading(true);
        Gapi.getDisclaimer(context.domain, disclaimerPath)
            .then((disclaimer) => {
                setLoading(false);
                setDisclaimer(disclaimer);
            }).catch(error => { 
                setLoading(false);
                enqueueSnackbar('Error loading disclaimer.', { variant: 'error' });
                console.error("GET DISCLAIMER ERROR: ", error);
            });
    }

    useEffect(() => {
        loadDisclaimer();
    }, [context.domain]);

    return (
        <DisclaimerDetails disclaimer={disclaimer} loading={loading} />
    )
});

export default DisclaimerDetailsContainer;