import React from 'react'
import PropTypes from 'prop-types'
import Drawer from '../../UI/Base/Drawer';
import Page from '../../components/Page';
import { useParams } from 'react-router-dom';
import {CircularProgress, Box, Grid, Card, Avatar, TextField, Typography, Stack, Container } from '@mui/material';
import Label from '../../components/Label';
import { userList } from '../Users/logic/data';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { setDefaultSignaturePath } from '../../Helpers/Gapi';

import * as Gapi from '../../Helpers/Gapi';
import { connect } from 'react-redux';
import useSettings from '../../hooks/useSettings';
import { fDate } from '../../utils/formatTime';


const load = (userId, domain) =>{
    
    return Gapi.getUserProfile(userId, domain)
    // .then(userList)
    // .then(users => users.find(({id}) => id === userId))
}


function SingleUser({context}) {
    const { id } = useParams();
    const [user, setUser] = React.useState(false);
    const [defaultSignatureEnabled, setDefaultSignatureEnabled] = React.useState(false);
    const [appliedSignatures, setAppliedSignatures] = React.useState([]);
    const STATUS_LIST = {inactive: "Inactive", live: "Live", unassigned: "Unassigned", scheduled: "Scheduled", default: "Default"}
    const { themeStretch } = useSettings(); 

    const splitAppliedSignatures = () => {
        if (!user) return;
        var signatures = [];
        if (defaultSignatureEnabled) {
            signatures.push(getDefaultSignature(user.defaultSignature))
        }
        user.assignments.forEach(assignment => {
            if (!assignment) return;
            var _signature = {...assignment.signature, status: getAssignmentStatus(assignment.status)};
            _signature = _getBannerModel(_signature);
            signatures.push(_signature);
        });

        console.log("SIGNATURES: ", signatures);
        setAppliedSignatures(signatures);
    }

    const getDefaultSignatureEnabled = () => {
        if (!user) return;
        Gapi.getDefaultSignatureUsers(context.domain)
                .then(result => setDefaultSignatureEnabled(result.users.indexOf(getAlias(user.email)) !== -1))
    }

    const getDefaultSignature = (defaultSignature) => {
        defaultSignature = {...defaultSignature, status: STATUS_LIST.default};
        return _getBannerModel(defaultSignature);
    };

    const getAssignmentStatus = (assignmentStatus) => {
        switch(assignmentStatus) {
            case "pending":
                return STATUS_LIST.inactive;
            case "scheduled":
                return STATUS_LIST.scheduled;
            case "active":
                return STATUS_LIST.live;
            case "expired":
                return STATUS_LIST.inactive;
        }
    };
      
    const _getBannerModel = (signature) => {
        const schema = JSON.parse(signature.schema);
        const banner = _findBanner(schema);
        return {...signature, banner: banner};
    }
    
    const _findBanner = (theObject) => {
        var result = null;
        if (theObject instanceof Array) {
            for (var i = 0; i < theObject.length; i++) {
                result = _findBanner(theObject[i]);
                if (result) {
                    break;
                }
            }
        } else {
            for (var prop in theObject) {
                if (prop == 'name') {
                    if (theObject[prop] == "Banner") {
                    return theObject.value;
                    }
                }
                if (theObject[prop] instanceof Object || theObject[prop] instanceof Array) {
                    result = _findBanner(theObject[prop]);
                    if (result) {
                    break;
                    }
                }
            }
        }
        return result;
    }

    const getValidityPeriod = (signature) => {
        return (signature.from && signature.to) ? `${fDate(signature.from)} - ${fDate(signature.to)}` : ""
    }

    const getAlias = (email) => {
        if (!email) return "";
        const alias = email.split("@")[0];
        return `${alias}@${context.domain}`;
    }

    React.useEffect(() => 
        context.domain && load(id, context.domain)
            .then(setUser)
    ,[context.domain]);

    React.useEffect(() => {getDefaultSignatureEnabled(); splitAppliedSignatures();},[user]);

    React.useEffect(() => splitAppliedSignatures(), [defaultSignatureEnabled])

    return (
        <Drawer Gapi={Gapi} signOut={Gapi.signOut} title="Users">
            <Page title="SignPro | User Profile">
                <Container maxWidth={themeStretch ? false : 'lg'}>
                    <HeaderBreadcrumbs
                        heading="User Profile"
                        links={[{ name: 'Users', href: "/users" }, { name: 'User Profile' }]}
                    />
                { !user ? (
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress />
                    </Box>
                ) :(
                    <> 
                        <Grid container spacing={3} sx={{alignItems: "stretch"}}>
                            <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
                                <Card sx={{ py: 5, px: 3, textAlign: 'center', height: '100%', display: "flex" }}>
                                    <Avatar alt="User Avatar" imgProps={{referrerPolicy:"no-referrer"}} src={user.userProfilePicture} sx={{ width: 128, height: 128, margin: "auto" }} />
                                </Card>
                            </Grid>
                            <Grid item xl={9} lg={8} md={8} sm={6} xs={12}>
                                <Card sx={{ py: 5, px: 3, textAlign: 'center' }}>
                                    <Grid container spacing={3}>
                                        <Grid item md={6} sm={12}>
                                            <TextField
                                                label="Name"
                                                defaultValue={user.userName}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                sx={{width: "100%"}}
                                                />
                                        </Grid>
                                        <Grid item md={6} sm={12}>
                                            <TextField
                                                label="Last name"
                                                defaultValue={user.lastName}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                sx={{width: "100%"}}
                                                />
                                        </Grid>
                                        <Grid item md={6} sm={12}>
                                            <TextField
                                                label="Job Title"
                                                defaultValue={user.jobTitle}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                sx={{width: "100%"}}
                                                />
                                        </Grid>
                                        <Grid item md={6} sm={12}>
                                            <TextField
                                                label="Department"
                                                defaultValue={user.orgUnit}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                sx={{width: "100%"}}
                                                />
                                        </Grid>
                                        <Grid item md={6} sm={12}>
                                            <TextField
                                                label="Organisational Unit"
                                                defaultValue={user.orgUnit}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                sx={{width: "100%"}}
                                                />
                                        </Grid>
                                        <Grid item md={6} sm={12}>
                                            <TextField
                                                label="Email Address"
                                                defaultValue={getAlias(user.email)}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                sx={{width: "100%"}}
                                                />
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} mt={5}>
                            <Grid item xs={12}>
                                <Card sx={{ py: 5, px: 3 }}>
                                    <Typography variant="subtitle1">Applied Signatures</Typography>
                                    <Grid container spacing={3} mt={5} px={10}>
                                        {appliedSignatures.map(signature => 
                                            <Stack key={signature.name} direction="row" alignItems="center" spacing={2} sx={{px: 3, width: "100%", mb: 3}}>
                                                <Grid item xs={6}>
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        <img style={{height: "42px", width: "42px", objectFit: "cover"}} src={signature.banner != null ? signature.banner.imageUrl : 'https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty.jpg'} alt="SignPro" />
                                                        <Stack>
                                                            <Typography variant="subtitle2" noWrap>{signature.name}</Typography>
                                                            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>{signature.banner != null ? signature.banner.link : ""}</Typography>
                                                        </Stack>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={3} textAlign="center">
                                                    {signature.status === STATUS_LIST.default ?
                                                        <Label
                                                            color='success'
                                                        >
                                                            DEFAULT SIGNATURE
                                                        </Label>
                                                    : 
                                                        <>
                                                            {getValidityPeriod(signature)}
                                                        </>
                                                    }
                                                </Grid>
                                                <Grid item xs={3} textAlign={"right"}>
                                                    {signature.status === STATUS_LIST.default ?
                                                        appliedSignatures.find(signature => signature.status === STATUS_LIST.live) ? 
                                                        <Label
                                                            color='default'
                                                        >
                                                            Inactive
                                                        </Label>
                                                        :
                                                        <Label
                                                            color='success'
                                                        >
                                                            Live
                                                        </Label>
                                                    : 
                                                        <Label
                                                            color={signature.status === STATUS_LIST.live ? 'success' : signature.status === STATUS_LIST.inactive ? 'default' : 'info'}
                                                        >
                                                            {signature.status}
                                                        </Label>
                                                    }
                                                </Grid>
                                            </Stack>
                                        )}
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                    </>

                )}
                </Container>
            </Page>
        </Drawer>
    )
}



SingleUser.propTypes = {}

const mapStateToProps = ({context}) => ({context});
export default connect(mapStateToProps)(SingleUser);