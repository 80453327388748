import React, {useState, useEffect} from 'react';
import { connect } from "react-redux";
import DisclaimerForm from '../components/DisclaimerForm';
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";

import * as Gapi from '../../Helpers/Gapi';
const mapStateToProps = ({context}) => ({context});

const DisclaimerEditFormContainer = connect(mapStateToProps)(({context, disclaimerPath}) => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [disclaimer, setDisclaimer] = useState({
        text: "",
        title: ""
    });
    const [submitting, setSubmitting] = useState(false);

    const loadDisclaimers = (domain) => {
        Gapi.getDisclaimers(domain)
            .then(result => {
                console.log("disclaimers: ", result);
                setDisclaimer(result[disclaimerPath]);
            })
            .catch(error => {
                console.error(error);
                enqueueSnackbar('Error getting disclaimer.', { variant: 'error' });
            });
    }

    useEffect(() => {
        loadDisclaimers(context.domain);
    }, []);

    const submit = () => {
        setSubmitting(true);
        console.log("Submit", context.domain)
        Gapi.updateDisclaimer(context.domain, disclaimerPath, disclaimer)
            .then((result) => {
                setSubmitting(false);
                enqueueSnackbar('The disclaimer was saved successfully.', { variant: 'success' });
                navigate('/disclaimers');
            }).catch(error => { 
                setSubmitting(false);
                enqueueSnackbar('Error saving disclaimer.', { variant: 'error' });
                console.error("SAVE DISCLAIMER ERROR: ", error);
            });
    }

    return (
        <DisclaimerForm disclaimer={disclaimer} setDisclaimer={setDisclaimer} submit={submit} submitting={submitting} />
    )
});

export default DisclaimerEditFormContainer;