import CloseIcon from '@mui/icons-material/CloseRounded';
import { LoadingButton } from '@mui/lab';
import {
    Divider, Modal, Typography
} from '@mui/material';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
    getStripeCheckoutSession
} from '../../../Helpers/Gapi';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  outline: 'none',
  borderRadius: 2,
  width: 350
};

const ModalTrialExpiring = ({ open, domain, handleClose, trialEnd }) => {
  const [loading, setLoading] = useState(false);

  const onButtonClick = async () => {
    setLoading(true);

    await getStripeCheckoutSession(domain, {
      successUrl: `${window.location.origin}/company`,
      cancelUrl: `${window.location.origin}/company`
    }).then((res) => (window.location.href = res.checkoutUrl));

    setLoading(false);
  };

  const handleCloseWrapper = () => {
    localStorage.setItem('trialExpiringDismissed', 'true');
    handleClose();
  };

  return (
    <Modal
      /**
       * TODO: avoid relying on BackdropProps since it is deprecated
       * slotProps.backdrop should be used but might be bugged in current MUI version
       * @reference https://github.com/mui/material-ui/pull/35140
       */
      BackdropProps={{ invisible: true }}
      sx={{
        background:
          'linear-gradient(256.42deg, rgba(22, 28, 36, 0.4) 0%, rgba(22, 28, 36, 0.192) 91.29%)'
      }}
      open={open}
      aria-labelledby="modal-modal-title"
    >
      <Box
        sx={{
          ...style
        }}
      >
        <Box
          position="relative"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            p: 2,
            boxShadow: '-20px 20px 40px rgba(145, 158, 171, 0.12)',
            borderRadius: 2
          }}
        >
          <Box px={8}>
            <img
              alt="trial expiring"
              src="/static/illustrations/illustration_trial_expired.png"
            />
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h2"
            textAlign="center"
          >
            Enjoying using SignPro?
          </Typography>
          <Typography textAlign="center" color="primary" fontSize={14}>
            Please subscribe to keep using the app. <br />
            Your Free Trial will expire on <br />
            {new Date(trialEnd).toLocaleDateString('en-GB', {
              day: 'numeric',
              month: 'long',
              year: 'numeric'
            })}
          </Typography>
          <Divider sx={{ mx: 2, backgroundColor: 'text.secondary' }} />
          <Typography
            textAlign="center"
            color="text.secondary"
            fontSize={14}
            mt={1}
          >
            Please add your payment details and <br />
            subscribe. You will be charged <br />
            monthly only €0.89 per each Google <br />
            Workspace user.
          </Typography>
          <LoadingButton
            loading={loading}
            onClick={onButtonClick}
            variant="contained"
            color="primary"
            size="large"
            sx={{ textTransform: 'none', my: 1.5, mx: 'auto' }}
          >
            Subscribe to SignPro
          </LoadingButton>
          <CloseIcon
            size="small"
            onClick={handleCloseWrapper}
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              color: 'primary.darker',
              cursor: 'pointer',
              '&:hover': {
                color: 'primary.main'
              }
            }}
          />
        </Box>
      </Box>
    </Modal>
  );
};

ModalTrialExpiring.propTypes = {
  open: PropTypes.bool.isRequired,
  domain: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  trialEnd: PropTypes.number.isRequired
};

export default ModalTrialExpiring;
