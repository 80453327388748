import React from 'react';
import Drawer from '../UI/Base/Drawer';
import useSettings from '../hooks/useSettings';
import { Container, Grid} from '@mui/material';
import Page from '../components/Page';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import { useParams } from "react-router-dom";
import BannerDetailsContainer from '../UI/containers/BannerDetailsContainer';


const BannerDetails = () => {
    let { bannerPath } = useParams(); 
    const { themeStretch } = useSettings(); 
  return (
    <Drawer>
        <Page title="SignPro | Banner Details">
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <HeaderBreadcrumbs 
                            heading="Banner Details"
                            links={[{ name: 'Banners', href: "/banners" }, { name: 'Banner details' }]} />
                    </Grid>
                    <Grid item xs={12}>
                        <BannerDetailsContainer bannerPath={bannerPath} />
                    </Grid>
                </Grid>
            </Container>
            
        </Page>
    </Drawer>
  );
};

export default BannerDetails;
