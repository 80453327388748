import React, { useState, useEffect, useRef } from 'react';
import _isArray from 'lodash/isArray';
import Drawer from '../../UI/Base/Drawer';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Icon } from '@iconify/react';

import UsersTable from './UsersTable';
import Page from '../../components/Page';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import UsersToolBar from './UsersToolBar';
import UsersOperations from './UsersOperations';
import UsersFilters from './UsersFilters';
import FilterChips from './FilterChips';

import { useSnackbar } from 'notistack';



import { applyFilter } from './logic/filter';
import { prepareModelUsers, userList } from './logic/data';
import debounce from 'lodash.debounce';

import roundClearAll from '@iconify/icons-ic/round-clear-all';

import * as Gapi from '../../Helpers/Gapi';
import { connect } from 'react-redux';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { Card, Container } from '@mui/material';
import useSettings from '../../hooks/useSettings';



const Users = ({ context }) => {
  //TODO REMOVE THIS HARDCODED STATE
  const [domain, setDomain] = useState(context.domain);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [defaultSignature, setDefaultSignature] = useState(null);
  const [filter, setFilter] = useState({name: '', jobTitleList: [], departmentList: [], orgUnitList: []});
  const [signatures, setSignatures] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [defaultSignatureLoading, setDefaultSignatureLoading] = useState(false);
  const [defaultSignatureUsers, setDefaultSignatureUsers] = useState([]);
  const [nextSelectedUsers, setNextSelectedUsers] = useState([]);
  const [tabValueSelected, setTabValueSelected] = React.useState('default');
  const [signaturesInUse, setSignaturesInUse] = useState([]);

  const tableRef = useRef();


  const { enqueueSnackbar } = useSnackbar();
  const success = async (response) => {
    // enqueueSnackbar('Success');
    enqueueSnackbar('Success', {
      variant: 'success'
    })
    return response;
  }


  const actions = {
    toogleFilters: () => setShowFilters(true),
    apply:  async ({selectedUsers, signature}) =>  
            await Gapi.applySignatureToUsers(selectedUsers.map(({primaryEmail}) => 
                  primaryEmail), signature._id, domain)
                  .then(enqueueSnackbar('The signature was applied successfully to selected users.', {
                    variant: 'success'
                  }))
                  .then(debounce(reload,300))
                  .catch(error => {
                    enqueueSnackbar('Signature application to selected users was unsuccessful. Please try again later!', {
                      variant: 'error'
                    })
                    console.error('applySignatureToUsers Error:', error);
                  }),
    unapply: async ({selectedUsers, signature}) => 
            await Gapi.unapplySignatureFromUsers(selectedUsers.map(({primaryEmail}) => 
                  primaryEmail), signature._id, domain)
                  .then(enqueueSnackbar('The signature was unapplied successfully from selected users.', {
                    variant: 'success'
                  }))
                  .then(debounce(reload,300))
                  .catch(error => {
                    enqueueSnackbar('The unapplying of the signature from selected users was unsuccessful. Please try again later!', {
                      variant: 'error'
                    })
                    console.error('unapplySignatureFromUsers Error:', error);
                  }),
    selectall: () => tableRef.current.selectAll(),
    deselectall: () => tableRef.current.unselectAll(),
    search: (name) => setFilter({...filter,name})
  }

  const disableDefaultSignature = async () => {
    setDefaultSignatureLoading(true);
    const primaryEmails = selectedUsers.map(({primaryEmail}) => primaryEmail)
    await Gapi.disableDefaultSignatureToUsers(domain, primaryEmails)
      .then(result => setDefaultSignatureUsers(result.users))
      .then(enqueueSnackbar('Default Signature was disabled successfully.', {
        variant: 'success'
      }))
      .then(setDefaultSignatureLoading(false))
      .catch(error => {
        enqueueSnackbar('Default Signature disabling was unsuccessful. Please try again later!', {
          variant: 'error'
        })
        console.error('disableDefaultSignature Error:', error);
      })
  }

  const enableDefaultSignature = async () => {
    setDefaultSignatureLoading(true);
    const primaryEmails = selectedUsers.map(({primaryEmail}) => primaryEmail)
    await Gapi.enableDefaultSignatureToUsers(domain, primaryEmails)
      .then(result => setDefaultSignatureUsers(result.users))
      .then(enqueueSnackbar('Default Signature was enabled successfully to selected users.', {
        variant: 'success'
      }))
      .then(setDefaultSignatureLoading(false))
      .catch(error => {
        enqueueSnackbar('Default Signature application was unsuccessful. Please try again later!', {
          variant: 'error'
        })
        console.error('enableDefaultSignature Error:', error);
      })
  }

  const handleAction = (action, values = null) =>{
    actions[action] ? 
    actions[action](values) : 
    console.error(`action ${action} not defined in Users`);
  }

  const reload = (response = false) =>{

    if(response) console.log('reload on response', response) 
    Promise.all([
      Gapi.getSignatures(domain),
      Gapi.getGsuiteUsers().then(userList),
      Gapi.getSignaturesInUse(domain),
      Gapi.getDefaultSignaturePath(domain),
      Gapi.getDefaultSignatureUsers(domain).then(result => setDefaultSignatureUsers(result.users))
    ])  
    .then(([signatures, users ,_signaturesInUse, defaultPath]) => {
        setSignaturesInUse(_signaturesInUse);
        setDefaultSignature(defaultPath);
        const preparedUsers = prepareModelUsers([signatures, users ,_signaturesInUse, defaultPath]);
        console.log(preparedUsers);
        console.log("PREPARE USERS: ", signatures, users, _signaturesInUse, preparedUsers, selectedUsers);
        setUsers(preparedUsers); 
        setNextSelectedUsers(selectedUsers.map((user) => preparedUsers.find((preparedUser) => user.id === preparedUser.id )));
        setSignatures(signatures);
       
       // setSelectedUsers([])
       // tableRef && tableRef.current && tableRef.current.unselectAll()
    })}
  
  useEffect(() => domain && reload(),[domain]);
  useEffect(() => { 
    setSelectedUsers([]);
    resetFilters();
    resetSearch();
    setDomain(context.domain);
  },[context.domain]);
  useEffect(() => {
    if (nextSelectedUsers && nextSelectedUsers.length > 0) {
      setSelectedUsers(nextSelectedUsers);
      setTabValueSelected('current');
    }
  },[signatures]);
  useEffect(
    () => setFilteredUsers(applyFilter(filter, users)
  ),[filter, users])

  const { themeStretch } = useSettings();

  const resetSearch = () => setFilter({...filter, name: ''});
  const resetFilters = () => setFilter({...filter, jobTitleList: [], departmentList: [], orgUnitList: []});

  return (
    <Drawer>
      <Page title="SignPro | Users">
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <Grid container alignItems="center">
            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
              <HeaderBreadcrumbs heading="Users"/>
            </Grid>
          </Grid>
          { users.length === 0 ? 
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>
            : <>
                <SwipeableDrawer
                  anchor={"right"}
                  open={showFilters}
                  onClose={(a) => setShowFilters(false)}
                  onOpen={(a) => console.log(a)}
                >
                  <Grid
                    sx={{width: "260px"}}
                    >
                      <Grid
                    sx={{height: "85vh", display: "flex", flexDirection: "column", overflow: "auto"}}
                    >
                      <UsersFilters 
                        domain={domain} 
                        onChange={({jobTitleList, departmentList, orgUnitList}) => setFilter({...filter, jobTitleList, departmentList, orgUnitList})} 
                        users={users} 
                        filter={filter} 
                        handleClose={() => setShowFilters(false)}
                        >
                      </UsersFilters>
                    </Grid>
                    <Grid
                    sx={{height: "15vh", display: "flex", flexDirection: "column", overflow: "auto", justifyContent: "center", mx: 2}}
                    >
                      <Button
                        fullWidth
                        size="large"
                        color="inherit"
                        variant="outlined"
                        onClick={() => {
                          resetFilters();
                          setShowFilters(false);
                        }}
                        startIcon={<Icon icon={roundClearAll} />}
                      >
                        Clear
                      </Button>
                    </Grid>
                  </Grid>
                </SwipeableDrawer>        
                <Stack spacing={5}> 
                  <Card>
                    <UsersToolBar action={handleAction}></UsersToolBar>
                    <FilterChips filter={filter} onChange={({jobTitleList, departmentList, orgUnitList}) => setFilter({...filter, jobTitleList, departmentList, orgUnitList})}  />
                    <UsersTable ref={tableRef} domainSelected={domain} users={filteredUsers} onSelectedUsers={setSelectedUsers} defaultSignatureUsers={defaultSignatureUsers}></UsersTable>
                  </Card>
                  { selectedUsers.length === 0  ||
                    <Card>
                      <UsersOperations 
                        tabValueSelected={tabValueSelected}
                        setTabValueSelected={setTabValueSelected}
                        selectedUsers={selectedUsers} 
                        signatures={signatures} 
                        signaturesInUse={signaturesInUse}
                        defaultSignature={defaultSignature}
                        onDisableDefaultSignature={disableDefaultSignature}
                        onEnableDefaultSignature={enableDefaultSignature}
                        domain={domain} 
                        defaultSignatureLoading={defaultSignatureLoading}
                        action={handleAction}>  
                      </UsersOperations>
                    </Card>
                  }    
                </Stack>   
            </> 
            }
        </Container>
      
      </Page>
    </Drawer>
  );
};

const mapStateToProps = ({context}) => ({context});
export default connect(mapStateToProps)(Users);
