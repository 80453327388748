import { Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import React, {useEffect, useState} from 'react';
import { UploadSingleFile } from '../../components/upload';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";




export default function BannerForm({formData, context, setFormData, submitForm, domainSelected, warnUserChanges, userConfirmNavigation}) {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [imagePreview, setImagePreview] = useState('');
    const [errors, setErrors] = useState({
        title: null,
        link: null,
        image: null
    });

    const getBase64 = (file) => new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const setImageBase64 = async (acceptedFiles) => {
        if (acceptedFiles[0]) {
            let imageFile = acceptedFiles[0];
            setImagePreview(URL.createObjectURL(imageFile));

            let imageBase64 = await getBase64(imageFile);
            setFormData({...formData, image: imageBase64});
        }
    }

    const validateAndSubmit = () => {
        console.log("validateAndSubmit", formData);
        var submit = true;
        var _errors = {
            title: null,
            link: null,
            image: null
        };
        if (formData.title === "") {
            _errors.title = "This field must be filled.";
            submit = false;
        } 

        if (formData.link !== "" && !validateUrl(formData.link)) {
            _errors.link = "The Target URL is not in the correct format. Must include http:// or https://";
            submit = false;
        }

        if (!formData.image) {
            _errors.image = "This field must be filled.";
            submit = false;
        }
        
        if (!submit) {
            setErrors({..._errors});
        } else {
            userConfirmNavigation();
            submitForm(formData, domainSelected, enqueueSnackbar, navigate );
        }
    }

    function validateUrl(value) {
        return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
    }

    const [isAutomaticUpdate, setIsAutomaticUpdate] = React.useState(true);

    useEffect(() => { 
        if (formData.submitting) {
            setIsAutomaticUpdate(true);
            return;
        }
        if (isAutomaticUpdate) {
            setIsAutomaticUpdate(false);
            return;
        }
        if (context && !context.warnUserChanges) warnUserChanges(); 
     }, [formData]);

    return (
        <Grid>
            <TextField
                name="title"
                label="Banner title"
                error={errors.title !== null}
                helperText={errors.title}
                fullWidth
                value={formData.title}
                required={true}
                onChange={(evt) => {
                setFormData({...formData, title: evt.target.value})
                }}
                sx={{mb: 4}}
            />
            <TextField
                name="link"
                label="Target URL"
                error={errors.link !== null}
                helperText={errors.link}
                type="url"
                fullWidth
                value={formData.link}
                required={false}
                onChange={(evt) => {
                setFormData({...formData, link: evt.target.value})
                }}
                sx={{mb: 3}}
            />

            <FormControlLabel
            control={
                <Checkbox
                    checked={formData.track}
                    onChange={(evt) => {
                        setFormData({...formData, track: !formData.track})
                    }}
                    disabled={!formData.link || formData.link.length === 0}
                    value={`track`}
                    color="primary"
                />
            }
            label="Track the banner"
            sx={{mb: 3}}
            />
            
            <Typography>Image *</Typography>
            {errors.image !== null && <Typography color="error" variant='caption'>{errors.image}</Typography>}
            <UploadSingleFile 
                maxSize={3145728}
                accept=".jpeg, .jpg, .png, .gif"
                file={imagePreview}
                onDrop={setImageBase64}/>

            <Grid sx={{mt: 4, textAlign: "right"}}>
                <LoadingButton sx={{px: 5}} onClick={validateAndSubmit} variant="contained" loading={formData.submitting}>
                    Save
                </LoadingButton>
            </Grid>
        </Grid>
    );
}
