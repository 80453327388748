import PropTypes from 'prop-types';
import React from 'react';

import { Box, Container, styled, Typography } from '@mui/material';
import { signIn } from '../Helpers/Gapi';
import GoogleSignIn from '../UI/GoogleSignIn';

const ContentStyle = styled('div')(() => ({
  maxWidth: 681,
  margin: 'auto',
  display: 'flex',
  textAlign: 'center',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: 0
}));

const NonGW = ({ redirectTo }) => {
  const signInWrapper = async () => {
    await signIn();
    redirectTo('/company');
  };

  return (
    <Container>
      <ContentStyle>
        <Box
          sx={{
            mb: 3,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 2
          }}
        >
          <Typography variant="h3">
            Dear guest, <br />
            unfortunately log in has failed :(
          </Typography>
          <Typography variant="body1" sx={{ color: 'text.secondary' }}>
            To log in to app.signpro.app, You need to have Google Workspace
            administrator account.
          </Typography>
          <GoogleSignIn sx={{ width: '495px', mt: 3 }} onClick={signInWrapper}>
            Use another account
          </GoogleSignIn>
        </Box>
      </ContentStyle>
    </Container>
  );
};

NonGW.propTypes = {
  redirectTo: PropTypes.func.isRequired
};

export default NonGW;
