import React from "react";
import { AppBar, Tabs, Tab, Typography, Box, Grid, FormControlLabel, TextField, Checkbox } from '@mui/material';
import UserSelect from "./UserSelect";
import { MobileDatePicker } from '@mui/lab';


const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </Typography>
  );
}

// const SignatureTab = ({label, children}) => {
//   // TODO
//   return <div>TODO</div>
// }

const enabledUsers = (users) => {
  let enabledUsers = users.filter(user => user.suspended !== true);
  return enabledUsers.map((user) => ({...user,label:user.name.fullName}) );
}

export default ({ onUserSelected, isDefault, conversions, setSelectedDates, selectedDates, currentNameError, signatureName, setSignatureName, tabPosition, setTabPosition, showSelectedDates, setShowSelectedDates, usersTotal, selectedUsers, setSelectedUsers, selectedAliases, setSelectedAliases }) => {
  const users = enabledUsers(usersTotal);
  const user = users.find((user) => user.primaryEmail === conversions.email);
  const [
    dateDisabled, 
    // setDateDisabled
  ] = React.useState(false);
  
  const handleChange = (event, newValue) => {
    setTabPosition(newValue);
  };

  return (
    <div>
      <AppBar position="static" color="transparent" elevation={1}>
        <Tabs
          value={tabPosition}
          onChange={handleChange}
          aria-label="simple tabs example"
          variant="scrollable"
          scrollButtons="auto"
          style={{paddingLeft: "25px", background: "#F4F6F8"}}
          color="primary"
        >
          <Tab label="Signature Name" />
          {!isDefault && <Tab label="Validity" />}
          <Tab label="User Preview" />
        </Tabs>
        <TabPanel value={tabPosition} index={0}>
        <Box p={3}>
            <Grid container justify='space-around' alignItems='center'>
              <TextField
                style={{ width: "100%" }}
                error={currentNameError.legnth > 0}
                value={signatureName}
                onChange={(e) => setSignatureName(e.target.value)}
                label="Signature name"
                helperText={currentNameError && "Can't be blank"}
              />              
            </Grid>
          </Box>
        </TabPanel>
        {!isDefault && 
          <TabPanel value={tabPosition} index={1}>
            <Box p={3}>
              <Grid container justify='space-around' alignItems='center'>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showSelectedDates}
                      onChange={ (e) => setShowSelectedDates(e.target.checked) }
                      color="primary"
                    />
                  }
                  label="Schedule signature validity"
                />
                { showSelectedDates && <Grid container justify='space-around' alignItems='center' spacing={2} style={{width: "100%", marginTop: "10px", marginLeft: "0px"}}>
                      <Box>
                        <MobileDatePicker
                          label="Start date"
                          value={selectedDates[0]}
                          inputFormat="dd/MM/yyyy"
                          onChange={(date) => setSelectedDates([date, ...selectedDates.slice(1)])}
                          renderInput={(params) => <TextField {...params} fullWidth />}
                          disabled={dateDisabled}
                        />
                      </Box>
                      <Box style={{marginLeft: "8px"}}>
                        <MobileDatePicker
                          label="End date"
                          value={selectedDates[1]}
                          inputFormat="dd/MM/yyyy"
                          onChange={(date) => setSelectedDates([...selectedDates.slice(0, 1), date])}
                          renderInput={(params) => <TextField {...params} fullWidth />}
                          disabled={dateDisabled}
                        />
                      </Box>
                </Grid> }
              </Grid>
            </Box>
          </TabPanel>
        }
        <TabPanel value={tabPosition} index={!isDefault ? 2 : 1}>
          <Box p={3}>
            <Grid container justify='space-around' alignItems='center'>
              <UserSelect 
                placeholder="Try on with the team member..." 
                users={users} 
                onUserSelected={(user) => {onUserSelected(user)}}
                selectedUser={user}
              >
              </UserSelect>
            </Grid>
          </Box>
        </TabPanel>
      </AppBar>
    </div>
  );
}
