/**
 * 
 * @param {} users 
 * @returns unique domain list from all users emails, returns array of strings 
 */

const extractDomainsFromUserList = (users) => 
    users.reduce((domains,{emails}) => {
        emails
            .map(({address}) => address.split('@').pop())
            .map(domain => domains.indexOf(domain) > -1 || domains.push(domain))
        return domains;
    },[]);

/**
 * convert to single list received users
 *   
 */
const userList = (userList) => 
    Object.values(userList)
        .filter(user => user.suspended !== true)
        .map(user => ({...user,fullName: user.name.fullName }))
        .map(user => ({...user, orgUnit: user.orgUnitPath.split('/').pop()}))

/**
 *   build model of users with a signature applied for any domain/alias
 */
const prepareModelUsers = ([signatures, users ,signaturesInUse, defaultPath]) => 
    users.map(user => ({
        ...user, 
        signatures: getUserSignatures([user,signatures, signaturesInUse, defaultPath])})
    )

const getUserSignatures = ([user, signatures , signaturesInUse, defaultPath]) =>{
    let filter = user.emails
    .map(({address}) => address)
    .map( email => [email, getEmailCurrentPath([email, signaturesInUse, defaultPath])] )  
    // .map(logInMap)
    .map(([email,path]) => ({email, path, signature: path ? getSignaturesFromPath(path, signatures) : null}) );
    console.log("FILTER: ", filter);
    return filter;
}

const getSignaturesFromPath = (path, signatures) => {
    let result = [];
    path.forEach(element => {
        result.push(signatures.find(signature => signature._id === element.path))
    });

    return result;
}

const getEmailCurrentPath = ([email, signaturesInUse, defaultPath]) => {
    const signatures = Object.values(signaturesInUse).map(({users, path}) => users && users.indexOf(email) > -1 && {users, path}).filter(s => s !== false)
    return signatures ? signatures : defaultPath;
}
 

const logInMap = (data) => {
    console.log(data);
    return data;
}


// any element of a array is included in b array ?
// const includeCondition = (a,b) => a.filter(e => b.includes(e)).length ? true : false;

// const getUsersAliasList = (users) =>  users
//     .reduce((list, user) => list.concat(user.emails.map(({address}) => address)), [])
//     .filter(address => address.indexOf(domain) >= 0);

//   const currentSignatures = Object.values(signatures)
//     .filter(({users}) => includeCondition(users,selectedUsersAliasList));

export {
    extractDomainsFromUserList,
    prepareModelUsers,
    userList
};