import React from "react";
import { Box, Grid, Switch } from '@mui/material';

import NumberInputComponent from "./NumberInputComponent";

export default ({ label, value, onChange }) => {
  const [showAll, setShowAll] = React.useState(true);
  const { innerWidth: width } = window;

  const [padding, setPadding] = React.useState(0);

  const [left, setLeft] = React.useState(0);
  const [right, setRight] = React.useState(0);
  const [top, setTop] = React.useState(0);
  const [bottom, setBottom] = React.useState(0);

  React.useEffect(() => {
    let s = new Option().style;
    s.padding = value;

    const [pl, pr, pt, pb] = [
      Number.parseInt(s.paddingLeft),
      Number.parseInt(s.paddingRight),
      Number.parseInt(s.paddingTop),
      Number.parseInt(s.paddingBottom),
    ];

    setValues(pl, pr, pt, pb);
  }, [value]);

  const setValues = (left, right, top, bottom) => {
    setLeft(left);
    setRight(right);
    setTop(top);
    setBottom(bottom);
    if (left === right && left === top && left === bottom) {
      setPadding(left);
    } else {
      setPadding(0);
    }
  };

  const handleShowAll = (evt, value) => {
    setShowAll(value);
    setPadding(padding);
    if (!value) {
      handleChangeAll(padding, padding, padding, padding);
    }
  };

  const handleChangeAll = (left, right, top, bottom) => {
    setValues(left, right, top, bottom);
    onChange(`${top}px ${right}px ${bottom}px ${left}px`);
  };

  const handleChangePadding = (value) => {
    handleChangeAll(value, value, value, value);
  };
  const handleChangeLeft = (value) => {
    handleChangeAll(value, right, top, bottom);
  };
  const handleChangeRight = (value) => {
    handleChangeAll(left, value, top, bottom);
  };
  const handleChangeTop = (value) => {
    handleChangeAll(left, right, value, bottom);
  };
  const handleChangeBottom = (value) => {
    handleChangeAll(left, right, top, value);
  };

  const PreviewBox = ({ left, right, top, bottom }) => (
    <div
      style={{
        backgroundColor: "#d5e8ff",
        boxSizing: "border-box",
        width: 55,
        height: 55,
        paddingLeft: left / 2.0,
        paddingRight: right / 2.0,
        paddingTop: top / 2.0,
        paddingBottom: bottom / 2.0,
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          width: "100%",
          height: "100%",
        }}
      ></div>
    </div>
  );

  const PaddingInput = ({ label, value, onChange }) => (
    <Grid item container direction="row" alignItems="center" justifyContent="end">
      <Grid item width="64px">
        <h5>{label}</h5>
      </Grid>
      <Grid item style={{ textAlign: "right" }}>
        <NumberInputComponent value={value} step={5} min={0} max={40} onChange={onChange} />
      </Grid>
    </Grid>
  );

  return (
    <>
      <Grid
        container
        direction="column"
        spacing={0}
        style={{ paddingBottom: 16 }}
      >
        <Grid item>
          <Box display="flex" flexWrap="wrap" alignItems="center">
            <Box flexGrow={1}>
              <h5>{label}</h5>
            </Box>
            <Box marginLeft={2}>
              <span style={{fontSize: "14px"}}>More</span>
            </Box>
            <Box marginLeft={0}>
              <Switch
                checked={showAll}
                onChange={handleShowAll}
                color="primary"
                inputProps={{ "aria-label": "show All" }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item container direction="row" spacing={0}>
          {width > 768 &&
          <Grid item xs={4}>
            <PreviewBox left={left} right={right} top={top} bottom={bottom} />
          </Grid>
          }
          <Grid item container xs={width > 768 ? 8 : 12} direction="column" spacing={1}>
            {showAll ? (
              <>
                <PaddingInput
                  label="Left"
                  value={left}
                  onChange={(value) => handleChangeLeft(value)}
                />
                <PaddingInput
                  label="Right"
                  value={right}
                  onChange={(value) => handleChangeRight(value)}
                />
                <PaddingInput
                  label="Top"
                  value={top}
                  onChange={(value) => handleChangeTop(value)}
                />
                <PaddingInput
                  label="Bottom"
                  value={bottom}
                  onChange={(value) => handleChangeBottom(value)}
                />
              </>
            ) : (
              <>
                <PaddingInput
                  label="All"
                  value={padding}
                  onChange={(value) => handleChangePadding(value)}
                />
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
