import { clientId, scopes, discoveryDocs, API_ENDPOINT } from '../config/gapi';

let _userId, _jwt, _currentUser, _enqueueSnackbar;

const setEnqueueSnackbar = (enqueueSnackbar) => _enqueueSnackbar = enqueueSnackbar;

const load = (gapiLoadListener, authSessionListener) => {
  if (!window.gapi) {
    return setTimeout(function () { load(gapiLoadListener, authSessionListener); }, 100);
  }
  window.gapi.load('client:auth2', initialize(gapiLoadListener, authSessionListener));
}

const initialize = (gapiLoadListener, authSessionListener) => () => {
  window.gapi.client.setApiKey(null);
  window.gapi.client.init({
    clientId,
    discoveryDocs,
    scope: scopes.join(' ')
  })
    .then(() => {
      gapiLoadListener(true);
      // eslint-disable-next-line no-console
      //console.log('gapi: client initialized');
      //  console.log("_-----------------_");
      //  console.log(process.env);
      //  console.log(":....................:")
    })
    .then(() => {
      const auth2 = window.gapi.auth2.getAuthInstance();
      auth2.isSignedIn.listen(authSessionListener);
      // auth2.isSignedIn.listen((a) => console.log(a))
      authSessionListener(auth2.isSignedIn.get());
    })
    .catch(({ error }) => {
      gapiLoadListener(error);
      // eslint-disable-next-line no-console
      //console.error('gapi init error', error)
    });
}

const setJWT = (jwt = undefined) =>
  _jwt = jwt

const setUserId = (userId = undefined) =>
  _userId = userId

const signIn = async () => {
  const auth2 = window.gapi.auth2.getAuthInstance();
  try {
    return await auth2.signIn();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('sign in error', error)
    await signOut();
    return false
  }
}

const signOut = async () => {
  _currentUser = null;
  window.gapi.auth2.getAuthInstance().disconnect();
}

const getCurrentUser = () => {
  if (_currentUser) {
    return _currentUser;
  }

  const auth2 = window.gapi.auth2.getAuthInstance()
  const currentUser = auth2.currentUser.get()
  const profile = currentUser.getBasicProfile()
  const token = currentUser.getAuthResponse().access_token
  // https://developers.google.com/identity/sign-in/web/reference#googleusergethosteddomain
  const hostedDomain = currentUser.getHostedDomain()
  const user = {
    name: profile.getName(),
    imageURL: profile.getImageUrl(),
    email: profile.getEmail(),
    token,
    hostedDomain
  }
  _currentUser = user;
  return user
}

const _fetch = async (endpoint, method, bearer, body) => {
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  };

  if (bearer) {
    headers.Authorization = 'Bearer ' + bearer;
  }

  const rawResponse = await fetch(API_ENDPOINT + endpoint, {
    method: method,
    headers: headers,
    body: body ? JSON.stringify(body) : undefined
  });
  if (rawResponse.status !== 200) {
    if (rawResponse.status === 500) window.location.href = "/error";
    if (rawResponse.status === 401) await signOut();
    if (rawResponse.status === 402) {
      // if not at /company, redirect to /company, to handle subscription modal notification logic
      if (window.location.href !== `${window.location.origin}/company`) {
        window.location.href = "/company";
      }
      
      return await rawResponse.json();
    }
    if (rawResponse.status === 403) await signOut();
    if (rawResponse.status === 400) _enqueueSnackbar('An error has occurred, please try again later!', { variant: 'error' });
    throw await rawResponse.json();
  }

  return await rawResponse.json();
}

const getJWT = async () => {
  try {
    const user = await isValidUser();
    if (isGmail(user.email)) {
      return { message: 'IS-GMAIL' };
    }

    if (!user.isValidAuth) {
      return { message: 'NON-ADMIN' };
    }
    const { jwt } = await _fetch('/login', 'POST', undefined, { userId: user.email, token: user.access_token, id_token: user.id_token })
    setJWT(jwt);
    return { jwt, userId: user.email };
  } catch ({ message }) {
    const currentUser = window.gapi.auth2.getAuthInstance().currentUser.get();
    const email = currentUser.getBasicProfile().getEmail();
    console.log("EMAIL: ", email.split('@').pop());
    const { exists } = await _fetch(`/public/companyInstalled?domain=${email.split('@').pop()}`, 'GET');

    if (exists)
      return { message: 'NON-ADMIN' };
    else
      return { message: 'NOT-INSTALLED' };
  }
}

const isGmail = (email) => {
  var regexp = new RegExp('[a-zA-Z0-9]{0,}([.]?[a-zA-Z0-9]{1,})[@](gmail.com)');
  return regexp.test(email);
}

// Check if the user is an admin of the gsuite domain
const isValidUser = async () => {
  const currentUser = window.gapi.auth2.getAuthInstance().currentUser.get()
  const access_token = currentUser.getAuthResponse().access_token
  const id_token = currentUser.getAuthResponse().id_token
  const email = currentUser.getBasicProfile().getEmail()
  const isValidAuth = isGsuiteUser(currentUser) && await isAdmin(email)
  return { isValidAuth, email, access_token, id_token }
}

const isGsuiteUser = (currentUser) =>
  currentUser.getHostedDomain() !== undefined

const isAdmin = async (userKey) => {
  try {
    const { result: { isAdmin } } = await window.gapi.client.directory.users.get({ userKey });
    if (!isAdmin) {
      throw new Error('your account need super administrator permissions to manage this application');
    }
    return true;
  } catch (e) {
    console.error("isAdmin error: ", e);
    if (e.status === 403)
      throw new Error('Insufficient permissions, please accept all permissions');
    else
      throw new Error('Error getting user info');
  }
}

const getCompanyData = async (domain) => {
  return await _fetch(`/companies/${domain.replace('.', '_')}`, 'GET', _jwt)
}
const removeCompanyLogo = async (domain) =>
  await _fetch(`/companies/${domain.replace('.', '_')}/logo`, 'DELETE', _jwt);

const saveCompanyData = async (domain, data) =>
  await _fetch(`/companies/${domain.replace('.', '_')}`, 'POST', _jwt, data);

const getCompanyColors = async (domain) =>
  await _fetch(`/companies/${domain.replace('.', '_')}/colors`, 'GET', _jwt)

const saveCompanyColor = async (data, domain) =>
  await _fetch(`/companies/${domain.replace('.', '_')}/color`, 'POST', _jwt, { color: data });

const saveBanner = async (domain, data) =>
  await _fetch(`/companies/${domain.replace('.', '_')}/banners`, 'POST', _jwt, { banner: data });

const getBanners = async (domain) =>
  await _fetch(`/companies/${domain.replace('.', '_')}/banners`, 'GET', _jwt);

const getBanner = async (key, domain) =>
  await _fetch(`/companies/${domain.replace('.', '_')}/banners/${key}`, 'GET', _jwt);

const updateBanner = async ({ key, banner }, domain) =>
  await _fetch(`/companies/${domain.replace('.', '_')}/banners/${key}`, 'PUT', _jwt, { banner });

const saveImage = async (data) =>
  await _fetch(`/images`, 'POST', _jwt, { file: data });

const getImages = async (domain) =>
  await _fetch(`/images`, 'GET', _jwt);

const getSignatureByPath = async (path, domain) =>
  await _fetch(`/companies/${domain.replace('.', '_')}/signatures/${path}`, 'GET', _jwt);

const getSignatures = async (domain) =>
  await _fetch(`/companies/${domain.replace('.', '_')}/signatures`, 'GET', _jwt);

const deleteSignature = async (path, domain) =>
  await _fetch(`/companies/${domain.replace('.', '_')}/signatures/${path}`, 'DELETE', _jwt);

const getSignaturesInUse = async (domain) =>
  await _fetch(`/companies/${domain.replace('.', '_')}/profiles/signatures`, 'GET', _jwt);

const getUserSignatures = async (userId, domain) =>
  await _fetch(`/companies/${domain.replace('.', '_')}/profiles/${userId}/signatures`, 'GET', _jwt);

const getUser = async (userId = _userId, domain) =>
  await _fetch(`/companies/${domain.replace('.', '_')}/profiles/${userId}/fulldata`, 'GET', _jwt);

const getUserProfile = async (userId = _userId, domain) =>
  await _fetch(`/companies/${domain.replace('.', '_')}/profiles/${userId}`, 'GET', _jwt);

const createSignature = async (signature, domain) =>
  await _fetch(`/companies/${domain.replace('.', '_')}/signatures`, 'POST', _jwt, { signature });

const saveSignature = async (signature, domain) => {
  const key = Object.keys(signature)[0];
  return await _fetch(`/companies/${domain.replace('.', '_')}/signatures/${key}`, 'PUT', _jwt, { signature: signature[key] });
}

const saveSignatures = async (signatures, domain) =>
  await _fetch(`/companies/${domain.replace('.', '_')}/signatures`, 'PUT', _jwt, { signatures });

const getDisclaimers = async (domain) => domain ?
  await _fetch(`/companies/${domain.replace('.', '_')}/disclaimers`, 'GET', _jwt) : {};

const getDisclaimer = async (domain, key) =>
  await _fetch(`/companies/${domain.replace('.', '_')}/disclaimers/${key}`, 'GET', _jwt);

const deleteDisclaimer = async (key, domain) =>
  await _fetch(`/companies/${domain.replace('.', '_')}/disclaimers/${key}`, 'DELETE', _jwt);

const deleteImage = async (key, domain) =>
  await _fetch(`/images/${key}`, 'DELETE', _jwt);

const deleteBanner = async (key, domain) =>
  await _fetch(`/companies/${domain.replace('.', '_')}/banners/${key}`, 'DELETE', _jwt);

const saveDisclaimer = async (domain, disclaimer) =>
  await _fetch(`/companies/${domain.replace('.', '_')}/disclaimers`, 'POST', _jwt, { disclaimer });

const getGsuiteUsers = async () =>
  await _fetch('/gsuite/profiles', 'GET', _jwt);

const getGsuiteCurrentProfile = async () =>
  await _fetch(`/gsuite/profile`, 'GET', _jwt);

const applySignatureToUsers = async (selectedUsers, signatureId, domain) =>
  await _fetch(`/companies/${domain.replace('.', '_')}/profiles/signature/${signatureId}/apply`, 'POST', _jwt, { selectedUsers });

const unapplySignatureFromUsers = async (selectedUsers, signatureId, domain) =>
  await _fetch(`/companies/${domain.replace('.', '_')}/profiles/signature/${signatureId}/unapply`, 'POST', _jwt, { selectedUsers });

const enableDefaultSignatureToUsers = async (domain, users) =>
  await _fetch(`/companies/${domain.replace('.', '_')}/profiles/default_signature/enable`, 'POST', _jwt, { users });

const disableDefaultSignatureToUsers = async (domain, users) =>
  await _fetch(`/companies/${domain.replace('.', '_')}/profiles/default_signature/disable`, 'POST', _jwt, { users });

const getDefaultSignatureUsers = async (domain) =>
  await _fetch(`/companies/${domain.replace('.', '_')}/profiles/default_signature`, 'GET', _jwt);

const unapplyAllSignaturesFromUsers = async (selectedUsers, selectedAliases, domain) =>
  await _fetch(`/companies/${domain.replace('.', '_')}/profiles/signature/all`, 'DELETE', _jwt, { selectedUsers, selectedAliases });

const updateDisclaimer = async (domain, key, disclaimer) =>
  await _fetch(`/companies/${domain.replace('.', '_')}/disclaimers/${key}`, 'PUT', _jwt, { disclaimer });

const removeDisclaimer = async (key, domain) =>
  await _fetch(`/companies/${domain.replace('.', '_')}/disclaimers/${key}`, 'DELETE', _jwt);

const getStats = async () =>
  await _fetch('/companies/stats', 'GET', _jwt);

const getDefaultSignaturePath = async (domain) =>
  await _fetch(`/companies/${domain.replace('.', '_')}/signatures/path/default`, 'GET', _jwt);

const setDefaultSignaturePath = async (path, domain) =>
  await _fetch(`/companies/${domain.replace('.', '_')}/signatures/path/default`, 'POST', _jwt, { path });

const getDomains = async () =>
  await _fetch('/domains', 'GET', _jwt);

const getStripeCheckoutSession = async (domain, { successUrl, cancelUrl }) =>
  await _fetch(`/subscribe/${domain.replace('.', '_')}/checkout`, 'POST', _jwt, { successUrl, cancelUrl });

const getStripeManagementPortal = async (domain, { returnUrl }) =>
  await _fetch(`/subscribe/${domain.replace('.', '_')}/manage`, 'POST', _jwt, { returnUrl });

const getStripeLatestInvoicePage = async (domain) =>
  await _fetch(`/subscribe/${domain.replace('.', '_')}/invoice`, 'GET', _jwt);
  
const dismissTrialStart = async (domain) =>
  await _fetch(`/subscribe/${domain.replace('.', '_')}/dismissTrialStart`, 'POST', _jwt);

export {
  deleteBanner,
  deleteDisclaimer,
  deleteImage,
  load,
  signIn,
  signOut,
  getBanner,
  getBanners,
  updateBanner,
  getCompanyData,
  getCurrentUser,
  getDisclaimers,
  getDisclaimer,
  getGsuiteUsers,
  getImages,
  getJWT,
  getSignatures,
  getSignatureByPath,
  deleteSignature,
  getSignaturesInUse,
  getStats,
  getGsuiteCurrentProfile,
  getUserProfile,
  getUserSignatures,
  removeCompanyLogo,
  saveBanner,
  saveCompanyData,
  saveDisclaimer,
  saveImage,
  createSignature,
  saveSignature,
  saveSignatures,
  applySignatureToUsers,
  unapplySignatureFromUsers,
  unapplyAllSignaturesFromUsers,
  setJWT,
  setUserId,
  updateDisclaimer,
  removeDisclaimer,
  isValidUser,
  getCompanyColors,
  saveCompanyColor,
  getDefaultSignaturePath,
  setDefaultSignaturePath,
  enableDefaultSignatureToUsers,
  disableDefaultSignatureToUsers,
  getDefaultSignatureUsers,
  getDomains,
  setEnqueueSnackbar,
  getStripeCheckoutSession,
  getStripeManagementPortal,
  getStripeLatestInvoicePage,
  dismissTrialStart,
};
