import React from 'react';

import EditorBase from './Editor';
import PreviewBase from './Preview';
import ConfiguratorBase from './Configurator';
import SaveSignatureBase from './SaveSignatureBase';

import { StateProvider } from './Context';
import reducer from './reducer';
import * as helpers from './helpers';

export { helpers };

export const Configurator = (props) => (
  <ConfiguratorBase {...props} />
);

export const Editor = (props) => (
  <EditorBase {...props} />
);

export const Preview = (props) => (
  <PreviewBase {...props} />
);

export const SaveSignature = (props) => (
  <SaveSignatureBase {...props} />
);

// eslint-disable-next-line react/prop-types,react/display-name
export default ({ template, images, companyColors, disclaimers, disclaimerKeys, banners, bannerKeys, children, warnUserChanges }) => {
  const initialState = {
    template: template,
    config: false, // Open config panel
    structureId: 0, // RowId selected for configure
    structure: template.rows[0], // Row data for configure
    componentId: 10103, // Component id selected for configure
    component: template.rows[0] ? template.rows[0].columns[0].contents[2] : null, // Component data selected for configure
    //images: images,
    images: [],
    companyColors: companyColors,
    disclaimers: disclaimers,
    disclaimerKeys: disclaimerKeys,
    banners: banners,
    bannerKeys: bannerKeys
  };

  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      {children}
    </StateProvider>
  );
};