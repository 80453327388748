import React from 'react';
import useSettings from '../../hooks/useSettings';
import { Container, Grid, Card } from '@mui/material';
import Drawer from '../../UI/Base/Drawer';
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import DisclaimerDetailsContainer from '../../UI/containers/DisclaimerDetailsContainer';
import { useParams } from 'react-router-dom';

const DisclaimersShow = () => {
    const { themeStretch } = useSettings(); 
    let { disclaimerPath } = useParams(); 
    return (
        <Drawer title="Disclaimers">
            <Page title="SignPro | Disclaimer Details">
                <Container maxWidth={themeStretch ? false : 'lg'}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <HeaderBreadcrumbs 
                                heading="Disclaimers"
                                links={[{ name: 'Disclaimers', href: "/disclaimers" }, { name: 'Disclaimer Details' }]} />
                        </Grid>
                        <Grid item xs={12}>
                            <Card sx={{p: 5}}>
                                <DisclaimerDetailsContainer disclaimerPath={disclaimerPath} />
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </Page>
        </Drawer>
    );
};

export default DisclaimersShow;