import React, { useEffect } from 'react';
import plusOutline from '@iconify/icons-eva/plus-outline';
import minusOutline from '@iconify/icons-eva/minus-outline';
import { Box, ButtonGroup, Button, OutlinedInput } from '@mui/material';
import { Icon } from '@iconify/react';

export default ({ value, step, min, max, readOnly, onChange }) => {

  const [inputValue, setInputValue] = React.useState(value)

  if (!Number.isInteger(step)){
    step = 1
  }
  const handleDecrease = () => {
    let newValue = value - step;
    if (Number.isInteger(min)){
      newValue = Math.max(newValue, min)
    }
    setInputValue(newValue);
    onChange(newValue);
  };

  const handleIncrease = () => {
    let newValue = value + step;
    if (Number.isInteger(max)) {
      newValue = Math.min(newValue, max);
    }
    setInputValue(newValue);
    onChange(newValue);
  };

  const handleChange = (e) => {
    let newValue = e.target.value;
    newValue = newValue ? parseInt(newValue) : min
    if(!newValue || newValue < min) newValue = min;
    else if(newValue > max) newValue = max;
    setInputValue(newValue);
    onChange(newValue);
  }
  
  readOnly = !!readOnly

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleInput = (e) => {
    if(e.target.value && !/^[0-9]+$/.test(e.target.value))
      return;
    if(e.target.value && min && e.target.value < min)
      setInputValue(min);
    else setInputValue(e.target.value);
  }
  
  const handleKeyPress = (e) => {
    if(e.key === "Enter"){
      handleChange(e);
    }
  } 
    
  return (
    <ButtonGroup
      color="primary"
      aria-label="outlined secondary button group"
    >
      <Button size="small" sx={{borderRightColor: "transparent"}} onClick={handleDecrease}>
        <Icon icon={minusOutline} width={16} height={16} />
      </Button>
      <input
        value={inputValue}
        readOnly={readOnly}
        min={min}
        max={max}
        onChange={handleInput}
        onKeyPress={handleKeyPress}
        onBlur={handleChange}
        style={{
          borderRadius: 0, 
          border: "1px solid rgba(0, 171, 85, 0.5)", 
          borderRightColor: "transparent", 
          borderLeftColor: "transparent",
          padding: "12px 0px",
          textAlign: "center",
          marginLeft: "-1px",
          fontSize: "14px",
          width: "116px"
        }}
      />
      <Button size="small" sx={{borderLeftColor: "transparent"}} onClick={handleIncrease}>
        <Icon icon={plusOutline} width={20} height={20} />
      </Button>
    </ButtonGroup>
  );
};