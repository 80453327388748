import React, {useState, useEffect} from 'react';
import DomainSelector from '../DomainSelector';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import { setDomain, loadDomains } from '../../Actions/Company';
import { userConfirmNavigation } from "../../Actions/Navigation";
import DialogUserWarn from '../../components/DialogUserWarn';

const mapStateToProps = ({context}) => ({context});
const mapDispatchToProps = dispatch => bindActionCreators({ setDomain, loadDomains, userConfirmNavigation }, dispatch);

const DomainContainer = connect(mapStateToProps, mapDispatchToProps)(({context, setDomain, loadDomains, userConfirmNavigation}) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [domainSelected, setDomainSelected] = useState(context.domain);
    useEffect(loadDomains,[]);

    const onChangeDomain = (domain) => {
        setDomainSelected(domain)
        if (context.warnUserChanges) {
            setDialogOpen(true);
        } else setDomain(domain);
    }

    const onWarnCancel = () => {
        setDomainSelected(context.domain);
        setDialogOpen(false);
    }

    const onWarnConfirm = () => {
        setDomain(domainSelected);
        userConfirmNavigation();
        setDialogOpen(false);
    }

    return (
        <>
            <DialogUserWarn open={dialogOpen} onCancel={onWarnCancel} onClose={onWarnCancel} onConfirm={onWarnConfirm} />
            <DomainSelector selectedDomain={context.domain} domains={context.domains} onChange={(domain) => onChangeDomain(domain)}></DomainSelector>
        </>
        
    )
});

export default DomainContainer;
