const initialState = {
    domain: '',
    domains: [],
    loading: false,
    navigationAvailable: true,
    warnUserChanges: false,
    company: null,
    error: false,
};
  

const context = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DOMAIN':
      return {...state, warnUserChanges: false, domain: action.domain};
    case 'SET_DOMAINS':
      return {...state, warnUserChanges: false, domains: action.domains};
    case 'LOADING_DATA':
      return {...state, warnUserChanges: false, loading: true, error: false};
    case 'LOAD_COMPANY_SUCCESS':
      return {...state, loading: false, company: action.company};
    case 'LOAD_ERROR':
      return {...state, loading: false, error: true};
    case 'SET_NAVIGATION_AVAILABLE':
      return {...state, navigationAvailable: true};
    case 'SET_NAVIGATION_UNAVAILABLE':
      return {...state, navigationAvailable: false};    
    case 'WarnUserChanges':
      return {...state, warnUserChanges: true};   
    case 'ConfirmNavigation':
      return {...state, warnUserChanges: false};     
    case 'SignOut':
      return {...initialState};  
    default:
      return state;
  }
}
  
export default context;