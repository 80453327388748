import { Grid, Card } from '@mui/material';
import React from 'react';
import BannerItem from './BannerItem';
import EmptyContent from '../../components/EmptyContent';


export default function BannerGrid({banners}) {
    return (
        <Grid container spacing={3}>
            {banners && Object.entries(banners).length > 0 ? Object.entries(banners).sort((a, b) => a[1].createdAt < b[1].createdAt ? 1 : -1).map(([bannerKey, bannerValue]) => (
                <Grid key={bannerKey} item xs={12} sm={6} md={3}>
                    <BannerItem imageUrl={bannerValue.imageUrl} title={bannerValue.title} trackable={bannerValue.track} path={bannerValue._id} />
                </Grid>
            )) : 
            <Grid item xs={12}>
                <Card sx={{pt: 1}}>
                    <EmptyContent title="Empty content" />
                </Card>
            </Grid>}
        </Grid>
    );
}
