import { Box, MenuItem, Select } from "@mui/material";
import React from "react";

const ConfiguratorTextStyleWidget = ({ label, value, onChange }) => {
  const handleChange = (evt) => onChange(evt.target.value);

  const text_styles = [
    {value: '{"fontWeight": "bold"}', label: 'Bold'},
    {value: '{"fontStyle": "italic"}', label: 'Italic'},
    {value: '{"textDecoration": "underline"}', label: 'Underline'}
  ];

  return (
    <>
      <Box flexGrow={1}>
        <h5>{label}</h5>
      </Box>
      <Box>
        <Select
          labelId="text-style-widget-label"
          id="text-style-widget-select"
          value={value||"inherit"}
          sx={{width: "194px", fontSize: "14px"}}
          size="small"
          onChange={handleChange}
        >
          <MenuItem value={'{"fontWeight": "normal", "fontStyle": "inherit", "textDecoration": "none"}'}>Default</MenuItem>
          {text_styles.map((style, index) => (
            <MenuItem key={index} value={style.value} style={JSON.parse(style.value)}>{style.label}</MenuItem>
          ))}
        </Select>
      </Box>
    </>
  )
}

export default ConfiguratorTextStyleWidget;