import React from 'react';
import { Button, Box, Grid, Autocomplete, TextField, Stack, Typography } from '@mui/material';
import { useStateValue } from '../../Context';
import SelectorListDialog from './SelectorListDialog';

const DisclaimerContainer = ({label, open, handleClose, onChange, disclaimerSelected}) => {
  const [{ disclaimers, disclaimerKeys }, dispatch] = useStateValue();

  console.log("disclaimers: ", disclaimers)

  const handleDisclaimerClick = (index) => {
    console.log("handleDisclaimerClick", index);
    onChange({'title': disclaimers[index].title, 'value': disclaimers[index].text});
    handleClose();
  }

  const getSelectedValuePosition = () => {
    console.log("getSelectedValuePosition: ", {disclaimers, disclaimerSelected});
    for (const key in disclaimers) {
      if (disclaimers[key].title === disclaimerSelected.title && disclaimers[key].text === disclaimerSelected.value) return key;
    }
    return -1;
  }

  const handleSelection = (e, disclaimer) => {
    console.log("handleSelection", disclaimer);
    if (disclaimer == null) return; 
    for (const key in disclaimers) {
      if (disclaimers[key].title === disclaimer.title && disclaimers[key].text === disclaimer.text) {
        handleDisclaimerClick(key);
        return;
      }
    }
  }

  return (<Autocomplete
    size='small'
    disableClearable
    value={disclaimers[getSelectedValuePosition()]}
    getOptionLabel={(disclaimer) => disclaimer.title}
    id="select-disclaimer"
    options={disclaimers.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)}
    sx={{ width: "100%"}}
    onChange={handleSelection}
    renderInput={(params) => <TextField {...params} placeholder="Select a Disclaimer" />}
    renderOption={(props, recipient, { inputValue, selected }) => {
        const { title } = recipient;
        return (
          <>
            <Stack direction="row" alignItems="center" spacing={2} {...props} sx={{padding: "10px 20px"}}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="subtitle2">{title}</Typography>
              </Box>
            </Stack>
          </>
        );
      }}
    />);

  /*
  return (
    <SelectorListDialog 
      label="Choose the Disclaimer"
      open={open}
      handleClose={handleClose}
      onApply={handleDisclaimerClick}
      rows={disclaimers.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1).map(disclaimer => ({value: disclaimer.title}))}
      selectedValuePosition={getSelectedValuePosition()}
      captionText="*You can add a new disclaimer from the Disclaimers page"
    />
  );*/
}

export default ({ label, disclaimerSelected, onChange }) => {
  const [open, setOpen] = React.useState(false);

  return(
    <>
      <Grid
        container
        direction="column"
        spacing={0}
      >
        <DisclaimerContainer
          label={label}
          open={open}
          onChange={onChange}
          disclaimerSelected={disclaimerSelected}
          handleClose={() => setOpen(false)}
        />
      </Grid>
    </>
  );
};