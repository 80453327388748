const initialState = {
  loading: true,
  logginError: false,
  isSignedIn: false,
  isGapiLoaded: false,
  jwt: undefined,
  user: undefined
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case 'ChangeGapiState':
      return {
        ...state,
        loading: false,
        isGapiLoaded: action.isGapiLoaded
      };    
    case 'ChangeSignStatus':
      return {
        ...state,
        isSignedIn: action.isSignedIn
      };
    case 'SetUser':
        return {
          ...state,
          user: action.user,
        };
    case 'setLoginError':
      return {
        ...state,
        logginError: action.message
      };        
    case 'SignOut':
      return {...state, jwt: undefined, user: undefined, isSignedIn: false};
    case 'ChangeJWT':
      return {
        ...state,
        loading: false,
        logginError: false,
        jwt: action.jwt
      };
    default:
      return state;
  }
}


export default auth;