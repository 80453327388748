import * as Gapi from '../Helpers/Gapi';


const loading = kind => ({type: 'LOADING_DATA', kind});
const loadError = error => ({type: 'LOAD_ERROR', error});
const loadComanyDataSuccess = company => ({type: 'LOAD_COMPANY_SUCCESS', company});
const checkCompanyReady = ({companyName,webpage}) => {
  return dispatch => {
      companyName && webpage ? 
        dispatch({type: 'SET_NAVIGATION_AVAILABLE'}): 
        dispatch({type: 'SET_NAVIGATION_UNAVAILABLE'});
  }
}

export const loadDomains = () => (dispatch, getState) => {
  const { context } = getState();
  dispatch(loading('domains'));
  Gapi.getDomains().then(domains => {
      if (domains && domains.length > 0) {
            dispatch({type: 'SET_DOMAINS', domains});
            context.domain ? 
              dispatch(setDomain(context.domain)): 
              dispatch(setDomain(domains[0]));
      }
  })
}


export const setDomain = domain => {
  return dispatch => {
    dispatch(loadCompanyData(domain));
    dispatch({type: 'SET_DOMAIN', domain});
  };
}
      
export const loadCompanyData = domain => {
  return dispatch => {
    dispatch(loading('company-data'));
    Gapi.getCompanyData(domain)
    .then(company => {
        dispatch(loadComanyDataSuccess(company));
        dispatch(checkCompanyReady(company));
    }).catch(error => dispatch(loadError(error)))
  }
}


export const setCompanyForm = companyForm => {
  // console.log("setCompanyForm", companyForm)
  return dispatch => {
    dispatch({type: 'SET_COMPANY_FORM', companyForm});
  };
}

export const submitCompanyForm = (companyForm, domain, enqueueSnackbar) => {
  var form = companyForm;
  delete form.submitting;
  return dispatch => {
    dispatch({type: 'SUBMITTING_FORM_START'});
    Gapi.saveCompanyData(domain.replace('.', '_'), form)
      .then(company => {
        enqueueSnackbar('The company profile was updated successfully.', { variant: 'success' });
        dispatch({type: 'SUBMITTING_FORM_END'});
        dispatch({type: 'LOAD_COMPANY_SUCCESS', company});
        dispatch(checkCompanyReady(company));
      });
  };
}