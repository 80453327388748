import React, {useCallback, useEffect} from 'react';
import DefaultLogo from '../static/images/user-group.png';
import { Grid, Typography } from '@mui/material';

import { UploadAvatar } from '../components/upload';
import { fData } from '../utils/formatNumber';


export default ({
  data,
  setImageBase64,
  onUseGsuiteLogoClick
}) => {

  // const isDefault =
  //   data &&
  //   data.imageUrl ?
  //   data.imageUrl.includes('https://www.google.com/a/cpanel') : false;

  const handleDrop = useCallback((acceptedFiles) => acceptedFiles[0] && setImageBase64(acceptedFiles),
    [setImageBase64]
  );

  useEffect(() => {console.log(data.imageUrl)}, [data]);


  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid sx={{ py: 10, px: 3, textAlign: 'center' }}>
            <UploadAvatar
              accept=".jpeg, .jpg, .png, .gif"
              file={data.imageUrl}
              maxSize={3145728}
              onDrop={handleDrop}
              caption={
                <Typography
                  variant="caption"
                  sx={{
                    mt: 2,
                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                    color: 'text.secondary'
                  }}
                >
                  Allowed *.jpeg, *.jpg, *.png, *.gif
                  <br /> max size of {fData(3145728)}
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

/*
const useStyles = makeStyles((theme) => ({
  divider: {
    marginBottom: theme.spacing(2)
  },
  avatar: {
    margin: 10
  },
  // button: {
  //   width: '210px'
  //},
  bigAvatar: {
    margin: 10,
    width: 120,
    height: 120
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  // rightIcon: {
  //   position: 'absolute',
  //   right: 0,
  //   marginRight: theme.spacing(1)
  // },
  // topMargin: {
  //   marginTop: theme.spacing(1)
  // }
}));
*/
