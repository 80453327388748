import { Icon } from '@iconify/react';
import { Link } from "react-router-dom";

import React, { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import copyFill from '@iconify/icons-eva/copy-fill'
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import pricetagsOutline from '@iconify/icons-eva/pricetags-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import { PATH_SIGNATURES_EDIT } from '../../Navigation';
import SvgIconStyle from '../../components/SvgIconStyle';


export default function SignatureListMoreMenu({ onDeleteClick, path, isDefault, onDuplicateClick, onDefaultClick, usersTotal }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          button component={Link} to={PATH_SIGNATURES_EDIT.replace("/:signaturePath", path)}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem onClick={onDuplicateClick} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <SvgIconStyle src={`/static/icons/navbar/ic_copy.png`} sx={{ width: 24, height: 24 }} />
          </ListItemIcon>
          <ListItemText primary="Duplicate" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        
        {!isDefault && 
        <MenuItem onClick={onDefaultClick} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={pricetagsOutline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Set Default" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        }
        
        {!isDefault && usersTotal === 0 && 
          <MenuItem onClick={onDeleteClick} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon={trash2Outline} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        }
      </Menu>
    </>
  );
}
