import React, { useState } from 'react';
import AddNewUrl from './AddNewUrl';

import Box from '@material-ui/core/Box';
import DialogContent from '@material-ui/core/DialogContent';
import { Dialog } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Resizer from 'react-image-file-resizer';

const NewBannerDialog = ({
    open, handleClose, handleCreate
  }) => {
    const [inputError, setInputError] = useState({});
    const [bannerUrl, setBannerUrl] = useState('');
    const [image, setImage] = useState('');
    const [bannerError, setBannerError] = useState(false);
    const [inputKey, setInputKey] = useState(0);
  
    const checkError = () => {
      setInputError({...inputError, ...{image: image === ''}})
    }
  
    const handleClick = () => {
      checkError();
      if (image === ''){
        alert("Please, select an image to upload.")
      } else if (bannerError) {
        alert("Please, fix url type error")
      } else {
        handleClose();
        handleCreate({ image: image, link: bannerUrl });
      }
    }
  
    const getBase64 = (file) => new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

    const getImageSizeFromBase64 = (data) => new Promise((resolve, reject) => {
      let image = new Image();
      image.onload = () => {
          resolve([image.width, image.height]);
      }
      image.onerror = error => reject(error);
      image.src = data;
    });

    const resizeFile = (file, width, height) => new Promise(resolve => {
      let imageRatio = height/width;
      Resizer.imageFileResizer(
          file,
          Math.min(800, width),
          Math.min(800, width) * imageRatio,
          'JPEG',
          60,
          0,
          uri => {
          resolve(uri);
          },
          'base64'
      );
    })
  
    const setImageBase64 = async (e) => {
      if (e.target.files[0]) {
        let imageFile = e.target.files[0];
        let imageBase64 = await getBase64(imageFile);
      
        let [width, height] = await getImageSizeFromBase64(imageBase64);
        let imageSize = imageFile.size;
        if (imageSize / 1024 > 50) {
            if (window.confirm('Image is too big, max allowed file size is 50KB. Image will be compressed and saved. Do you want to continue?')) {
              imageBase64 = await resizeFile(imageFile, width, height);
            } else { 
                alert('Image is too heavy, allowed max file size is 50KB. Please upload new image.');
                setInputKey(inputKey+1)
                return
            }
        } 
        setImage(imageBase64);
      }
    }
  
    return (
  
    <Dialog
        fullWidth
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        style={{ zIndex: 1301 }}
      >
        <DialogContent dividers>
          <Box style={{marginBottom: "8px"}} >
            <AddNewUrl  bannerUrl={bannerUrl} setBannerUrl={setBannerUrl} setBannerUrlError={setBannerError} />
          </Box>
          <Box marginBottom={2}>
            <label
              htmlFor="contained-button-file"
              
            >
              <input
                accept="image/*"
                id="contained-button-file"
                multiple
                type="file"
                key={inputKey}
                onChange={(e) => (setImageBase64(e))}
              />  
            </label>
          </Box>
          <Grid container direction='row' justify='flex-end' alignItems='center'>
            <Button variant="contained" color="primary" style={{marginRight: 8}} onClick={handleClick}>
              Ok
            </Button>
            <Button variant="contained" onClick={handleClose}>
              Close
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
export default NewBannerDialog;