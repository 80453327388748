import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Preview from './Preview';
import * as Gapi from '../../Helpers/Gapi';
import * as SettingsReducer from '../../Reducers/Settings';
import BannersGrid from '../components/BannersGrid';
import DisclaimersList from '../components/DisclaimersList';
import ImagesGrid from '../components/ImagesGrid';
import AddNewData from '../components/AddNewData';

const DataTable = ({
  type,
  banners,
  disclaimers,
  images,
  dispatch
}) => {
  const [showData, setShowData] = useState(banners);
  const [showPreview, setShowPreview] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  let loading = false;

  const handleClick = (data) => () => {
    setShowPreview(true);
    setSelectedData(data);
  }

  const removeImage = (key) => () => {
    if (loading) return;
    setShowPreview(false);
    if (type === 'banner') {
      deleteBannerByKey(key)
    } else {
      deleteImageByKey(key)
    } 
  } 

  const deleteImageByKey = (key) => {
    if (window.confirm("You are about to delete an Image")) {
    loading = true;
    Gapi.deleteImage(key).then(() => {
      loading = false;
      SettingsReducer.removeImage(dispatch)(key);
    }).catch((err) => {
      loading = false;
      console.error(err);
    });
  }}
    
  const deleteBannerByKey = (key) => {
    if (window.confirm("You are about to delete a Banner")){
    loading = true;
    Gapi.deleteBanner(key).then(() => {
      loading = false;
      SettingsReducer.removeBanner(dispatch)(key);
    }).catch((err) => {
      loading = false;
      console.error(err);
    }); 
  }}

  const disclaimerDelete = (key) => () => {
    if (window.confirm("You are about to delete a Disclaimer")){
      loading = true;
    Gapi.removeDisclaimer(key)
      .then(() => {
        loading = false;
        SettingsReducer.removeDisclaimer(dispatch)(key);
      }).catch((err) => {
        loading = false;
        console.error(err);
      });   
  }}
  
  useEffect(() => {
    const data = type === 'banner' ? banners : (type === 'disclaimer' ? disclaimers : images);
    setShowData(data);
  }, [type, banners, disclaimers, images]);

  useEffect(() => {
    setShowPreview(false);
    setSelectedData({});
  }, [type]);

  return (
<>
    <AddNewData
      type={type}
      onSaveBannerSucess={(banner) => SettingsReducer.addBanner(dispatch)(banner)}
      onSaveImageSucces={(image) => SettingsReducer.addImage(dispatch)(image)}
      onSaveDisclaimerSucces={(disclaimer) => SettingsReducer.addDisclaimer(dispatch)(disclaimer)}
      />

    <Grid container spacing={3}>
      {type === 'image' ?
        <ImagesGrid 
          imageList={showData}
          imageDelete={removeImage}
          itemSelected={selectedData}
          imageSelected={(image) => () => {setSelectedData(image)}}
        />
      : type === 'disclaimer' ? 
        <DisclaimersList
          disclaimerDelete={disclaimerDelete}
          disclaimerList={showData}
          disclaimerSelected={handleClick}
          gridSize={6}
        />
      : 
        <BannersGrid 
          bannerList={showData} 
          bannerDelete={removeImage}
          bannerSelected={handleClick}
          itemSelected={(selectedData && selectedData.value) ? selectedData.value.imageUrl : null}
          gridSize={6}   
        />
      }
      {showPreview && (
        <Grid
          item
          xs={6}
        >
          <Preview onSaveNewDataSuccess={() => setShowPreview(false)} type={type} data={selectedData} dispatch={dispatch} />
        </Grid>
      )}
    </Grid>
</>
  );
}
export default DataTable;
