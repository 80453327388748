import React from 'react';
import { Menu, MenuItem, Button } from '@mui/material';

import { useStateValue } from '../Context';
import { splitId } from '../helpers';

const fields = [
  { type: "text", name: "Free Text", value: "", editable: true},
  { type: "spacer", name: "Spacer", value: "{spacer}" },
  { type: "text", name: "Name", value: "{firstName}" },
  { type: "text", name: "Surname", value: "{lastName}" },
  { type: "text", name: "Full Name", value: "{userFullName}" },
  { type: "text", name: "Job Title", value: "{jobTitle}" },
  { type: "text", name: "Department", value: "{department}" },
  { type: "text", name: "Organisational Unit", value: "{orgunit}" },
  { type: "text", name: "Email", value: "{email}" },
  { type: "text", name: "User Personal Phones", value: "{personal_phones}" },
  { type: "text", name: "User Work Phones", value: "{work_phones}" },
  { type: "text", name: "User Mobile Phones", value: "{mobile_phones}" },
  { type: "text", name: "Linkedin", value: "{linkedin}" },
  { type: "text", name: "Facebook", value: "{facebook}" },
  { type: "text", name: "Instagram", value: "{instagram}" },
  { type: "text", name: "Youtube", value: "{youtube}" },
  { type: "text", name: "Twitter", value: "{twitter}" },
  { type: "image", name: "Personal Photo", value: "{userPhoto}" },
  { type: "text", name: "Company Name", value: "{companyName}" },
  { type: "image", name: "Company Logo", value: "{companyLogo}" },
  { type: "text", name: "Webpage", value: "{webpage}" },
  { type: "image", name: "Banner", value: "", selectable: true, isBanner: true },
  { type: "image", name: "Image", value: "", selectable: true },  
  { type: "disclaimer", name: "Disclaimer", value: "{disclaimer}" }
];

export default ({ index }) => {
  // eslint-disable-next-line no-unused-vars
  const [{ template }, dispatch] = useStateValue();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAdd = (field) => {
    setAnchorEl(null);
    dispatch({
      type: 'addItem',
      item: { id: index, data: {...field} }
    });
    const split_id = splitId(index);
    dispatch({
      type: 'editItem',
      item: { id: index * 100 + template.rows[split_id[0]].columns[split_id[1]].contents.length }
    });
  };

  // return <Button color="primary">+ Add new</Button>;
  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        color="primary"
        sx={{marginLeft: "12px", fontWeight: 400, paddingX: 0}}
        onClick={handleClick}
      >
        + New Component
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {fields.map((field, i) => (
          <MenuItem key={i} onClick={() => handleAdd(field)}>{field.name}</MenuItem>
        ))}
      </Menu>
    </>
  );
};
