import React, { useState, useEffect } from "react";
// import PropTypes from "prop-types";
import { List, ListItem } from '@mui/material';

import ConfiguratorStructureWidget from "./Components/ConfiguratorStructureWidget";
import ConfiguratorColorWidget from "./Components/ConfiguratorColorWidget";
import ConfiguratorPaddingWidget from "./Components/ConfiguratorPaddingWidget";

export default ({ structure, onChange }) => {
  const width = structure.width;
  const [widths, setWidths] = useState(structure.columns.map((c) => (c.width || 568)));
  const [colSpacing, setColSpacing] = useState(structure.colSpacing || 0);
  const [bgColor, setBgColor] = useState(structure.bgColor || "inherit");
  const [padding, setPadding] = useState(structure.padding || "0");
  const [userClick, setUserClick] = useState(false);
  const [styleClicked, setStyleClicked] = useState(null);

  useEffect(() => {
    onChange({ widths, colSpacing, bgColor, padding }, userClick, styleClicked);
    setUserClick(false);
    setStyleClicked(null);
  }, [onChange, widths, colSpacing, bgColor, padding]);

  const setWidthsAndColSpacing = (widths, colSpacing) => {
    setWidths(widths);
    setColSpacing(colSpacing);
  };

  return (
    <List style={{ width: "100%" }} onClick={() => setUserClick(true)}>
      <ListItem sx={{paddingY: "4px"}}>
        <ConfiguratorStructureWidget
          label=""
          width={width}
          widths={widths}
          colSpacing={colSpacing}
          onChange={setWidthsAndColSpacing}
        />
      </ListItem>
      <ListItem sx={{paddingY: "24px"}} onClick={() => setStyleClicked("bgColor")}>
        <ConfiguratorColorWidget
          label="Background colour"
          value={bgColor}
          onChange={setBgColor}
        />
      </ListItem>
      <ListItem sx={{paddingY: "4px"}} onClick={() => setStyleClicked("padding")}>
        <ConfiguratorPaddingWidget
          label="Padding"
          value={padding}
          onChange={setPadding}
        />
      </ListItem>
    </List>
  );
};
