import React from "react";
import { renderToStaticMarkup } from "react-dom/server";

const applyConversionsToHtml = (conversions, html) =>
  Object.entries(conversions).reduce((acc, [key, value]) => {
    const regExp = new RegExp(`{${key}}`, "g");
    return acc.replace(regExp, value);
  }, html);

// eslint-disable-next-line react/prop-types
export default ({ conversions, children }) => {
  const iframeRef = React.useRef(null);

  React.useEffect(() => {
    updateIframe();
  });

  React.useEffect(() => {
    const timer = setTimeout(() => {
      resizeIframe();
    }, 1000);
    return () => clearTimeout(timer);
  });

  const updateIframe = () => {
    let iframe = iframeRef.current;
    let doc = iframe.contentDocument;
    const html = applyConversionsToHtml(
      conversions,
      renderToStaticMarkup(children)
    );

    doc.open();
    doc.write("<!DOCTYPE html>");
    doc.write(html);
    doc.close();
  };

  const resizeIframe = () => {
    let iframe = iframeRef.current;
    iframe.style.width = "100%";
    iframe.style.height = `${iframe.contentWindow.document.body.scrollHeight + 16}px`;
  };

  return (
    <iframe
      title="Preview"
      src="about:blank"
      scrolling="no"
      frameBorder="0"
      ref={iframeRef}
    ></iframe>
  );
};
