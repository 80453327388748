import _omit from 'lodash/omit';

const initialState = {
  banners: {},
  disclaimers: {},
  images: {}
}

function reducer(state, action) {
  switch (action.type) {
    case 'addBanners': {
      const { banners } = action.payload;
      return {
        ...state,
        banners
      };
    }

    case 'addBanner': {
      const { banner } = action.payload;
      console.log("addbanner state", state.banners)
      console.log("addbanner newbanner", banner)
      return {
        ...state,
        banners: { ...state.banners, ...banner }
      };
    }

    case 'updateBanner': {
      const { key, value } = action.payload;
      console.log("updatebanner", value);
      return {
        ...state,
        banners: {..._omit(state.banners, key), ...{ [key]: value }}
      }
    }

    case 'addDisclaimers': {
      const { disclaimers } = action.payload;
      return {
        ...state,
        disclaimers
      };
    }

    case 'addDisclaimer': {
      const { disclaimer } = action.payload;
      return {
        ...state,
        disclaimers: {...state.disclaimers, ...disclaimer}
      }
    }

    case 'updateDisclaimer': {
      const { key, disclaimer } = action.payload;
      return {
        ...state,
        disclaimers: {..._omit(state.disclaimers, key), ...{ [key]: disclaimer }}
      }
    }

    case 'removeDisclaimer': {
      const key = action.payload
      delete state.disclaimers[key]
      return {
        ...state
      }
    }

    case 'addImages': {
      const { images } = action.payload;
      return {
        ...state,
        images
      };
    }

    case 'addImage': {
      const { image } = action.payload;
      return {
        ...state,
        images: { ...state.images, ...image }
      }
    }

    case 'removeImage': {
      const key = action.payload
      delete state.images[key]
      return {
        ...state
      }
    }

    case 'removeBanner': {
      const key = action.payload
      delete state.banners[key]
      return {
        ...state
      }
    }

    default:
      throw new Error();
  }
}

const addBanners = (dispatch) =>
  (banners) => dispatch({ type: 'addBanners', payload: { banners } });

const addBanner = (dispatch) =>
  (banner) => dispatch({ type: 'addBanner', payload: { banner } });

const updateBanner = (dispatch) =>
  (key, value) => dispatch({ type: 'updateBanner', payload: { key, value } });

const addDisclaimers = (dispatch) =>
  (disclaimers) => dispatch({ type: 'addDisclaimers', payload: { disclaimers } });

const addDisclaimer = (dispatch) =>
  (disclaimer) => dispatch({ type: 'addDisclaimer', payload: { disclaimer } });

const updateDisclaimer = (dispatch) =>
  (key, disclaimer) => dispatch({ type: 'updateDisclaimer', payload: { key, disclaimer } });

const removeDisclaimer = (dispatch) =>
(key) => dispatch({ type: 'removeDisclaimer', payload: key });

const addImages = (dispatch) =>
  (images) => dispatch({ type: 'addImages', payload: { images } });

const addImage = (dispatch) =>
  (image) => dispatch({ type: 'addImage', payload: { image } });

const removeBanner = (dispatch) =>
  (key) => dispatch({ type: 'removeBanner', payload: key });

const removeImage = (dispatch) =>
  (key) => dispatch({ type: 'removeImage', payload: key });

export {
  initialState,
  reducer,
  addBanners,
  addBanner,
  updateBanner,
  addDisclaimers,
  addDisclaimer,
  updateDisclaimer,
  addImages,
  addImage,
  removeBanner,
  removeImage, 
  removeDisclaimer
};