import React, { useState } from 'react';
import { TextField, Autocomplete, Box, Avatar, Stack, Typography } from '@mui/material';

const UserSelect = ({placeholder, users, onUserSelected, selectedUser}) => {
    const [selected,setSelected] = useState(selectedUser);
    const handleSelection = (event, user) => {
        onUserSelected(user);
        setSelected(user);
    }  
    console.log("UserSelect", users);
    // var firstItem = true;
    return (
        <Autocomplete
            value={selected}
            getOptionLabel={(user)=>user.label}
            id="select-user"
            options={users}
            sx={{ width: 300 }}
            onChange={handleSelection}
            renderInput={(params) => <TextField {...params} placeholder={placeholder} />}
            renderOption={(props, recipient, { inputValue, selected }) => {
                const { label, organizations, thumbnailPhotoUrl } = recipient;
                var jobTitle = "";
                if (organizations && organizations.length > 0) {
                  jobTitle = organizations[0].title;
                }
                console.log("PROPS: ", props)
                return (
                  <>
                    {props["data-option-index"] == 0 && 
                      <Box sx={{padding: "10px 20px"}}>
                        <Typography variant="h6">Team members</Typography>
                        <Typography variant="body2" sx={{ color: 'text.secondary', mt: "2px" }}>You have {users.length} team members</Typography>
                      </Box>
                    }
                    <Stack direction="row" alignItems="center" spacing={2} {...props} sx={{padding: "10px 20px"}}>
                      <Box
                        sx={{
                          width: 48,
                          height: 48,
                          flexShrink: 0,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Avatar alt="Avatar" imgProps={{referrerPolicy:"no-referrer"}} src={thumbnailPhotoUrl ? thumbnailPhotoUrl : null} width={48} height={48} sx={{ mx: 'auto' }} />
                      </Box>

                      <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="subtitle2">{label}</Typography>
                        <Box sx={{ flexGrow: 1 }}>
                          <Typography variant="body2" sx={{ color: 'text.secondary', mt: "2px" }}>{jobTitle}</Typography>
                        </Box>
                      </Box>
                      
                    </Stack>
                  </>
                );
              }}
            />            
      );
  }
  
export default UserSelect;
 

