import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { SnackbarProvider } from 'notistack';
import React from 'react';

import infoFill from '@iconify/icons-eva/info-fill';
import alertCircleFill from '@iconify/icons-eva/alert-circle-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
import checkmarkCircle2Fill from '@iconify/icons-eva/checkmark-circle-2-fill';
// material
import { alpha, useTheme } from '@mui/material/styles';
import { Box, GlobalStyles } from '@mui/material';
import SnackbarCloseButton from './SnackbarCloseButton';

// ----------------------------------------------------------------------

function SnackbarStyles() {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  return (
    <GlobalStyles
      styles={{
        '#root': {
          '& .SnackbarContent-root': {
            maxWidth: '360px',
            padding: theme.spacing(1.5),
            margin: theme.spacing(0.25, 0),
            boxShadow: theme.customShadows.z8,
            borderRadius: theme.shape.borderRadius,
            color: theme.palette.grey[isLight ? 0 : 800],
            backgroundColor: theme.palette.grey[isLight ? 900 : 0],
            '&.SnackbarItem-variantSuccess': {
              color: "#212B36",
              backgroundColor: "#E9FCD4"
            },
            '&.SnackbarItem-variantSuccess .SnackbarItem-action button': {
              color: "#08660D"
            },
            '&.SnackbarItem-variantError': {
              color: "#7A0C2E",
              backgroundColor: "#FFE7D9"
            },
            '&.SnackbarItem-variantError .SnackbarItem-action button': {
              color: "#7A0C2E"
            },
            '&.SnackbarItem-variantWarning': {
              color: "#7A0C2E",
              backgroundColor: "#FFF7CD"
            },
            '&.SnackbarItem-variantWarning .SnackbarItem-action button': {
              color: "#7A4F01"
            },
            '&.SnackbarItem-variantInfo': {
              color: "#04297A",
              backgroundColor: "#D0F2FF"
            },
            '&.SnackbarItem-variantInfo .SnackbarItem-action button': {
              color: "#04297A"
            },
          },
          '& .SnackbarItem-message': {
            padding: '0 !important',
            paddingRight: '24px !important',
            fontWeight: theme.typography.fontWeightRegular
          },
          '& .SnackbarItem-message .MuiBox-root': {
            width: 'auto !important',
          },
          '& .SnackbarItem-action': {
            marginRight: 0,
            color: theme.palette.action.active,
            position: 'absolute',
            right: '4px',
            '& svg': { width: 20, height: 20 }
          }
        }
      }}
    />
  );
}

function SnackbarIcon({ icon, color }) {
  return (
    <Box
      component="span"
      sx={{
        mr: 1.5,
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: 1.5,
        alignItems: 'center',
        justifyContent: 'center',
        color: `${color}.main`
      }}
    >
      <Icon icon={icon} width={24} height={24} />
    </Box>
  );
}

NotistackProvider.propTypes = {
  children: PropTypes.node
};

export default function NotistackProvider({ children }) {
  return (
    <>
      <SnackbarStyles />

      <SnackbarProvider
        dense
        maxSnack={5}
        // preventDuplicate
        autoHideDuration={600000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        iconVariant={{
          success: <SnackbarIcon icon={checkmarkCircle2Fill} color="success" />,
          error: <SnackbarIcon icon={infoFill} color="error" />,
          warning: <SnackbarIcon icon={alertTriangleFill} color="warning" />,
          info: <SnackbarIcon icon={alertCircleFill} color="info" />
        }}
        action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}
      >
       {children}
      </SnackbarProvider>
    </>
  );
}
