import React from 'react'
import PropTypes from 'prop-types'
import UsersSignatureListItem from './UsersSignatureListItem'
import { Grid, Typography } from '@mui/material'

function UsersSignatureList({signatures=[], defaultSignature, domain='', buttons=[], action=(action)=>{}}) {

    console.log("UsersSignatureList", signatures);
    return (
        <Grid container spacing={3} mt={2} px={5}>
            {signatures.length > 0 ? signatures.sort((a, b) => a.path == defaultSignature ? -1 : 1).map(signature => 
                <UsersSignatureListItem 
                    key={signature.name}                     
                    action={action}
                    signature={signature} 
                    domain={domain} 
                    buttons={buttons}></UsersSignatureListItem>) : 
                <Typography textAlign="center" sx={{px: 3, width: "100%", mb: 3}}>
                  No signatures  
                </Typography>}
        </Grid>
    )
}

UsersSignatureList.propTypes = {
    signatures: PropTypes.arrayOf(PropTypes.object),
    domain: PropTypes.string,
    buttons: PropTypes.arrayOf(PropTypes.string),
    action: PropTypes.func
}

export default UsersSignatureList

