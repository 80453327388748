import React from "react";
import PropTypes from "prop-types";
import Frame from "./Frame";
import Sign from "./Sign";

import { useStateValue } from "../Context";

// Component for previewing full email
const Email = ({ template }) => (
  <html>
    <head>
      <title>Preview</title>
    </head>
    <body>
      <Sign template={template} />
    </body>
  </html>
);

Email.propTypes = {
  template: PropTypes.object
};

const PreviewBase = ({ conversions }) => {
  // eslint-disable-next-line no-unused-vars
  
  const [
    { template },
    //  dispatch
  ] = useStateValue();
  return (
    <div style={{ position: "relative", zIndex: 1001, padding: "20px" }}>
      <Frame width="100%" height="100%" conversions={conversions}>
        <Email template={template} />
      </Frame>
    </div>
  );
};

PreviewBase.propTypes = {
  conversions: PropTypes.object
};

export default PreviewBase;