export const redirectTo = (path) => dispatch => 
dispatch({ type: 'RedirectTo', path })

export const redirectSuccess = () => dispatch => 
dispatch({ type: 'RedirectSuccess' })

export const warnUserChanges = () => dispatch => {
    console.log("warnUserChanges");
    dispatch({ type: 'WarnUserChanges' })
}

export const userConfirmNavigation = () => dispatch =>  
dispatch({ type: 'ConfirmNavigation' })