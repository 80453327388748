import { Box, MenuItem, Select } from "@mui/material";
import React from "react";

const ConfiguratorBorderStyleWidget = ({ label, value, onChange }) => {
  const handleChange = (evt) => onChange(evt.target.value);

  const options = ["solid", "dashed", "dotted"];

  return (
    <>
        <Box flexGrow={1}>
            <h5>{label}</h5>
        </Box>
        <Box>
            <Select
                labelId="border-style-select-helper-label"
                id="border-style-select-helper"
                value={value || "solid"}
                onChange={handleChange}
                size="small"
                sx={{width: "194px", fontSize: "14px"}}>
                {options.map((option, index) => (
                    <MenuItem key={index} value={option}>
                    <Box style={{ width: "100%", height: "1rem" }}>
                        <div
                        style={{
                            position: "relative",
                            top: "50%",
                            borderBottomWidth: "2px",
                            borderBottomStyle: option,
                            borderBottomColor: "#999",
                        }}
                        ></div>
                    </Box>
                    </MenuItem>
                ))}
            </Select>
        </Box>
    </>
  )
}

export default ConfiguratorBorderStyleWidget;