import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';


import Drawer from '../UI/Base/Drawer';
import Chart from '../UI/Chart';
import Loading from './Loading';

const getStatsLine = (value, label) =>
  <Grid item xs={12} md={12} lg={12}>
    <Typography variant="h5" display="inline">
      {value}
    </Typography>
    <Typography variant="body1" display="inline" style={{paddingLeft: 10}}>
      {label}
    </Typography>
  </Grid>

const StatsView = ({ stats }) =>
  <Grid container spacing={1}>
    <Grid item xs={12} md={12} lg={12} style={{paddingBottom: 20}}>
      <Typography variant="h4">
        Statistics
      </Typography>
    </Grid>
    {getStatsLine(stats.users, 'Users')}
    {getStatsLine(stats.orgUnits, 'Organizational Units')}
    {getStatsLine(stats.templates, 'Templates')}
    {getStatsLine(stats.signaturesInUse, 'Signatures in use')}
    {getStatsLine(stats.banners, 'Banners')}
    {getStatsLine(stats.disclaimers, 'Disclaimers')}
  </Grid>

const Dashboard = ({ Gapi }) => {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const [stats, setStats] = useState({});

  useEffect(()=> {
    if (!Object.keys(stats).length) {
      Gapi.getStats()
        .then(({ stats }) => {
          setStats(stats || {});
        })
    }
  }, [Gapi, stats])
  

  return (
    <Drawer Gapi={Gapi} signOut={Gapi.signOut} title='Dashboard'>
      <Grid container spacing={3}>
        {/* Chart */}
        <Grid item xs={12} md={6} lg={6}>
          <Paper className={fixedHeightPaper}>
            {
              Object.keys(stats).length
                ? (<StatsView stats={stats} />)
                : <Loading />
            }
          </Paper>
        </Grid>
        {/* Chart */}
        <Grid item xs={12} md={6} lg={6}>
          <Paper className={fixedHeightPaper}>
            {
              Object.keys(stats).length
                ? (<Chart stats={stats} />)
                : <Loading />
            }
          </Paper>
        </Grid>
      </Grid>
    </Drawer>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 400,
  },
}));

export default Dashboard;
