import React from 'react';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  banking: getIcon('ic_banking'),
  mail: getIcon('ic_mail'),
  elements: getIcon('ic_elements'),
  menu: getIcon('ic_menu'),
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: null,
    items: [
      { title: 'Company profile', path: "/company", icon: ICONS.banking, disableable: false },
      { title: 'Users', path: "/users", icon: ICONS.user, disableable: true },
      { title: 'Signatures', path: "/signatures", icon: ICONS.mail, disableable: true },
      { title: 'Banners', path: "/banners", icon: ICONS.elements, disableable: true },
      { title: 'Disclaimers', path: "/disclaimers", icon: ICONS.menu, disableable: true }    
    ]
  },

  /*
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      {
        title: 'user',
        path: NAVIGATION_PATH.app.root,
        icon: ICONS.user,
        children: [
          { title: 'Four', path: NAVIGATION_PATH.app.pageFour },
          { title: 'Five', path: NAVIGATION_PATH.app.pageFive },
          { title: 'Six', path: NAVIGATION_PATH.app.pageSix }
        ]
      }
    ]
  }
  */
];

export default sidebarConfig;
