import { splitId } from "./helpers";

export default (state, action) => {
  // eslint-disable-next-line no-console
  console.info(action);
  switch (action.type) {
    case "moveRow": {
      // type: 'moveRow',
      // row: { destination: {index}, source: {index} }
      const { template } = state;
      const {
        row: { source, destination }
      } = action;

      const [srcIdx] = splitId(source.index);
      const [dstIdx] = splitId(destination.index);

      const movedRow = template.rows[srcIdx];
      const remainingRows = template.rows.filter((item, idx) => idx !== srcIdx);

      return {
        ...state,
        template: {
          ...template,
          rows: [
            ...remainingRows.slice(0, dstIdx),
            movedRow,
            ...remainingRows.slice(dstIdx)
          ]
        }
      };
    }

    case "addRow": {
      // type: 'addRow',
      // row: {id: index}
      const { template } = state;
      const rows = Array.from(template.rows);

      return {
        ...state,
        template: {
          ...template,
          rows: [
            ...rows,
            {
              width: template.width ? template.width : 568,
              columns: [{ width: template.width ? template.width : 568, contents: [] }]
            }
          ]
        }
      };
    }
    case "moveColumn": {
      // column: { destination: {index}, source: {index} }
      const { template } = state;
      const {
        column: { source, destination }
      } = action;

      const [srcRowIdx, srcIdx] = splitId(source.index);
      const [dstRowIdx, dstIdx] = splitId(destination.index);

      // No drag and drop between different rows.
      if (srcRowIdx !== dstRowIdx) {
        return state;
      }

      const row = template.rows[srcRowIdx];
      const remainingRows = template.rows.filter(
        (item, idx) => idx !== srcRowIdx
      );

      const movedColumn = row.columns[srcIdx];
      const remainingColumns = row.columns.filter(
        (item, idx) => idx !== srcIdx
      );

      return {
        ...state,
        template: {
          ...template,
          rows: [
            ...remainingRows.slice(0, srcRowIdx),
            {
              ...row,
              columns: [
                ...remainingColumns.slice(0, dstIdx),
                movedColumn,
                ...remainingColumns.slice(dstIdx)
              ]
            },
            ...remainingRows.slice(srcRowIdx)
          ]
        }
      };
    }
    case "addColumn": {
      // type: 'addRow',
      // row: {id: index}
      const {
        row: { id }
      } = action;
      // const rowIdx = Number.parseInt(id);
      const [rowIdx] = splitId(id);
      const { template } = state;
      const rows = Array.from(template.rows);
      const row = rows[rowIdx];

      // Máximo de 3 columnas
      if (row.columns.length < 3) {
        // Calculate widths
        let width = row.width || 568;
        let s = new Option().style;
        s.padding = row.padding || 0;
        let padding = `${(parseInt(s.paddingLeft) + parseInt(s.paddingRight))}px`;
        let colSpacing = row.colSpacing || 0;

        const cols = addColumnToColumnsAdjustingSize(
          row.columns,
          { contents: [] },
          width,
          padding,
          colSpacing
        );

        rows[rowIdx] = {
          ...row,
          columns: [...cols]
        };
      }

      return {
        ...state,
        template: {
          ...template,
          rows: [...rows]
        }
      };
    }
    case "removeColumn": {
      // type: 'removeColumn',
      // column: {id: index}
      // eslint-disable-next-line no-console
      console.info("remove row column");
      const {
        column: { id }
      } = action;

      const [rowIdx, colIdx] = splitId(id);
      const { template } = state;
      const rows = Array.from(template.rows);
      const row = rows[rowIdx];

      // Calculate widths
      let width = row.width || 568;
      let s = new Option().style;
      s.padding = row.padding || 0;
      let padding = `${(parseInt(s.paddingLeft) + parseInt(s.paddingRight))}px`;
      let colSpacing = row.colSpacing || 0;

      // Eliminamos la columna
      const columns = removeColumnFromColumnsAdjustingSize(
        rows[rowIdx].columns,
        colIdx,
        width,
        padding,
        colSpacing
      );

      rows[rowIdx] = { ...rows[rowIdx], columns: columns };

      // Si está vacía eliminamos la fila
      if (!rows[rowIdx].columns.length) {
        rows.splice(rowIdx, 1);
      }

      return {
        ...state,
        template: {
          ...template,
          rows: [...rows]
        }
      };
    }
    case "moveItem": {
      // type: 'moveItem',
      // item: { destination: {index}, source: {index} }
      const { template } = state;
      const {
        item: { source, destination }
      } = action;

      const [srcRowIdx, srcColIdx, srcItemIdx] = splitId(source.index);
      const [dstRowIdx, dstColIdx, dstItemIdx] = splitId(destination.index);

      const rows = Array.from(template.rows);

      // TODO: inmutable
      const movedItem = rows[srcRowIdx].columns[srcColIdx].contents.splice(
        srcItemIdx,
        1
      )[0];

      rows[dstRowIdx].columns[dstColIdx].contents.splice(
        dstItemIdx,
        0,
        movedItem
      );

      return {
        ...state,
        template: {
          ...template,
          rows: [...rows]
        }
      };
    }

    case "addItem": {
      // type: 'addItem',
      // item: {id: index, data: data}
      // eslint-disable-next-line no-console
      console.info("add ", action);
      const {
        item: { id, data }
      } = action;

      const [rowIdx, colIdx] = splitId(id);
      const { template } = state;
      let rows = Array.from(template.rows);
      // Fila
      let row = rows[rowIdx];
      let col = row.columns[colIdx];
      col.contents = [...col.contents, { ...data, width: data.name === 'Personal Photo' ? 150 : col.width }];

      return {
        ...state,
        template: {
          ...template,
          rows: [...rows]
        }
      };
    }
    case "removeItem": {
      // type: 'removeItem',
      // item: {id: index}
      // eslint-disable-next-line no-console
      console.info("add ", action);
      const {
        item: { id }
      } = action;

      // const [rowIdx, colIdx, itemIdx] = id
      //   .split("-")
      //   .map((s) => Number.parseInt(s));
      const [rowIdx, colIdx, itemIdx] = splitId(id);
      const { template } = state;
      const rows = Array.from(template.rows);
      // Fila
      let row = rows[rowIdx];
      let col = row.columns[colIdx];
      // Eliminamos el item
      col.contents.splice(itemIdx, 1);

      return {
        ...state,
        template: {
          ...template,
          rows: [...rows]
        }
      };
    }

    case "editItem": {
      // Dado un elemento seleccionado, que puede ser row, column o item
      // seleccionamos para edición.
      const {
        item: { id }
      } = action;

      const [rowIdx, colIdx, itemIdx] = splitId(id);
      const { template } = state;
      // eslint-disable-next-line no-console
      console.info("componentId", id, "structureId", rowIdx);

      let structure;
      let component = template;

      if (rowIdx !== undefined) {
        structure = component = component.rows[rowIdx];
      }
      if (colIdx !== undefined) {
        component = component.columns[colIdx];
      }
      if (itemIdx !== undefined) {
        component = component.contents[itemIdx];
      }

      return {
        ...state,
        config: true,
        structureId: rowIdx,
        componentId: id,
        structure: { ...structure },
        component: { ...component }
      };
    }

    // Discard changes
    case "discardChanges": {
      return {
        ...state,
        config: false,
        structureId: null,
        componentId: null,
        component: null,
        structure: null
      };
    }

    case "saveItem": {
      // Saves item config (width, font, padding, colors, etc...).
      // eslint-disable-next-line no-console
      console.info(action);
      // const {
      //   item: { id },
      // } = action;

      // const [rowIdx, colIdx, itemIdx] = splitId(id);
      // const { template } = state;

      // console.info(itemIdx);

      return {
        ...state,
        config: false,
        structureId: null,
        componentId: null,
        component: null,
        structure: null
      };
    }

    case "saveTemplateConfig": {
      // Saves template general config (width, font, colors, etc...).
      const { template, component, structure } = state;

      // Check template's width changes, because this changes the sizes of inner structures.
      const templateWidth = template.width ? template.width : action.payload.width;
      const newTemplateWidth = action.payload.width;

      let newComponent = {...component};
      let newStructure = {...structure};

      //Check if changes to restore styles
      if (action.userClick) {
          template.rows = template.rows.map(row => {
            return ({width: row.width, columns: row.columns.map(column => {
              return ({width: column.width, contents: column.contents.map(content => {
                switch(action.styleClicked) {
                  case "bgColor":
                    newComponent = {...component, bgColor: "inherit"};
                    newStructure = {...structure, bgColor: "inherit"};
                    return ({...content, bgColor: "inherit"});
                  case "textColor":
                    newComponent = {...component, textColor: "inherit"};
                    return ({...content, textColor: "inherit"});
                  case "fontFamily":
                    newComponent = {...component, fontFamily: "inherit"};
                    return ({...content, fontFamily: "inherit"});
                  case "textSize":
                    newComponent = {...component, textSize: "inherit"};
                    return ({...content, textSize: "inherit"});
                  case "lineHeight":
                    newComponent = {...component, lineHeight: "inherit"};
                    return ({...content, lineHeight: "inherit"});
                  case "borderWidth":
                    newComponent = {...component, borderWidth: action.payload.borderWidth};
                    return ({...content, borderWidth: action.payload.borderWidth});
                  case "borderColor":
                    newComponent = {...component, borderColor: "inherit"};
                    return ({...content, borderColor: "inherit"});
                  case "borderStyle":
                    newComponent = {...component, borderStyle:  action.payload.borderStyle};
                    return ({...content, borderStyle: action.payload.borderStyle});
                  default:
                    return ({...content});
                }
              })});
            })});
          });
      }

      let rows = [];
      if (templateWidth !== newTemplateWidth) {
        // Modifies structures according to width change.
        const diff = newTemplateWidth - templateWidth;

        template.rows.forEach((row) => {
          const colDiff = diff / row.columns.length;
          // eslint-disable-next-line no-unused-vars
          const cols = row.columns.map((col, i) => {
            let colWidth = col.width
              ? col.width + colDiff
              : newTemplateWidth / row.columns.length;

            return {
              ...col,
              width: colWidth,
              contents: col.contents.map((i) => ({
                ...i,
                width: Number.isInteger(colWidth)
                  ? Math.min(colWidth, i.width)
                  : colWidth
              }))
            };
          });

          rows.push({
            ...row,
            width: newTemplateWidth,
            lineHeight: template.lineHeight,
            columns: [...cols]
          });
        });
      } else {
        // No width changes
        rows = [...template.rows];
      }

      return {
        ...state,
        template: {
          ...action.payload,
          rows: rows
        },
        component: {...newComponent},
        structure: {...newStructure}
      };
    }

    case "saveStructureConfig": {
      // Saves template general config (width, font, colors, etc...).
      // eslint-disable-next-line no-console
      // eslint-disable-next-line no-console
      const { template, component } = state;
      const [rowIdx] = splitId(state.componentId);
      let row = template.rows[rowIdx];

      if (!row) return state;

      const {
        payload: { widths, colSpacing, bgColor, padding}
      } = action;

      let newComponent = {...component};

      //Check if changes to restore styles
      if (action.userClick) {
        row.columns = row.columns.map(column => {
          return ({width: column.width, contents: column.contents.map(content => {
            switch(action.styleClicked) {
              case "bgColor":
                newComponent = {...component, bgColor: "inherit"};
                return ({...content, bgColor: action.payload.bgColor});
              case "padding":
                newComponent = {...component, padding: action.payload.padding};
                return ({...content, padding: action.payload.padding});
              default:
                return ({...content});
            }
          })});
        });
      }

      const columns = adjustColumnsSize(
        row.columns.map((c, idx) => ({
          ...c,
          width: widths[idx],
          colSpacing
        })),
        row.width,
        padding
      );

      row = {
        ...row,
        colSpacing,
        bgColor,
        padding,
        columns
      };

      return {
        ...state,
        template: {
          ...template,
          rows: [
            ...template.rows.slice(0, rowIdx),
            row,
            ...template.rows.slice(rowIdx + 1)
          ]
        },
        structure: row,
        component: {...newComponent}
      };
    }

    case "saveComponentConfig": {
      const { template } = state;
      const [rowIdx, colIdx, itemIdx] = splitId(state.componentId);
      // eslint-disable-next-line no-console
      console.info("row", rowIdx, "col", colIdx, "item", itemIdx);
      const { payload } = action;

      let row = template.rows[rowIdx];
      if (itemIdx !== undefined) {
        // Editing item
        let column = row.columns[colIdx];
        let item = column.contents[itemIdx];
        item = {
          ...item,
          ...payload,
          width: Number.isInteger(payload.width)
            ? Math.min(column.width, payload.width)
            : column.width
        };
        // eslint-disable-next-line no-console
        console.info(item);
        return {
          ...state,
          component: { ...item },
          template: {
            ...template,
            rows: [
              ...template.rows.slice(0, rowIdx),
              {
                ...row,
                columns: [
                  ...row.columns.slice(0, colIdx),
                  {
                    ...column,
                    contents: [
                      ...column.contents.slice(0, itemIdx),
                      { ...item },
                      ...column.contents.slice(itemIdx + 1)
                    ]
                  },
                  ...row.columns.slice(colIdx + 1)
                ]
              },
              ...template.rows.slice(rowIdx + 1)
            ]
          }
        };
      } else if (colIdx !== undefined) {
        // Editing column
      } else {
        /// Editing row
      }
      break;
    }

    case 'addCompanyColor': {
      const { color } = action.payload
      return {
        ...state,
        companyColors: [...state.companyColors, color]
      };
    }

    case 'addImage': {
      const { image } = action.payload
      return {
        ...state,
        images: [...state.images, image]
      }
    }

    case 'removeImage': {
      const { imageKey } = action.payload
      return {
        ...state,
        images: [
          ...state.images.slice(0, imageKey),
          ...state.images.slice(imageKey + 1)
        ]
      }
    }

    case 'removeDisclaimer': {
      const { disclaimer } = action.payload
      return {
        ...state,
        disclaimers: state.disclaimers.filter((e) => (e !== disclaimer))
      }
    }

    case 'addDisclaimer': {
      const { disclaimer, key } = action.payload
      return {
        ...state,
        disclaimers: [...state.disclaimers, disclaimer],
        disclaimerKeys: [...state.disclaimerKeys, key]
      }
    }

    case 'updateDisclaimer': {
      const { disclaimer, index } = action.payload;
      return {
        ...state,
        disclaimers: [
          ...state.disclaimers.slice(0, index),
          { ...state.disclaimers[index], title: disclaimer.title, text: disclaimer.value },
          ...state.disclaimers.slice(index + 1)
        ]
      }
    }

    case 'addBanner': {
      const { banner } = action.payload;
      return {
        ...state, 
        banners: [
          ...state.banners,
          banner
        ]
      }
    }

    case 'updateBanner': {
      const { banner, index } = action.payload;
      state.banners[index] = banner;
      return {
        ...state
      }
    }

    case 'removeBanner': {
      const { bannerKey } = action.payload
      return {
        ...state,
        banners: [
          ...state.banners.slice(0, bannerKey),
          ...state.banners.slice(bannerKey + 1)
        ]
      }
    }

    default:
      // eslint-disable-next-line no-console
      console.info("default");
      return state;
  }
};

// Add a column to an array of columns, adjusting size
const addColumnToColumnsAdjustingSize = (
  columns,
  column,
  width,
  padding,
  colSpacing
) => {
  // Calculating total usable width
  const totalWidth = width - parseInt(padding, 10) - colSpacing * columns.length;

  // Expected column width after adding it. We divide the space proportionally.
  let expectedColWidth = totalWidth / (columns.length + 1);

  let cols = [...columns];
  // Keeping the width we have for substracting.
  let remainingWidth = totalWidth;
  for (let i = 0; i < cols.length; i++) {
    // Removing the width size proportionally.
    let newWidth = Math.ceil(
      cols[i].width - (colSpacing + expectedColWidth) / cols.length
    );
    newWidth = Math.max(30, newWidth); // Min 30
    cols[i].width = newWidth;
    remainingWidth = remainingWidth - newWidth; // updating the remaining width for substract.
  }
  return adjustColumnsSize(
    [...cols, { ...column, width: remainingWidth }],
    width,
    padding,
    colSpacing
  );
};

// Removes a column from an array of columns, adjusting size
const removeColumnFromColumnsAdjustingSize = (
  columns,
  index,
  width,
  padding,
  colSpacing
) => {
  const newColumns = [...columns.slice(0, index), ...columns.slice(index + 1)];
  return adjustColumnsSize(newColumns, width, padding, colSpacing);
};

const parsePaddingString = (paddingString) => {
  const style = new Option().style;
  style.padding = paddingString;

  return {
    left: Number.parseInt(style.paddingLeft),
    top: Number.parseInt(style.paddingTop),
    right: Number.parseInt(style.paddingRight),
    bottom: Number.parseInt(style.paddingBottom)
  };
};

// Adjust columns sizes from an array of columns if total width chanes
const adjustColumnsSize = (columns, width, paddingString, colSpacing) => {
  const padding = parsePaddingString(paddingString);
  const columnsSpacing = (colSpacing || 0) * (columns.length - 1);
  const availableWidth = width - padding.left - padding.right - columnsSpacing;

  let columnsWidths = columns.map((c) => c.width);
  let columnsWidth = columnsWidths.reduce((total, col) => total + col, 0);

  let delta = availableWidth - columnsWidth;

  let colDelta = Math.ceil(delta / columns.length);
  let newColumnsWidths = columnsWidths.map((width) => width + colDelta);

  // Adjusting last column
  const diff =
    availableWidth -
    newColumnsWidths.reduce((total, width) => total + width, 0);
  if (diff !== 0) {
    newColumnsWidths[newColumnsWidths.length - 1] += diff;
  }

  // Updating columns with widths
  return columns.map((c, i) => ({
    ...c,
    width: newColumnsWidths[i]
  }));
};

// const moveItem = (dispatch) => (source, destination) =>
//   dispatch({ type: "moveItem", item: { source, destination } });


export const addCompanyColor = (dispatch) =>
  (color) => dispatch({ type: 'addCompanyColor', payload: { color } });

export const addImage = (dispatch) =>
  (image) => dispatch({ type: 'addImage', payload: { image } });

export const removeImage = (dispatch) =>
  (imageKey) => dispatch({ type: 'removeImage', payload: { imageKey } })

export const removeDisclaimer = (dispatch) =>
  (disclaimer) => dispatch({ type: 'removeDisclaimer', payload: { disclaimer } })

export const addDisclaimer = (dispatch) =>
  (disclaimer, key) => dispatch({ type: 'addDisclaimer', payload: { disclaimer, key } })

export const updateDisclaimer = (dispatch) =>
  (disclaimer, index) => dispatch({ type: 'updateDisclaimer', payload: { disclaimer, index } })

export const addBanner = (dispatch) =>
  (banner) => dispatch({ type: 'addBanner', payload: { banner } });

export const updateBanner = (dispatch) =>
  (banner, index) => dispatch({ type: 'updateBanner', payload: { banner, index } })

export const removeBanner = (dispatch) =>
  (bannerKey) => dispatch({ type: 'removeBanner', payload: { bannerKey } })
