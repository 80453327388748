// import React from "react";
// import {
//   BrowserRouter as Router,
//   Route,
//   Navigate,
//   Routes,
//   Outlet
// } from "react-router-dom";

// import { connect } from "react-redux";

// import Dashboard       from '../Screens/Dashboard';
// import Company         from '../Screens/Company';
// import Signature       from '../Screens/Signature';
// import SignatureList   from '../Screens/SignatureList';
// import Users           from '../Screens/Users/Users';
// import SingleUser      from '../Screens/SingleUser/SingleUser';
// import Login           from '../Screens/Login';
// import Settings        from '../Screens/Settings';
// import Banners         from "../Screens/Banners";
// import Images from "../Screens/Images";
// import NewBanner from "../Screens/NewBanner";
// import BannerDetails from "../Screens/BannerDetails";




export const PATH_DASHBOARD       = '/dashboard';
export const PATH_COMPANY         = '/company';
export const PATH_SIGNATURES_EDIT = '/signatures/:signaturePath';
export const PATH_SIGNATURES_NEW  = '/newSignature';
export const PATH_SIGNATURES      = '/signatures';
export const PATH_BANNERS         = '/banners';
export const PATH_BANNERS_NEW     = '/banners/new';
export const PATH_BANNERS_SHOW    = '/banners/:bannerPath';
export const PATH_DISCLAIMERS      = '/disclaimers';
export const PATH_DISCLAIMERS_NEW  = '/disclaimers/new';
export const PATH_DISCLAIMERS_EDIT = '/disclaimers/:disclaimerPath/edit';
export const PATH_DISCLAIMERS_SHOW = '/disclaimers/:disclaimerPath';
export const PATH_USERS           = '/users';
export const PATH_SINGLE_USER     = '/users/:id';
export const PATH_SETTINGS        = '/settings';
export const PATH_IMAGES          = '/images';
export const PATH_LOGIN           = '/login';
export const PATH_PRIVACY         = '/privacy';
export const PATH_TERMS           = '/terms';
export const PATH_USER_NOT_GSUITE = '/error';
export const PATH_ERROR = '/error';

export const PATH_DEFAULT         = PATH_DASHBOARD;


// const mapStateToProps = ({context}) => ({context});



// export const Navigation = connect(mapStateToProps)(({ isSignedIn, Gapi, context }) =>
//     <Router>
//       <Routes>
//         <Route path={PATH_LOGIN} element={<Login isSignedIn={isSignedIn} Gapi={Gapi} context={context} />} />
//         <Route path={PATH_USER_NOT_GSUITE} element={ <UserNotGsuite Gapi={Gapi} context={context}/>} />
//         <Route path={PATH_PRIVACY} element={ <PrivacyRoute />} context={context}/>
//         <Route path={PATH_TERMS} element={ <TermsOfServiceRoute />} context={context}/>
//         <Route path={PATH_TERMS} element={ <TermsOfServiceRoute />} context={context}/>
//         <Route exact path={PATH_SIGNATURES} element={<PrivateRoute isSignedIn={isSignedIn} context={context}/>}>
//           <Route exact path={PATH_SIGNATURES} element={<SignatureList Gapi={Gapi} context={context}/>}/>
//         </Route>
//         <Route path={PATH_SIGNATURES_EDIT} element={<PrivateRoute isSignedIn={isSignedIn} context={context}/>}>
//           <Route path={PATH_SIGNATURES_EDIT} element={<Signature Gapi={Gapi} context={context}/>}/>
//         </Route>
//         <Route path={PATH_SIGNATURES_NEW} element={<PrivateRoute isSignedIn={isSignedIn} context={context}/>}>
//           <Route path={PATH_SIGNATURES_NEW} element={<Signature Gapi={Gapi} />}/>
//         </Route>
//         <Route exact path={PATH_DASHBOARD} element={<PrivateRoute isSignedIn={isSignedIn} context={context}/>}>
//           <Route exact path={PATH_DASHBOARD} element={<Dashboard Gapi={Gapi} />}/>
//         </Route>
//         <Route exact path={PATH_COMPANY} element={<PrivateRoute isSignedIn={isSignedIn} context={context} path={PATH_COMPANY}/>}>
//           <Route exact path={PATH_COMPANY} element={<Company Gapi={Gapi} context={context}/>}/>
//         </Route>
//         <Route exact path={PATH_USERS} element={<PrivateRoute isSignedIn={isSignedIn} context={context}/>}>
//           <Route exact path={PATH_USERS} element={<Users Gapi={Gapi} context={context}/>}/>
//         </Route>
//         <Route path={PATH_SINGLE_USER} element={<PrivateRoute isSignedIn={isSignedIn} context={context}/>}>
//           <Route exact path={PATH_SINGLE_USER} element={<SingleUser  Gapi={Gapi} context={context}/>}/>
//         </Route>
//         <Route path={PATH_BANNERS} element={<PrivateRoute isSignedIn={isSignedIn} context={context}/>}>
//           <Route path={PATH_BANNERS} element={<Banners Gapi={Gapi} context={context}/>}/>
//         </Route>
//         <Route path={PATH_BANNERS_SHOW} element={<PrivateRoute isSignedIn={isSignedIn} context={context}/>}>
//           <Route path={PATH_BANNERS_SHOW} element={<BannerDetails Gapi={Gapi} context={context}/>}/>
//         </Route>
//         <Route path={PATH_BANNERS_NEW} element={<PrivateRoute isSignedIn={isSignedIn} context={context}/>}>
//           <Route path={PATH_BANNERS_NEW} element={<NewBanner Gapi={Gapi} context={context}/>}/>
//         </Route>
//         <Route exact path={PATH_SETTINGS} element={<PrivateRoute isSignedIn={isSignedIn} context={context}/>}>
//           <Route exact path={PATH_SETTINGS} element={<Settings Gapi={Gapi} context={context}/>}/>
//         </Route>
//         <Route exact path={PATH_IMAGES} element={<PrivateRoute isSignedIn={isSignedIn} context={context}/>}>
//           <Route exact path={PATH_IMAGES} element={<Images Gapi={Gapi} context={context}/>}/>
//         </Route>
//         <Route exact path="/" render={() => (
//           <Navigate replace to={PATH_DEFAULT} />
//         )}/>
//       </Routes>
//     </Router>
// );
// const PrivateRoute = ({isSignedIn, context, path}) => {
//     // console.log('checking private is signedin', isSignedIn);
//     return <Outlet></Outlet>
//     // return isSignedIn ? 

//     //   context.navigationAvailable || path ? <Outlet /> : <Navigate to={PATH_COMPANY} /> :
//     //   <Navigate to={PATH_LOGIN} />;
// }

// const UserNotGsuite = ({ Gapi }) =>
//     <div>
//       <h1>User has to be a gsuite admin member for your domain</h1>
//       <a href="/login" onClick={() => Gapi.signOut()}>Login</a>
//     </div>

// const PrivacyRoute = () =>
//   <div>
// <h1>Privacy Policy</h1>
// <p><b>Effective date: September 9, 2019</b></p>

// <p>Your privacy is important to us. It is Applaud Ltd's policy to respect your privacy regarding any information we may collect from you across our Website, <a href="https://wedoops-signature.appspot.com/">https://wedoops-signature.appspot.com/</a>.</p>
// <h2>TL;DR</h2>
// <ul>
//   <li>Your personal information collected from personal info forms or signup forms is never shared, sold or rented to anyone, ever.</li>
//   <li>Your personal information is only shared between internal systems—all of whom have security safeguards in place.</li>
//   <li>No stats are collected via any service. Hence, your personal information is not shared, rented or sold to anyone.</li>
//   <li>If you want the data that’s been collected from our Website, contact wedoops@wedoops.io and we’ll give it to you.</li>
//   <li>If you want your data to be deleted from our Website, contact wedoops@wedoops.io and we’ll do that ASAP (although, after doing so, you’ll no longer be able to sign into your account and managed your signatures).</li>
// </ul>
// <h2>GDPR SPECIFICS</h2>
// <ul>
//   <li>Your data is protected using industry standard tools and software. It’s never shared, sold or given away, except to connect internal software accounts to other internally used software accounts.</li>
//   <li>If you want your data stored or collected, contact wedoops@wedoops.io.</li>
//   <li>The data is stored as long as our Website is live.</li>
//   <li>The data is stored in Google data centers.</li>
//   <li>The type of data stored via forms on our Website via Google Cloud Platform service:</li>
//     <ul>
//       <li>Company Data - Profile image, Company name, Webpage URL, LinkedIn URL, Instagram URL, Twitter URL, Facebook URL, YouTube URL</li>
//       <li>User Data - name, email, user role</li>
//       <li>Signature Templates Info (i.e. directions for the system on how to create automatic email templates that cannot be changed or edited by a single user)</li>
//     </ul>
// </ul>
// <h2>SECTION 1 – WHAT DO WE DO WITH YOUR INFORMATION?</h2>
// <p>When you sign up in our Website, as part of becoming our customer, we collect the personal information you give us such as your company’s name, user names, email addresses.</p>
// <p>When you browse our Website, we also automatically receive your computer’s internet protocol (IP) address in order to provide us with information that helps us learn about your browser and operating system.</p>
// <h2>SECTION 2 – CONSENT</h2>
// <p>How do you get my consent?</p>
// <p>When you provide us with personal information, we imply that you consent to our collecting it and using it for that specific reason only.</p>
// <p>How do I withdraw my consent?</p>
// <p>If after you sign up, you change your mind, you may withdraw your consent for the continued collection, use or disclosure of your information, at anytime, by contacting us at wedoops@wedoops.io or mailing us at: Wedoops IO, Pärnu mnt 69A, 10135 Tallinn, Estonia.</p>
// <h2>SECTION 3 – DISCLOSURE</h2>
// <p>We may disclose your personal information if we are required by law to do so or if you violate our Terms of Service. Otherwise, no one ever gets to see or use your personal data.</p>
// <h2>SECTION 4 – WEBSITE / SERVICE</h2>
// <p>Our Website is hosted on Google Cloud Platform. This provides us with the online service platform that allows us to offer our services to you.</p>
// <p>Our Website will:
// <ul>
//   <li>View domains related to your customers</li>
//   <li>View groups on your domain</li>
//   <li>View users on your domain</li>
//   <li>Manage your basic mail settings</li>
// </ul>
// </p>
// <p>Your data is stored through our Website in Google databases. They store your data on a secure server behind a firewall.</p>
// <h2>SECTION 5 – THIRD-PARTY SERVICES</h2>
// <p>We don’t use any third-party services. We only use Google services like Google Accounts for sign ups and sign ins. Also, our Website links to Google GSuite to load profile images.</p>
// <p>In general, all services used by us (like Google Accounts) will only collect, use and disclose your information to the extent necessary to allow them to perform the services they provide to us.</p>
// <h2>SECTION 6 – SECURITY</h2>
// <p>To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered or destroyed.</p>
// <h2>SECTION 7 – AGE OF CONSENT</h2>
// <p>By using this site, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.</p>
// <h2>SECTION 8 – CHANGES TO THIS PRIVACY POLICY</h2>
// <p>We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the Website. If we make material changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it.</p>
// <p>If our Website is acquired or merged with another company, your information may be transferred to the new owners so that we may continue to you our service.</p>
// <h2>QUESTIONS AND CONTACT INFORMATION</h2>
// <p>If you would like to: access, correct, amend or delete any personal information we have about you, register a complaint, or simply get more information by contacting our Privacy Compliance Officer at wedoops@wedoops.io or by mail at Wedoops IO, Pärnu mnt 69A, 10135 Tallinn, Estonia</p>
//   </div>

// const TermsOfServiceRoute = () =>
//   <div>
// <h2>Wedoops Terms of Service</h2>
// <h3>1. Terms</h3>
// <p>By accessing the website at <a href="https://wedoops.io/">https://wedoops.io/</a>, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.</p>
// <h3>2. Use License</h3>
// <ol type="a">
//    <li>Permission is granted to temporarily download one copy of the materials (information or software) on Wedoops IO's website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
//    <ol type="i">
//        <li>modify or copy the materials;</li>
//        <li>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
//        <li>attempt to decompile or reverse engineer any software contained on Wedoops IO's website;</li>
//        <li>remove any copyright or other proprietary notations from the materials; or</li>
//        <li>transfer the materials to another person or "mirror" the materials on any other server.</li>
//    </ol>
//     </li>
//    <li>This license shall automatically terminate if you violate any of these restrictions and may be terminated by Wedoops IO at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.</li>
// </ol>
// <h3>3. Disclaimer</h3>
// <ol type="a">
//    <li>The materials on Wedoops IO's website are provided on an 'as is' basis. Wedoops IO makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</li>
//    <li>Further, Wedoops IO does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.</li>
// </ol>
// <h3>4. Limitations</h3>
// <p>In no event shall Wedoops IO or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Wedoops IO's website, even if Wedoops IO or a Wedoops IO authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>
// <h3>5. Accuracy of materials</h3>
// <p>The materials appearing on Wedoops IO's website could include technical, typographical, or photographic errors. Wedoops IO does not warrant that any of the materials on its website are accurate, complete or current. Wedoops IO may make changes to the materials contained on its website at any time without notice. However Wedoops IO does not make any commitment to update the materials.</p>
// <h3>6. Links</h3>
// <p>Wedoops IO has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Wedoops IO of the site. Use of any such linked website is at the user's own risk.</p>
// <h3>7. Modifications</h3>
// <p>Wedoops IO may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service.</p>
// <h3>8. Governing Law</h3>
// <p>These terms and conditions are governed by and construed in accordance with the laws of Estonia and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>
//   </div>