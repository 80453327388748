import React from 'react';
import Drawer from '../UI/Base/Drawer';
import useSettings from '../hooks/useSettings';
import { Container, Grid, Card } from '@mui/material';
import Page from '../components/Page';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import BannerFormContainer from '../UI/containers/BannerFormContainer';

const NewBanner = ({ Gapi, context }) => {
    const { themeStretch } = useSettings(); 
    return (
        <Drawer title="Banners">
            <Page title="SignPro | New Banner">
                <Container maxWidth={themeStretch ? false : 'lg'}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <HeaderBreadcrumbs 
                                heading="Banners"
                                links={[{ name: 'Banners', href: "/banners" }, { name: 'New Banner' }]} />
                        </Grid>
                        <Grid item xs={12}>
                            <Card sx={{p: 5}}>
                                <BannerFormContainer />
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
                
            </Page>
        </Drawer>
    );
};

export default NewBanner;
