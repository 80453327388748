import React from 'react'
import {DialogTitle, FormLabel, FormControl, FormGroup, FormControlLabel, Checkbox, Typography, Stack, Divider} from '@mui/material';
import { MIconButton } from '../../components/@material-extend';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';


function UsersFilters({users, filter, onChange, handleClose}) {
  const [state, setState] = React.useState({
    jobTitleList: {},
    departmentList: {},
    orgUnitList: {}
  });

  React.useEffect(() => {
    setState({
      jobTitleList: users.filter(user => 
        user.organizations).reduce((checks, {organizations: [{title}]}) => 
          ({...checks,[title]: filter.jobTitleList.includes(title)}), {}),
      departmentList: users.filter(user => 
        user.organizations).reduce((checks, {organizations: [{department}]}) => 
          ({...checks,[department]: filter.departmentList.includes(department)}), {}),
      orgUnitList: users.reduce((checks, {orgUnit}) => ({...checks,[orgUnit]: filter.orgUnitList.includes(orgUnit)}), {} ), 
    })
  }, []);

  React.useEffect(() => {
    // build arrays including selected options
    const jobTitleList = Object.keys(state.jobTitleList)
      .reduce((list, jobTitle) => state.jobTitleList[jobTitle] ? list.concat([jobTitle]) : list, [])
    const departmentList = Object.keys(state.departmentList)
       .reduce((list, department) => state.departmentList[department] ? list.concat([department]) : list, [])
    const orgUnitList = Object.keys(state.orgUnitList)
       .reduce((list, orgUnit) => state.orgUnitList[orgUnit] ? list.concat([orgUnit]) : list, [])

    onChange({jobTitleList, departmentList, orgUnitList})
  },[state])

  const handleChange = (event) => 
    setState({
      ...state,
      [event.target.getAttribute('data-reference')]: {
        ... state[event.target.getAttribute('data-reference')],
        [event.target.name]: event.target.checked
      },
  });
  


return (<>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 2, pr: 1, pl: 2.5 }}>
          <Typography variant="subtitle1">Filters</Typography>
          <MIconButton onClick={handleClose}>
            <Icon icon={closeFill} width={20} height={20} />
          </MIconButton>
        </Stack>
        <Divider />
        <FormControl component="fieldset" variant="standard" sx={{ pr: 1, pl: 2, mt: 2 }}>
          <FormLabel component="legend"><b>Job Title</b></FormLabel>
          <FormGroup sx={{pl: 2}}>
            {
              Object.keys(state.jobTitleList).map(jobTitle => <FormControlLabel key={jobTitle}
                  control={
                    <Checkbox 
                      checked={state.jobTitleList[jobTitle]} 
                      onChange={handleChange} 
                      inputProps={{'data-reference': 'jobTitleList',}}
                      name={jobTitle} />
                  }
                  label={jobTitle}
              />)
            }
          </FormGroup>
        </FormControl>

        <FormControl component="fieldset" variant="standard" sx={{ pr: 1, pl: 2, mt: 2 }}>
          <FormLabel component="legend"><b>Department</b></FormLabel>
          <FormGroup sx={{pl: 2}}>
            {
              Object.keys(state.departmentList).map(department => <FormControlLabel key={department}
                  control={
                    <Checkbox 
                      checked={state.departmentList[department]} 
                      onChange={handleChange} 
                      inputProps={{'data-reference': 'departmentList',}}
                      name={department} />
                  }
                  label={department}
              />)
            }
          </FormGroup>
        </FormControl>


        <FormControl component="fieldset" variant="standard" sx={{ pr: 1, pl: 2, mt: 2 }}>
          <FormLabel component="legend"><b>Org. Unit</b></FormLabel>
          <FormGroup sx={{pl: 2}}>
            {
              Object.keys(state.orgUnitList).map(orgUnit => <FormControlLabel key={orgUnit}
                  control={
                    <Checkbox 
                      checked={state.orgUnitList[orgUnit]} 
                      onChange={handleChange} 
                      inputProps={{'data-reference': 'orgUnitList',}}
                      name={orgUnit} />
                  }
                  label={orgUnit}
              />)
            }
          </FormGroup>
        </FormControl>
    </>
    )
}

UsersFilters.propTypes = {

}

export default UsersFilters

