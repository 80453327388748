import React from 'react';
import { Box } from '@mui/material';


export default function Logo({ sx }) {


  return (
    <Box sx={{ height: 34, mb: 2, ...sx }}>
      <img style={{height: "100%"}} src="/static/brand/signpro-primary.png" alt="SignPro" />
    </Box>
  );
}
