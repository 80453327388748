import React from "react"
import { Droppable, Draggable } from 'react-beautiful-dnd';

import { List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, IconButton, Menu, MenuItem, Card, CardContent  } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Icon } from '@iconify/react';

import Item from "./Item"
import AddItem from "./AddItem"

import { useStateValue } from '../Context';
import {splitId} from "../helpers"
import AlertDialog from "../../../components/AlertDialog";

const ColumnHeader = ({index, isDragDisabled}) => {
  // eslint-disable-next-line no-unused-vars
  const [{ template }, dispatch] = useStateValue();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setAnchorEl(null);
    dispatch({
      type: "editItem",
      item: { id: index },
    });
  }

  const handleRemove = () => {
    setAnchorEl(null);
    setShowConfirmDialog(true);
  };

  return (
    <div>
      <AlertDialog
        open={showConfirmDialog}
        handleClose={() => setShowConfirmDialog(false)}
        title={"Are you sure you want to remove the selected column?"}
        cancelText="Cancel"
        confirmText='Confirm'
        handleAgree={() => {
          setShowConfirmDialog(false);
          dispatch({
            type: 'removeColumn',
            column: { id: index }
          });
        }} />
      <ListItem disablePadding={true} disableGutters={false}>
        <Card sx={{width: "100%", padding: "4px", margin: "4px 12px", backgroundColor: "#00AB55", display: "flex", alignItems: "center", color: "#fff", borderRadius: "8px"}}>
          <ListItemIcon sx={{color: "#fff", marginRight: "4px"}}>
            <Icon icon="material-symbols:open-with-rounded" width="20" height="20"  />
          </ListItemIcon>
          <ListItemText sx={{paddingRight: "26px"}}><b>Column {splitId(index)[1] + 1}</b></ListItemText>
          <ListItemSecondaryAction sx={{marginRight: "-16px"}}>
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
              sx={{color: "white", position: "relative", width: "32px", height: "32px"}}
            >
              <MoreVertIcon sx={{position: "absolute", right: "0"}} />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleEdit}>Edit</MenuItem>
              <MenuItem onClick={handleRemove}>Remove</MenuItem>
            </Menu>
          </ListItemSecondaryAction>
        </Card>
        
      </ListItem>
    </div>
  );
}

export default ({ data, index, isDragDisabled }) => {
  return (
    <Draggable
      type={`row-column`}
      draggableId={`row-column-${index}`}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <List dense disablePadding={true} >
            <div {...provided.dragHandleProps}>
              <ColumnHeader index={index} isDragDisabled={isDragDisabled} />
            </div>

            <Droppable droppableId={`items-${index}`} type={`column-${index}-items`}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {data.contents.map((item, i) => (
                    <Item
                      key={`column-${i}`}
                      data={item}
                      index={index * 100 + i + 1}
                      isDragDisabled={data.contents.length <= 1}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>

            <ListItem disableGutters={true}>
              <AddItem index={index} />
            </ListItem>
          </List>
        </div>
      )}
    </Draggable>
  );
};