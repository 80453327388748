import React from 'react';
import { useState } from 'react';
// material
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material';

// ----------------------------------------------------------------------

export default function AlertDialog({open, title, message, handleClose, handleAgree, cancelText = "Cancel", confirmText = "Confirm"}) {

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth id="dialog480">
        <DialogTitle sx={{pb: 3}}>{title}</DialogTitle>
        <DialogContent sx={{pb: 0}}>
          <DialogContentText id="alert-dialog-description" sx={{color: "#212B36", fontSize: "14px"}}>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" style={{
              color: "#212B36",
              borderColor: "rgba(145, 158, 171, 0.32)"
          }}>{cancelText}</Button>
          <Button onClick={() => {handleClose(); handleAgree();}} autoFocus variant="contained">
            {confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
