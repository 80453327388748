import React from "react";

import { Box, Select, MenuItem } from '@mui/material';

export default ({ label, value, onChange }) => {
  const handleChange = (evt) => onChange(evt.target.value);

  const fonts = [
    {value: "arial,'helvetica neue',helvetica,sans-serif", label: "Arial"},
    {value:"'courier new',courier,'lucida sans typewriter','lucida typewriter',monospace", label: "Courier New"},
    {value: "georgia,times,'times new roman',serif", label: "Georgia"},
    {value: "helvetica,'helvetica neue',arial,verdana,sans-serif", label: "Helvetica"},
    {value: "'lucida sans unicode','lucida grande',sans-serif", label: "Lucida Sans Unicode"},
    {value: "tahoma,verdana,segoe,sans-serif", label: "Tahoma"},
    {value: "'times new roman',times,baskerville,georgia,serif", label: "Time New Roman"},
    {value: "'trebuchet ms','lucida grande','lucida sans unicode','lucida sans',tahoma,sans-serif", label: "Trebuchet MS"},
    {value: "verdana,geneva,sans-serif", label: "Verdana"},
    {value: "arvo,courier,georgia,serif", label: "Arvo"},
    {value: "lato,'helvetica neue',helvetica,arial,sans-serif", label: "Lato"},
    {value: "lora,georgia,'times new roman',serif", label: "Lora"},
    {value: "merriweather,georgia,'times new roman',serif", label: "Merriweather"},
    {value: "'merriweather sans','helvetica neue',helvetica,arial,sans-serif", label: "Merriweather Sans"},
    {value: "'noticia text',georgia,'times new roman',serif", label: "Noticia Text"},
    {value: "'open sans','helvetica neue',helvetica,arial,sans-serif", label: "Open Sans"},
    {value: "'playfair display',georgia,'times new roman',serif", label: "Playfair Display"},
    {value: "roboto,'helvetica neue',helvetica,arial,sans-serif", label: "Roboto"},
    {value: "'source sans pro','helvetica neue',helvetica,arial,sans-serif", label: "Source Sans Pro"},
  ];
  
  return (
    <>
      <Box flexGrow={1}>
        <h5>{label}</h5>
      </Box>
      <Box>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={value||"inherit"}
          sx={{width: "194px", fontSize: "14px"}}
          size="small"
          onChange={handleChange}
        >
          <MenuItem value={"inherit"}>Default</MenuItem>
          {fonts.map((font, index) => (
            <MenuItem key={index} disabled={!font.value} value={font.value} style={{fontFamily:font.value}}>{font.label}</MenuItem>
          ))}
        </Select>
      </Box>
    </>
  );
};
