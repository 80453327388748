import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Icon } from '@iconify/react';
import { ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, IconButton, Menu, MenuItem, Card  } from '@mui/material';
import { useStateValue } from '../Context';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import AlertDialog from '../../../components/AlertDialog';

const Item = ({index, data, isDragDisabled}) => {
  // eslint-disable-next-line no-unused-vars
  const [{ template }, dispatch] = useStateValue();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setAnchorEl(null);
    dispatch({
      type: 'editItem',
      item: { id: index }
    });
  };
  const handleRemove = () => {
    setAnchorEl(null);
    setShowConfirmDialog(true);    
  };
  
  return (
    <div>
      <AlertDialog 
        open={showConfirmDialog} 
        handleClose={() => setShowConfirmDialog(false)} 
        title={"Are you sure you want to remove the selected component?"} 
        cancelText="Cancel"
        confirmText='Confirm'
        handleAgree={() => {
          setShowConfirmDialog(false);
          dispatch({
            type: "removeItem",
            item: { id: index },
          });
        }} />
      <ListItem disablePadding={true} disableGutters={false}>
        <Card sx={{width: "100%", padding: "4px", margin: "4px 12px", borderRadius: "8px", alignItems: "center", display: "flex"}}>
          <ListItemIcon sx={{color: "#00AB55", marginRight: "4px"}}>
            <Icon icon="material-symbols:open-with-rounded" width="20" height="20"  />
          </ListItemIcon>
          <ListItemText onClick={handleEdit} sx={{cursor: "pointer", paddingRight: "26px"}}>{data.name}</ListItemText>
          <ListItemSecondaryAction sx={{marginRight: "-16px"}}>
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
              sx={{position: "relative", width: "32px", height: "32px"}}
            >
              <MoreVertIcon sx={{position: "absolute", right: "0"}} />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleEdit}>Edit</MenuItem>
              <MenuItem onClick={handleRemove}>Remove</MenuItem>
            </Menu>
          </ListItemSecondaryAction>
        </Card>
      </ListItem>
    </div>
  );
}

export default ({ data, index, isDragDisabled }) => {
  const colIndex = index / 100;

  return (
    <Draggable
      type={`column-${colIndex}-items`}
      draggableId={`item-${index}`}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Item index={index} data={data} isDragDisabled={isDragDisabled} />
        </div>
      )}
    </Draggable>
  );
};
