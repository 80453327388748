import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";
import DisclaimerForm from '../components/DisclaimerForm';
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from 'redux';
import * as Gapi from '../../Helpers/Gapi';
import { warnUserChanges, userConfirmNavigation } from '../../Actions/Navigation';


const mapStateToProps = ({context}) => ({context});
const mapDispatchToProps = dispatch => bindActionCreators({ warnUserChanges, userConfirmNavigation }, dispatch);


const DisclaimerFormContainer = connect(mapStateToProps, mapDispatchToProps)(({context, warnUserChanges, userConfirmNavigation}) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [disclaimer, setDisclaimer] = useState({
        text: "",
        title: ""
    });
    const [submitting, setSubmitting] = useState(false);

    const submit = () => {
        setSubmitting(true);
        Gapi.saveDisclaimer(context.domain, disclaimer)
            .then((result) => {
                setSubmitting(false);
                userConfirmNavigation();
                navigate('/disclaimers');
                enqueueSnackbar('The disclaimer was saved successfully.', { variant: 'success' });
            }).catch(error => { 
                setSubmitting(false);
                enqueueSnackbar('Error saving disclaimer.', { variant: 'error' });
                console.error("SAVE DISCLAIMER ERROR: ", error);
            });
    }

    const [isAlreadyWarn, setIsAlreadyWarn] = React.useState(false);

    useEffect(() => { 
        if (disclaimer.text !== '' || disclaimer.title !== '') {
            if (!isAlreadyWarn && context && !context.warnUserChanges) { 
                setIsAlreadyWarn(true);
                warnUserChanges(); 
            }
        }
     }, [disclaimer]);

    return (
        <DisclaimerForm disclaimer={disclaimer} setDisclaimer={setDisclaimer} submit={submit} submitting={submitting} />
    )
});

export default DisclaimerFormContainer;