import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { REACT_TRACKER_ENDPOINT } from '../../../config/env';
import { NOT_PROVIDED, NOT_PROVIDED_COMPANY } from '../../../config/globals';

const HtmlWrapperColor = ({ width, color, bgColor, fontFamily, lineHeight, children, textSize, borderColor, borderWidth, borderStyle }) => {
  return (
    <div
      className="sig-wrapper-color"
      style={{
        backgroundColor: bgColor ? bgColor : 'transparent',
        fontFamily,
        fontSize: textSize,
        color,
        lineHeight,
        borderColor,
        borderWidth,
        borderStyle
      }}
    >
      {/* <!--[if gte mso 9]>
			    <v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t">
				    <v:fill type="tile" color="#f6f6f6"></v:fill>
			    </v:background>
		      <![endif]--> */}
      <table
        className="sig-wrapper"
        width="100%"
        cellSpacing="0"
        cellPadding="0"
      >
        <tbody>
          <tr>
            <td className="sigd-email-paddings st-br" valign="top">
              <table
                bgcolor={'transparent'}
                style={{ backgroundColor: 'transparent', color: color }}
                align="left"
                cellPadding="0"
                cellSpacing="0"
                width={width}
              >
                <tbody>
                  <tr>
                    <td
                      className="sigd-structure sig-p0t sig-p0l sig-p0b sig-p0r"
                      align="left"
                    >
                      <table cellPadding="0" cellSpacing="0" width="100%">
                        <tbody>
                          <tr>
                            <td
                              width={width}
                              className="sigd-container-frame"
                              align="center"
                              valign="top"
                            >
                              {children}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

HtmlWrapperColor.propTypes = {
  width: PropTypes.number,
  color: PropTypes.string,
  bgColor: PropTypes.string,
  fontFamily: PropTypes.string,
  lineHeight: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element)
};

const HtmlRowWrapper = ({ width, children }) => {
  return (
    <table
      cellPadding="0"
      cellSpacing="0"
      className="sig-content"
      align="center"
    >
      <tbody>
        <tr>
          <td
            className="sigd-stripe"
            style={{ backgroundColor: 'transparent' }}
            bgcolor="transparent"
            align="center"
          >
            <table
              bgcolor="transparent"
              width={width}
              cellPadding="0"
              cellSpacing="0"
              style={{ backgroundColor: 'transparent' }}
            >
              <tbody>
                <tr>{children}</tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

HtmlRowWrapper.propTypes = {
  width: PropTypes.number,
  children: PropTypes.element
};

const HtmlRow = ({ row, children }) => {
  const colSpacing = row.colSpacing || 0;
  const bgColor = row.bgColor || 'transparent';
  const padding = row.padding || '0';

  return (
    <td
      className="sigd-structure sig-p40t sig-p40b sig-p40r sig-p40l"
      align="left"
      style={{ backgroundColor: bgColor, padding: padding }}
      bgcolor={bgColor}
    >
      <table
        width="100%"
        cellPadding="0"
        cellSpacing="0"
        style={{ backgroundColor: bgColor }}
        bgcolor={bgColor}
      >
        <tr>
          {children(row.columns).reduce(
            (result, column, i, array) =>
              i < array.length - 1
                ? result.concat(column, <td key={i} width={colSpacing}></td>)
                : result.concat(column),
            []
          )}
        </tr>
      </table>
    </td>
  );
};

HtmlRow.propTypes = {
  row: PropTypes.object,
  children: PropTypes.func
};

// TODO: añadir aquí el color de la columna?
const HtmlColumn = ({ column, children }) => {
  var s = new Option().style;
  s.padding = column.padding;
  return (
    <td width={`${column.width}`} valign="top">
      <table cellPadding="0" cellSpacing="0" className="sig-left" align="left">
        <tbody>
          <tr>
            <td
              width={column.width || 'auto'}
              className="sigd-container-frame sig-m-p20b"
              align="left"
              style={{
                paddingLeft: s.paddingLeft || 'inherit',
                paddingRight: s.paddingRight || 'inherit',
                paddingTop: s.paddingTop || 'inherit',
                paddingBottom: s.paddingBottom || 'inherit'
              }}
            >
              <table cellPadding="0" cellSpacing="0" width="100%">
                <tbody>
                  {children(column.contents).map((component, i) => (
                    <tr key={i}>{component}</tr>
                  ))}
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </td>
  );
};

HtmlColumn.propTypes = {
  column: PropTypes.object,
  children: PropTypes.func
};

// Item
const HtmlContent = ({ lineHeight, content, context }) => {
  if (content.type === 'image') {
    if (content.name === 'Banner')
      return HtmlBannerContent({ content, context });
    else 
      return HtmlImageContent({ content });
  } else if (content.type === 'spacer') {
    return HtmlSpacerContent({ content });
  }
  return HtmlTextContent({ lineHeight, content });
};

const HtmlBannerContent = ({ content, context }) => {

  const prepareLink = (value) => { 
    return  value.track ? `${REACT_TRACKER_ENDPOINT}/t/sp/${value._id}/${window.btoa(value.link)}` : value.link;
  }
  const style = {
    padding: content.padding,
    color: content.textColor,
    backgroundColor: content.bgColor,
    textAlign: content.align,
    fontFamily: content.fontFamily,
    borderWidth: content.borderWidth,
    borderStyle: content.borderStyle,
    borderColor: content.borderColor
  };
  
  const width = content.width || '100%';
  const value = content.value;
  return (
    <td
      className="sigd-block-image"
      align="left"
      bgcolor={content.bgColor}
      style={style}
    >
      {value.link && value.link.length > 0 ? 
        <a href={prepareLink(value)} target="_blank" rel="noopener noreferrer">
          <img src={value.imageUrl} alt="" style={{ maxWidth: '100%', width: width }} />
        </a>
      : 
        <img src={value.imageUrl} alt="" style={{ maxWidth: '100%', width: width }} />
      }
    </td>
  );
};

const HtmlImageContent = ({ content }) => {
  const style = {
    padding: content.padding,
    color: content.textColor,
    backgroundColor: content.bgColor,
    textAlign: content.align,
    fontFamily: content.fontFamily,
    borderWidth: content.borderWidth,
    borderStyle: content.borderStyle,
    borderColor: content.borderColor
  };
  const width = content.width || '100%';
  const value = content.value;
  return (
    <td
      className="sigd-block-image"
      align="left"
      bgcolor={content.bgColor}
      style={style}
    >
      <img src={value} alt="" style={{ maxWidth: '100%', width: width }} />
    </td>
  );
};

HtmlImageContent.propTypes = {
  content: PropTypes.object.isRequired
};

const HtmlSpacerContent = ({ content }) => {
  const width = content.width || 568;
  const align = content.align || 'center';
  const bgColor = content.bgCoslor || 'transparent';
  const padding = content.padding || '20px';
  const border = content.border || '1px solid #ccc';
  const style = {
    padding: padding,
    backgroundColor: bgColor,
    fontFamily: content.fontFamily
  };

  return (
    <td
      className="sigd-block-spacer"
      align={align}
      bgcolor={bgColor}
    >
      <table
        border="0"
        width={`${width}px`}
        height="100%"
        cellPadding="0"
        cellSpacing="0"
      >
        <tbody>
          <tr>
            <td style={style}><p style={{
                borderBottom: border,
                background: 'none',
                height: 1,
                width: '100%',
                margin: '0px 0px 0px 0px'
              }}></p></td>
          </tr>
        </tbody>
      </table>
    </td>
  );
};

HtmlSpacerContent.propTypes = {
  content: PropTypes.object.isRequired
};

const HtmlTextContent = ({ content, lineHeight }) => {
  const style = {
    lineHeight,
    padding: content.padding,
    color: content.textColor,
    backgroundColor: content.bgColor,
    textAlign: content.align,
    fontFamily: content.fontFamily,
    fontSize: content.textSize,
    borderWidth: content.borderWidth,
    borderStyle: content.borderStyle,
    borderColor: content.borderColor,
    ...JSON.parse(content.textStyle || '{}')
  };
  const value = content.value;
  return (
    <td
      className="sigd-block-text"
      align={content.align}
      bgcolor={content.bgColor}
      style={style}
      dangerouslySetInnerHTML={{__html: value}}
    />
  );
};

HtmlTextContent.propTypes = {
  content: PropTypes.object.isRequired,
  lineHeight: PropTypes.string
};

const Sign = ({ template, context }) => (
  <HtmlWrapperColor
    width={template.width || 568}
    padding={template.padding}
    bgColor={template.bgColor}
    color={template.textColor}
    lineHeight={template.lineHeight}
    fontFamily={template.fontFamily}
    textSize={template.textSize}
    borderWidth={template.borderWidth}
    borderColor={template.borderColor}
    borderStyle={template.borderStyle}
  >
    {template.rows.map((row, i) => (
      <HtmlRowWrapper
        key={i}
        width={template.width || 568}
        bgcolor={template.bgColor}
        padding={template.padding}
      >
        <HtmlRow row={row}>
          {(columns) =>
            columns.map((column, i) => (
              <HtmlColumn key={i} column={column}>
                {(contents) =>
                  contents.filter((content) => content.value !== NOT_PROVIDED && content.value !== NOT_PROVIDED_COMPANY).map((content, i) => (
                    <HtmlContent
                      key={i}
                      content={content}
                      context={context}
                      lineHeight={content.lineHeight || template.lineHeight}
                    />
                  ))
                }
              </HtmlColumn>
            ))
          }
        </HtmlRow>
      </HtmlRowWrapper>
    ))}
  </HtmlWrapperColor>
);

Sign.propTypes = {
  template: PropTypes.object
};

// const mapStateToProps = ({context}) => ({context});
// export default connect(mapStateToProps)(Sign);
export default Sign;