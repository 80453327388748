import React, { useEffect, useState } from 'react';

import { Box, Popover, Typography, Button, TextField } from '@mui/material';

import { useStateValue } from '../../Context';
import * as Gapi from '../../../../Helpers/Gapi';
import * as reducer from '../../reducer';

import { connect } from "react-redux";


const colors = [
  '', '#FF0003', '#FF0083', '#9B00FF', '#AC60DF', '#4734D1',
  '#11C979', '#00A077', '#35DADB', '#49C2F6', '#2697ED',
  '#34D947', '#AAEC6A', '#FDF927', '#FDC427', '#FEAA22',
  '#000000', '#F5F5F5', '#BABABA', '#A66E50', '#FC661E'
];

const mapStateToProps = ({context}) => ({context});
export default connect(mapStateToProps)(({ label, value, onChange, context }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [colorInput, setColorInput] = useState(value || "inherit");
  const open = Boolean(anchorEl);
  const id = open ? 'palette_popover' : undefined;

  const [{ companyColors }, dispatch] = useStateValue();
  const uniqCompanyColors = [...new Set(companyColors)]
  const companyColorsToShow = uniqCompanyColors.length > 10 ? uniqCompanyColors.slice(uniqCompanyColors.length - 10) : uniqCompanyColors

  useEffect(() => {
    setColorInput(value || "");
  }, [value])

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const validateColor = (color) => {
    let s = new Option().style;
    s.color = color;
    return s.color !== "";
  };

  const handleChange = () => {
    const value = colorInput;
    if (validateColor(value)) {
      Gapi.saveCompanyColor(value, context.domain).then(() => {
        reducer.addCompanyColor(dispatch)(value)
      });
      onChange(value);
    }
    handleClose();
  };

  const handleColorChange = (e) => {
    onChange(e.target.dataset.color);
  }

  const handleInputChange = (e) => {
    setColorInput(e.target.value || "");
  }
  
  return (
    <Box display="flex" flexWrap="nowrap" width="100%" style={{ alignContent: "center", alignItems: "center", justifyContent: "space-between" }}>
      <Box>
        <h5>{label}</h5>
      </Box>
      <Box display="flex" flexWrap="nowrap" alignItems="center">
        <div
          style={{
            backgroundColor: colorInput,
            backgroundImage: colorInput === "inherit" || colorInput === "" || colorInput === "initial" ? "linear-gradient(to bottom right,  transparent calc(50% - 2px), red, transparent calc(50% + 2px))" : "",
            width: "36px",
            height: "36px",
            borderRadius: "50%",
            boxShadow: `4px 4px 8px #eee`,
            marginRight: "8px"
          }}
          onClick={handleClick}
        />
        <TextField
            label="HEX colour code"
            value={colorInput && colorInput}
            size="small"
            sx={{fontSize: "14px"}}
            InputLabelProps={{ shrink: true }}
            onClick={handleClick}
        />
      </Box>
      
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{boxShadow: `4px 4px 8px #eee`}}
      >
        <Box bgcolor={'white'} width="244px">
          <Box bgcolor={colorInput} />
          <Box display="flex" flexWrap="wrap" sx={{paddingLeft: "16px", paddingTop: "16px"}}>
            {colors.map((color, index) => (
              <div
                key={index}
                data-color={color}
                style={{
                  backgroundColor: color,
                  backgroundImage: color === "inherit" || color === "" || color === "initial" ? "linear-gradient(to bottom right,  transparent calc(50% - 2px), red, transparent calc(50% + 2px))" : "",
                  width: "36px",
                  height: "36px",
                  borderRadius: "50%",
                  boxShadow: `4px 4px 8px #eee`,
                  marginRight: "8px",
                  marginBottom: "8px",
                  cursor: "pointer"
                }}
                onClick={handleColorChange}
                />
            ))}
            <Box display="flex" width={1} sx={{paddingRight: "16px", paddingTop: "8px"}} justifyContent='space-between' alignItems='center'>
              <TextField inputProps={{style: {padding: '0.4em', margintRight: "8px"}}} variant="outlined" value={colorInput} onChange={handleInputChange} />
              <Button variant="contained" color="primary" onClick={handleChange}>Ok</Button>
            </Box>
          </Box>
          {
            companyColors.length > 0 ?
              <Box sx={{padding: "16px", paddingRight: "8px"}}>
                <Typography >Company colors</Typography>
                <Box display="flex" flexWrap="wrap">
                  {companyColorsToShow.map((color, index) => (
                    <div
                      key={index}
                      data-color={color}
                      style={{
                        backgroundColor: color,
                        backgroundImage: color === "inherit" || color === "" || color === "initial" ? "linear-gradient(to bottom right,  transparent calc(50% - 2px), red, transparent calc(50% + 2px))" : "",
                        width: "36px",
                        height: "36px",
                        borderRadius: "50%",
                        boxShadow: `4px 4px 8px #eee`,
                        marginRight: "8px",
                        marginBottom: "8px",
                        cursor: "pointer"
                      }}
                      onClick={handleColorChange}
                      />
                  ))}
                </Box>
              </Box>
            :
            null
          }
        </Box>
      </Popover>
    </Box>
  );
});
