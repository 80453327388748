import React from 'react';
import { Link } from 'react-router-dom';
import CustomDataGrid from '../../components/CustomDataGrid';




const UsersTable = ({ users, defaultSignatureUsers, onSelectedUsers, domainSelected }, ref) => {

  const _users = users?.map(user => ({...user, defaultEnabled: defaultSignatureUsers.indexOf(`${user.primaryEmail.split("@")[0]}@${domainSelected}`) !== -1}))

  const columns = [
    { field: 'fullName', headerName: 'Full Name', width: 70 },
    { field: 'organizations[0].title', headerName: 'Job Title', width: 130 },
    { field: 'organizations[0].department', headerName: 'Department', width: 130 },
    { field: 'orgUnit', headerName: 'Org Unit.', width: 130 },
    { field: 'defaultEnabled', headerName: 'Default Signature', width: 130 },
  ];

  return (      
  <CustomDataGrid
    ref={ref}
    rows={_users}
    columns={columns}
    onChange={onSelectedUsers}
    pageSize={10}
    rowsPerPageOptions={[5]}
    checkboxSelection
  />);
}

export default React.forwardRef(UsersTable);
