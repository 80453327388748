import React from "react";
// import PropTypes from "prop-types";
import { Box, ToggleButtonGroup, ToggleButton, styled, Switch } from '@mui/material';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: "1px solid !important",
    '&.Mui-disabled': {
      border: 0,
    },
    '&.Mui-selected': {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      backgroundColor: "inherit"
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

export default ({ label, value, onChange, customiseSwitch }) => {
  const [customise, setCustomise] = React.useState(value !== "inherit");
  const handleCustomise = (evt, value) => {
    setCustomise(value);
    if (!value) {
      onChange("inherit");
    } else {
      onChange("120%");
    }
  };
  const handleChange = (event, lineHeight) => {
    if (lineHeight) onChange(lineHeight);
  };
  return (
    <>
      <Box sx={{ width: 1/4 }}>
        <h5>{label}</h5>
      </Box>
      {customiseSwitch && 
        <Box sx={{ width: 3/4, textAlign: "end" }}>
          <span style={{fontSize: "14px"}}>Customise</span>
          <Switch
            checked={customise}
            onChange={handleCustomise}
            color="primary"
            inputProps={{ "aria-label": "show All" }}
          />
        </Box>
      }
      <Box flexGrow={1} textAlign="end">
        {(customise || !customiseSwitch) &&
          <StyledToggleButtonGroup
            size="small"
            value={value}
            exclusive
            onChange={handleChange}
          >
            <ToggleButton value="100%">1.0</ToggleButton>
            <ToggleButton value="120%">1.2</ToggleButton>
            <ToggleButton value="150%">1.5</ToggleButton>
            <ToggleButton value="200%">2.0</ToggleButton>
          </StyledToggleButtonGroup>
        }
      </Box>
    </>
  );
};
