export const getSignatureWithBannerModel = (signature) => {
    const schema = JSON.parse(signature.schema);
    const banner = findBanner(schema);
    return {...signature, banner: banner};
}

const findBanner = (theObject) => {
    var result = null;
    if (theObject instanceof Array) {
        for (var i = 0; i < theObject.length; i++) {
            result = findBanner(theObject[i]);
            if (result) {
                break;
            }
        }
    } else {
        for (var prop in theObject) {
            if (prop == 'name') {
                if (theObject[prop] == "Banner") {
                return theObject.value;
                }
            }
            if (theObject[prop] instanceof Object || theObject[prop] instanceof Array) {
                result = findBanner(theObject[prop]);
                if (result) {
                break;
                }
            }
        }
    }
    return result;
}