import React from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip,
} from 'recharts';

const _parseStats = (stats) => ([
    { name: 'Users', value: stats.users },
    { name: 'Org. units', value: stats.orgUnits },
    { name: 'Templates', value: stats.templates },
    { name: 'Banners', value: stats.banners },
    { name: 'Disclaimers', value: stats.disclaimers },
  ]);

export default ({ stats }) => {
  const data = _parseStats(stats);
    return (
      <BarChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="value" fill="#3F51B9" />
      </BarChart>
    );
}
