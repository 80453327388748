import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Box, Button, Container, styled, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { signIn } from '../Helpers/Gapi';
import GoogleSignIn from '../UI/GoogleSignIn';

const ContentStyle = styled('div')(() => ({
  maxWidth: 681,
  margin: 'auto',
  display: 'flex',
  textAlign: 'center',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: 0
}));

const NonAdmin = ({ redirectTo }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [textCopied, setTextCopied] = useState(false);

  const signInWrapper = async () => {
    await signIn();
    redirectTo('/company');
  };

  const copyText = () => {
    enqueueSnackbar('Text copied to clipboard', { variant: 'info' });
    setTextCopied(true);
    navigator.clipboard.writeText('www.signpro.app');
  };

  return (
    <Container>
      <ContentStyle>
        <Box
          sx={{
            mb: 3,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 2
          }}
        >
          <Typography variant="h3">
            Dear guest, <br />
            unfortunately log in has failed :(
          </Typography>
          <Typography variant="body1" sx={{ color: 'text.secondary' }}>
            To log in to app.signpro.app, You need to have Google Workspace
            administrator account.
          </Typography>
          <GoogleSignIn sx={{ width: '500px' }} onClick={signInWrapper}>
            Use another account
          </GoogleSignIn>
          <Typography sx={{ color: 'text.secondary', mt: 8, mb: 2 }}>
            If you don’t have these credentials, but you still would like to
            experience SignPro benefits, please share SignPro webpage with your
            Google Workspace administrator 💚
          </Typography>
          <Button
            size="large"
            variant="contained"
            sx={{ width: 'fit-content' }}
            onClick={copyText}
          >
            {textCopied ? 'Copied SignPro URL' : 'Copy SignPro URL'}
          </Button>
        </Box>
      </ContentStyle>
    </Container>
  );
};

NonAdmin.propTypes = {
  redirectTo: PropTypes.func.isRequired
};

export default NonAdmin;
