import React from 'react';
import {Grid, TextField, InputAdornment} from '@mui/material';
import twitterFill from '@iconify/icons-eva/twitter-fill';
import linkedinFill from '@iconify/icons-eva/linkedin-fill';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import instagramFilled from '@iconify/icons-ant-design/instagram-filled';
import youtubeFilled from '@iconify/icons-ant-design/youtube-filled';
import { Icon } from '@iconify/react';

export default ({
  data,
  onChange,
}) => {

  
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            required
            id="companyName"
            name="companyName"
            label="Company name"
            fullWidth
            autoComplete="company-name"
            value={data.companyName}
            variant="outlined"
            onChange={(evt) => {
              onChange('companyName', evt.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            id="webpage"
            name="webpage"
            label="Webpage"
            fullWidth
            autoComplete="company-webpage"
            value={data.webpage}
            variant="outlined"
            onChange={(evt) => {
              onChange('webpage', evt.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="linkedin"
            name="linkedin"
            fullWidth
            autoComplete="company-linkedin"
            value={data.linkedin}
            variant="outlined"
            onChange={(evt) => {
              onChange('linkedin', evt.target.value);
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start"><Icon icon={linkedinFill} height={24} /></InputAdornment>
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="instagram"
            name="instagram"
            fullWidth
            autoComplete="company-instagram"
            value={data.instagram}
            variant="outlined"
            onChange={(evt) => {
              onChange('instagram', evt.target.value);
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start"><Icon icon={instagramFilled} height={24} /></InputAdornment>
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="twitter"
            name="twitter"
            fullWidth
            autoComplete="company-twitter"
            value={data.twitter}
            variant="outlined"
            onChange={(evt) => {
              onChange('twitter', evt.target.value);
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start"><Icon icon={twitterFill} height={24} /></InputAdornment>
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="facebook"
            name="facebook"
            fullWidth
            autoComplete="company-facebook"
            value={data.facebook}
            variant="outlined"
            onChange={(evt) => {
              onChange('facebook', evt.target.value);
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start"><Icon icon={facebookFill} height={24} /></InputAdornment>
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="youtube"
            name="youtube"
            fullWidth
            autoComplete="company-youtube"
            value={data.youtube}
            variant="outlined"
            onChange={(evt) => {
              onChange('youtube', evt.target.value);
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start"><Icon icon={youtubeFilled} height={24} /></InputAdornment>
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

/*
const useStyles = makeStyles((theme) => ({
  divider: {
    marginBottom: theme.spacing(2)
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },

}));
*/