import React, { useState } from 'react';
// material
import { styled, useTheme } from '@mui/material/styles';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { signOut } from '../../Actions/Auth';
import { getStripeCheckoutSession, getStripeManagementPortal } from '../../Helpers/Gapi';
import Loading from '../../Screens/Loading';
import { Button } from '@mui/material';


// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------
const Drawer = ({ auth, context, signOut, children }) => {

  const theme = useTheme();
  const { collapseClick } = useCollapseDrawer();
  const [open, setOpen] = useState(false);

  const subscription = context.company ? context.company.subscription : {};

  const openManageSubscription = () => {
    getStripeManagementPortal(context.domain, {
      returnUrl: window.location.href
    }).then((res) => {
      window.location.href = res.manageUrl;
    }).catch((err) => {
      //TODO: snackbar?
      console.error(err);
    });
  }

  const openCheckout = () => {
    getStripeCheckoutSession(context.domain, {
          successUrl: window.location.href,
          cancelUrl: window.location.href
        }).then((res) => {
          window.location.href = res.checkoutUrl
        }).catch((err) => {
          console.error(err);
        });
  }

  return (
    <RootStyle>
      <DashboardNavbar
        onOpenSidebar={() => setOpen(true)}
        user={auth.user}
        signOut={signOut}
        openManageSubscription={openManageSubscription}
        openCheckout={openCheckout}
        subscription={subscription}
      />

      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} user={auth.user} />

      <MainStyle
        sx={{
          transition: theme.transitions.create('margin', {
            duration: theme.transitions.duration.complex
          }),
          ...(collapseClick && {
            ml: '102px'
          })
        }}
      >
        {context.loading ? <Loading /> : children}
      </MainStyle>
    </RootStyle>
  );
}

const mapStateToProps = ({ auth, context }) => ({ auth, context });
const mapDispatchToProps = dispatch => bindActionCreators({ signOut }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Drawer);