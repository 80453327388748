import React, { useEffect } from "react";

import { Button } from '@mui/material';
import { useStateValue } from "./Context";

const SaveSignatureBase = ({ disable, onSaveClick }) => { 
  const [
    { template },
    //  dispatch
  ] = useStateValue();

  return (
    <Button
        size="large"
        variant="contained"
        color="primary"
        disabled={disable}
        onClick={() => {
            onSaveClick(template)
        }}
    >
        Save the Signature
    </Button>
  );
};

export default SaveSignatureBase;