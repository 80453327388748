import React, { useState } from 'react';
import { useStateValue } from '../../Context';
import * as reducer from '../../reducer';
import * as Gapi from '../../../../Helpers/Gapi';
import { splitId } from '../../helpers';
import Resizer from 'react-image-file-resizer';
import { Button, Box, Grid, Autocomplete, Stack, Avatar, Typography, TextField } from '@mui/material';

const BannerListContainer = ({ open, value, handleOpen, handleClose, handleChange }) => {
  const [{ banners }, dispatch] = useStateValue();

  const handleBannerApply = (index) => {
    reducer.updateBanner(dispatch)(banners[index], index);
    applyBanner(banners[index]);
  }

  const applyBanner = async (banner) => {
    let imageSize = await getImageSizeFromBase64(banner.imageUrl);
    handleChange(imageSize[0], banner);
    handleClose();
  }

  const getImageSizeFromBase64 = (data) => new Promise((resolve, reject) => {
    let image = new Image();
    image.onload = () => {
      resolve([image.width, image.height]);
    }
    image.onerror = error => reject(error);
    image.src = data;
  });

  const getSelectedValuePosition = () => {
    for (const key in banners) {
      if (banners[key].imageUrl === value.imageUrl && banners[key].title === value.title) return key;
    }
    return -1;
  }

  const handleSelection = (e, banner) => {
    if (banner == null) return;
    for (const key in banners) {
      if (banners[key].imageUrl === banner.imageUrl && banners[key].title === banner.title) { 
        handleBannerApply(key);
        return;
      }
    }
  }

  return (
    <Autocomplete
      size='small'
      value={banners[getSelectedValuePosition()]}
      getOptionLabel={(banner) => banner.title}
      id="select-banner"
      disableClearable
      options={banners.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)}
      sx={{ width: "100%"}}
      onChange={handleSelection}
      renderInput={(params) => <TextField {...params} placeholder="Select a Banner" />}
      renderOption={(props, recipient, { inputValue, selected }) => {
          const { title, imageUrl } = recipient;
          return (
            <>
              <Stack direction="row" alignItems="center" spacing={2} {...props} sx={{padding: "10px 20px"}}>
                <Box
                  sx={{
                    width: 48,
                    height: 48,
                    flexShrink: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Avatar alt="Avatar" src={imageUrl} width={48} height={48} sx={{ mx: 'auto' }} />
                </Box>

                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="subtitle2">{title}</Typography>
                </Box>
                
              </Stack>
            </>
          );
        }}
      />
  )
};

export default ({ label, value, onChange, isBanner }) => {
  const [open, setOpen] = React.useState(false);
  const [imageTypes, setImageTypes] = React.useState({})
  const [{ componentId, template }, dispatch] = useStateValue(); 
  const type = "image";
  const handleShowImages = () => {
    setOpen(true);
  };

  const handleCloseImages = () => {
    setOpen(false);
  };

  const handleChangeImage = (width, image) => {
    const [rowIdx, colIdx] = splitId(componentId);
    let row = template.rows[rowIdx];
    let column = row.columns[colIdx];

    onChange(width, image, column.width)

  }

  const getBase64 = (file) => new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
  });

  const getImageSizeFromBase64 = (data) => new Promise((resolve, reject) => {
      let image = new Image();
      image.onload = () => {
          resolve([image.width, image.height]);
      }
      image.onerror = error => reject(error);
      image.src = data;
  });

  const resizeFile = (file, width, height) => new Promise(resolve => {
      let imageRatio = height/width;
      Resizer.imageFileResizer(
          file,
          Math.min(800, width),
          Math.min(800, width) * imageRatio,
          'JPEG',
          60,
          0,
          uri => {
          resolve(uri);
          },
          'base64'
      );
  })

  const handleUploadClick = (type) => async (e) => {
      if (e.target.files[0]) {
          let imageFile = e.target.files[0];
          if (!["image/png", "image/jpeg", "image/jpg", "image/gif"].some(v => imageFile.type.includes(v))) {
            alert("Allowed *.jpeg, *.jpg, *.png, *.gif");
            setInputKey(inputKey+1)
            return;
          }
          let uploadImageData = await getBase64(imageFile);
          let [width, height] = await getImageSizeFromBase64(uploadImageData);
          let imageSize = imageFile.size;
          if (imageSize / 1024 > 3100) {
              if (window.confirm('Image is too big, max allowed file size is 3.1MB. Image will be compressed and saved. Do you want to continue?')) {
                  uploadImageData = await resizeFile(imageFile, width, height);
              } else { 
                  alert('Image is too heavy, allowed max file size is 3.1MB. Please upload new image.');
                  setInputKey(inputKey+1)
                  return
              }
          } 

          Gapi.saveImage(uploadImageData).then((image) => {
            reducer.addImage(dispatch)(image.url);
            handleChangeImage(width, image.url)
          }).catch((err) => console.error(err))
      }
  };

  const [inputKey, setInputKey] = useState(0);

  return (
    <Grid
      container
      direction="column"
      spacing={0}
    >
      {isBanner ?
        <BannerListContainer
          open={open}
          value={value}
          handleOpen={handleShowImages}
          handleClose={handleCloseImages}
          handleChange={handleChangeImage}
        />
      :
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Grid container direction="row" spacing={3}>
              <Grid item>
                <label htmlFor="contained-button-file">
                  <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="contained-button-file"
                  key={inputKey}
                  type="file"
                  onChange={handleUploadClick(type)}
                  />
                  <Button
                    variant='contained'
                    color="primary"
                    component="span"
                    sx={{minWidth: "189px"}}
                  >
                    Browse an Image
                  </Button>
                </label>
              </Grid>
              <Grid item>
                <Typography variant="caption" sx={{ color: 'text.secondary', display: "block" }}>Allowed *.jpeg, *.jpg, *.png, *.gif</Typography>
                <Typography variant="caption" sx={{ color: 'text.secondary' }}>Max size of 3.1 MB</Typography>
              </Grid>
            </Grid>
          </Grid>
          {value && 
            <Grid item>
              <Stack direction="row" alignItems="center" spacing={2} sx={{border: "1px solid rgba(145, 158, 171, 0.32)", borderRadius: "10px", paddingRight: "8px", width: "189px"}}>
                <img style={{height: "64px", width: "64px", objectFit: "cover", borderRadius: "10px"}} src={value} alt="SignPro Component Image" />
                <Stack>
                  <Typography variant="subtitle2" noWrap>.{value.split(".").pop()}</Typography>
                </Stack>
              </Stack>
            </Grid>
          }
        </Grid>
      }
    </Grid>
  );
};
