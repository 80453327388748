import { Container, Grid, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import Page from '../components/Page';
import { dismissTrialStart, getStripeManagementPortal } from '../Helpers/Gapi';
import useSettings from '../hooks/useSettings';
import Drawer from '../UI/Base/Drawer';
import ModalFirstTime from '../UI/components/subscription/ModalFirstTime';
import ModalTrialExpired from '../UI/components/subscription/ModalTrialExpired';
import ModalTrialExpiring from '../UI/components/subscription/ModalTrialExpiring';
import TransitionAlert from '../UI/components/TransitionAlert';
import CompanyGeneral from './CompanyGeneral';
import Loading from './Loading';

const Company = ({ context }) => {
  const { themeStretch } = useSettings();

  const {
    licensed,
    trial,
    trialEnd,
    pastDue,
    dismissedStartTrialModal,
    isStripeCustomer
  } = context.company ? context.company.subscription : {};

  useEffect(() => {
    if (pastDue) {
      getStripeManagementPortal(context.domain, {
        returnUrl: window.location.href
      }).then((res) => {
        window.location.href = res.manageUrl;
      });
    }
  }, [pastDue]);

  const showTrialStart = trial && !dismissedStartTrialModal;

  const showTrialExpiring =
    trial &&
    trialEnd &&
    !isStripeCustomer &&
    new Date(trialEnd).getTime() - new Date().getTime() <
      1000 * 60 * 60 * 24 * 4 &&
    !localStorage.getItem('trialExpiringDismissed');

  const showTrialExpired = !trial && !licensed;

  const showModal = (() => {
    // Show modal if the user has never been licensed, allowing the user to start a trial
    if (showTrialStart) return true;
    // Show modal if trial is expiring, allowing the user to dismiss it, or to subscribe
    if (showTrialExpiring) return true;
    // Show modal if trial is expired, forcing the user to subscribe to continue using the application
    if (showTrialExpired) return true;
    // Show modal if the subscription is past due, forcing the user to pay the latest invoice by either
    // going to the billing portal or the invoice page
    // NB: this is not currently used, as the billing portal is opened automatically
    if (pastDue) return true;

    return false;
  })();

  const [modalOpen, setModalOpen] = useState(showModal);

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleFirstTimeModalClose = () => {
    dismissTrialStart(context.domain).then(() => {
      setModalOpen(false);
    });
  };

  return (
    <Drawer>
      <Page title="SignPro | Company profile">
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
              <HeaderBreadcrumbs heading="Company profile" />
            </Grid>
            <Grid item xl={8} lg={8} md={8} sm={6} xs={12}>
              {!context.navigationAvailable && (
                <TransitionAlert
                  title="Fill the company profile for the new domain!"
                  message="Filling company profile information for the new domain is necessary for activating the menu."
                  severity="info"
                />
              )}
            </Grid>
          </Grid>
          <Stack spacing={5}>
            {context.loading ? <Loading /> : <CompanyGeneral />}
          </Stack>
        </Container>
        {showTrialStart ? (
          <ModalFirstTime
            open={modalOpen}
            handleClose={handleFirstTimeModalClose}
          />
        ) : showTrialExpiring ? (
          <ModalTrialExpiring
            open={modalOpen}
            handleClose={handleClose}
            domain={context.domain}
            trialEnd={trialEnd}
          />
        ) : // : pastDue
        // ? <ModalPastDue open={modalOpen} domain={context.domain} />
        showTrialExpired ? (
          <ModalTrialExpired open={modalOpen} domain={context.domain} />
        ) : null}
      </Page>
    </Drawer>
  );
};

const mapStateToProps = ({ context }) => ({ context });
export default connect(mapStateToProps)(Company);
