import React, { useReducer, useEffect } from 'react';
 
import * as SignatureReducer from '../Reducers/Signature';

import { Grid, Button, Card, Container } from '@mui/material';

import { SignaturesTreeView } from '../UI/TreeView';

import Drawer from '../UI/Base/Drawer';
import Loading from '../Screens/Loading';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import useSettings from '../hooks/useSettings';

import { Link, useNavigate } from "react-router-dom";
import { PATH_SIGNATURES_EDIT, PATH_SIGNATURES_NEW } from '../Navigation';

import * as Gapi from '../Helpers/Gapi';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import Page from '../components/Page';
import AlertDialog from '../components/AlertDialog';



const SignatureList = ({ context }) => {
    
    const { reducer, initialState } = SignatureReducer;

    const [open, setOpen] = React.useState(false);
    const [title, setTitle] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [onAgree, setOnAgree] = React.useState();
    const handleAgree = () => {
        onAgree();
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [loading, setLoading] = React.useState(true);
    const [defaultSignatureKey, setDefaultSignatureKey] = React.useState('');
    const [state, dispatch] = useReducer(reducer, initialState);
    const { themeStretch } = useSettings();

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const setDefaultSignature = (key, signature) =>
        Gapi.setDefaultSignaturePath(key, context.domain)
            .then(() => {
                loadData()
                enqueueSnackbar(`Signature "${signature.name}" was successfully set as the new Default Signature.`, { variant: 'success' });
            })
            .catch((error) => {
                enqueueSnackbar(`Setting the signature as the new Default Signature was unsuccessful. Please try again later!`, { variant: 'error' });
            });

    const deleteSignature = (path, signature) => {
        Gapi.deleteSignature(path.replace(/^\/|\/$/g, ''), context.domain).then((r) => {
            loadData();
            enqueueSnackbar(`Signature "${signature.name}" is deleted successfully.`, { variant: 'success' });
        }).catch((error) => {
            enqueueSnackbar(`Deletion of the signature "${signature.name}" was unsuccessful. Please try again later!`, { variant: 'error' });
        });
    };

    const loadData = async () => {
        setLoading(true);
        const [signatures, defaultSignaturePath] = await Promise.all([Gapi.getSignatures(context.domain),Gapi.getDefaultSignaturePath(context.domain)]);
        setDefaultSignatureKey(defaultSignaturePath);
        if (Object.keys(signatures).length > 0) SignatureReducer.addSignatures(dispatch)(signatures, defaultSignaturePath);
        else SignatureReducer.addSignatures(dispatch)({});
        setLoading(false);
    };

    const duplicateSignature = (path, signature) => {
        setLoading(true);
        const newName = `Copy of ${signature.name.replace(/^\//, "")}`;

        const newSignaturePayload = {
            schema: signature.schema,
            name: newName,
            html: signature.html,
            from: signature.from || null,
            to: signature.to || null
        }

        Gapi.createSignature(newSignaturePayload, context.domain)
            .then(async (res) => {
                setLoading(false);
                navigate(PATH_SIGNATURES_EDIT.replace(":signaturePath", res._id));
                enqueueSnackbar('Signature duplicated successfully.', { variant: 'success' });
            })
            .catch((e) => {
                enqueueSnackbar('Error duplicating signature.', { variant: 'error' });
                console.error(e.message)
            })
    };

    useEffect(() => {
        context.domain && loadData();
    }, [context.domain]);

    return (
        <Drawer>
            <Page title="SignPro | Signature List">
            {loading ? ( <Loading /> ) : (
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <AlertDialog open={open} handleClose={handleClose} title={title} message={message} handleAgree={handleAgree} />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <HeaderBreadcrumbs
                        heading="Signature List"
                        action={
                            <Button
                                variant="contained"
                                startIcon={<Icon icon={plusFill} />}
                                component={Link} 
                                to={PATH_SIGNATURES_NEW}
                                >
                                New Signature
                            </Button>
                        }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Card sx={{paddingTop: "8px"}}>
                            <SignaturesTreeView
                                signatures={state.signatures}
                                onDelete={(path, signature) => {
                                    setTitle(`Delete the signature?`);
                                    setMessage(`If this signature is currently applied to users, then after deleting the signature, a Default Signature will be applied to these users.`);
                                    setOnAgree(() => () => deleteSignature(path, signature));
                                    setOpen(true);
                                }}
                                onDuplicate={(path, signature) => {
                                    setTitle(`Duplicate the signature?`);
                                    setMessage(`Are you sure you want to duplicate ${signature.name} signature?`);
                                    setOnAgree(() => () => duplicateSignature(path, signature));
                                    setOpen(true);
                                }}
                                defaultSignatureKey={defaultSignatureKey}
                                onRadioSelect={(key, signature) => {
                                    setTitle(`Set "${signature.name}" as the new Default Signature?`);
                                    setMessage(null);
                                    setOnAgree(() => () => setDefaultSignature(key, signature));
                                    setOpen(true);
                                }}
                            />
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            )}
            </Page>
        </Drawer>
    );
};


const mapStateToProps = ({context}) => ({context});
export default connect(mapStateToProps)(SignatureList);