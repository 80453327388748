import { Grid, TextField, InputAdornment } from '@mui/material';
import React from 'react';
import EmojiPicker from '../../../../components/EmojiPicker';

export default ({ 
  value, 
  onChange 
}) => {
  return(
    <>
      <Grid
        container
        direction="column"
        spacing={0}
        style={{ paddingTop: 16, paddingBottom: 16 }}
      >
        <TextField 
          InputProps={{
            endAdornment: <InputAdornment position="start" sx={{whiteSpace: "normal"}}>
              <EmojiPicker value={value} alignRight={true} setValue={(e) => onChange(e)} />
            </InputAdornment>,
          }} 
          id="outlined-basic" 
          label="Free text" 
          InputLabelProps={{ shrink: true }} 
          placeholder='Write text here' 
          size='small' 
          variant="outlined" 
          value={value} 
          onChange={(e) => onChange(e.target.value)} />
      </Grid>
    </>
  );
};