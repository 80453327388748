import React from 'react';
import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import FilterListIcon from '@mui/icons-material/FilterList';


import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import debounce from 'lodash.debounce';
import { useTheme, styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';

import { Box, Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment } from '@mui/material';



const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    transition: theme.transitions.create(['box-shadow', 'width'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter
    }),
    '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
    '& fieldset': {
      borderWidth: `1px !important`,
      borderColor: `${theme.palette.grey[500_32]} !important`
    }
  }));

const UsersToolBar = ({action}) => {

    const changeHandler = event => {
        action('search', event.target.value);
    };
    const debouncedChangeHandler = React.useCallback(
        debounce(changeHandler, 300)
    , []);
    return (
        <Stack spacing={3} justifyContent="space-between" direction="row" sx={{height: "44px", mx: 1, my: 2}}>
            <SearchStyle
            onChange={debouncedChangeHandler}
            placeholder="Search user..."
            startAdornment={
                <InputAdornment position="start">
                <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                </InputAdornment>
            }
            />

            <Button variant="outlined" onClick={() => action('toogleFilters')} sx={{color: "#212B36", borderColor: "#dce0e4"}} endIcon={<FilterListIcon /> }>Filters</Button>
        </Stack>
    );
};

UsersToolBar.propTypes = {
    action: PropTypes.func
};

export default UsersToolBar;