import React from 'react';
import { Card, Box, Stack, Typography, Grid, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import Label from '../../components/Label';
import CreateIcon from '@mui/icons-material/Create';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';

const ProductImgStyle = styled('img')({
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute'
  });

export default function BannerItem({imageUrl, title, trackable, path}) {
    return (
        <Card>
            <Box sx={{ pt: '100%', position: 'relative' }}>
                <ProductImgStyle alt={title} src={imageUrl} />
            </Box>

            <Stack sx={{ p: 3 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="subtitle2" noWrap>
                        {title}
                    </Typography>
                    <Link
                        to={`/banners/${path}`}
                        >
                        <IconButton color="default" aria-label="edit signature" component="span">
                            <Icon icon={editFill} width={20} height={20} />
                        </IconButton>
                    </Link>
                </Stack>
                <Grid sx={{height: "24px"}}>
                    {trackable && <Label
                        color='success'
                    >
                        Trackable
                    </Label>}
                </Grid>
                
            </Stack>
        </Card>
    );
}
