import React, { useState } from 'react';

import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';
// material
import {
  Alert,
  AppBar,
  Box,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// components
import { LoadingButton } from '@mui/lab';
import { MHidden } from '../../components/@material-extend';
import DomainContainer from '../containers/DomainContainer';
import AccountPopover from './AccountPopover';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  },
  boxShadow: 'none'
}));

const ShadowHeader = styled('div')(({ theme }) => ({
  left: '0px',
  right: '0px',
  bottom: '0px',
  height: '24px',
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: 'calc(100% - 48px)',
  boxShadow: 'rgb(145 158 171 / 16%) 0px 8px 16px 0px'
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  },
  background: '#fff'
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({
  onOpenSidebar,
  user,
  signOut,
  openCheckout,
  openManageSubscription,
  subscription
}) {
  const { trial, trialInternal, cancellationDate, trialEnd, isStripeCustomer, licensed } = subscription;

  // TODO: handle when not shown
  // window.width > 1020 but not when 1200 < window.width < 1300
  const min1020 = useMediaQuery('(min-width:1020px)');
  const min1200 = useMediaQuery('(min-width:1200px)');
  const max1300 = useMediaQuery('(max-width:1300px)');

  const showAlert = min1020 && !(min1200 && max1300);
  const showCancel =
    showAlert && cancellationDate && Date.parse(cancellationDate) > new Date();

  const trialEnded = Date.parse(trialEnd) < new Date();
  const showTrial = showAlert && (trial || (trialEnded && !licensed)) && !showCancel;
  const showSubscriptionAction = showTrial && !isStripeCustomer && (trialEnded || trialInternal);

  const { isCollapse } = useCollapseDrawer();

  return (
    <RootStyle
      sx={{
        ...(isCollapse && {
          width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` }
        })
      }}
    >
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: 'text.primary' }}
          >
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>

        <Box sx={{ width: '256px' }}>
          <DomainContainer />
        </Box>
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          {showTrial && (
            <TrialAlert
              trialEnd={trialEnd}
              trialEnded={trialEnded}
              showSubscriptionAction={showSubscriptionAction}
              onActionClick={openCheckout}
            />
          )}
          {showCancel && <EndingAlert cancellationDate={cancellationDate} />}
          <AccountPopover
            user={user}
            signOut={signOut}
            subscription={subscription}
          />
        </Stack>
        <ShadowHeader />
      </ToolbarStyle>
    </RootStyle>
  );
}

const TrialAlert = ({ trialEnd, onActionClick, trialEnded, showSubscriptionAction }) => {
  const [loading, setLoading] = useState(false);

  const onActionClickWrapper = () => {
    setLoading(true);
    onActionClick();
  };

  const action =
    showSubscriptionAction ? (
      <LoadingButton
        variant="outlined"
        loading={loading}
        onClick={onActionClickWrapper}
        sx={{
          mt: -0.5,
          color: 'info.darker',
          borderColor: 'info.darker',
          textTransform: 'none',
          '&:hover': {
            color: 'info.main',
            bgcolor: 'info.lighter',
            borderColor: 'info.main'
          }
        }}
      >
        Set Up a Subscription
      </LoadingButton>
    ) : undefined;

  return (
    <Alert severity="info" action={action} variant="outlined">
      <Typography fontSize="14px">
        {trialEnded ? (
          'Your Free Trial has expired'
        ) : (
          <>
            You are on Free Trial period until{' '}
            {new Date(trialEnd).toLocaleDateString('en-GB', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            })}
          </>
        )}
      </Typography>
    </Alert>
  );
};

const EndingAlert = ({ cancellationDate }) => {
  return (
    <Alert severity="info" variant="outlined">
      <Typography fontSize="14px">
        Your Subscription will last until{' '}
        {new Date(cancellationDate).toLocaleDateString('en-GB', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        })}
      </Typography>
    </Alert>
  );
};
