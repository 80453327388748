import React from "react";
import PropTypes from "prop-types";

import { Tabs, Tab, Box, IconButton } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import GeneralConfig from "./GeneralConfig";
import StructureConfig from "./StructureConfig";
import ComponentConfig from "./ComponentConfig";

import { useStateValue } from "../Context";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const TabPanelGeneral = () => {
  const [{ template }, dispatch] = useStateValue();

  const saveTemplate = React.useCallback((values, userClick, styleClicked) => {
    dispatch({ type: "saveTemplateConfig", payload: values, userClick: userClick , styleClicked: styleClicked});
  }, [dispatch]);

  return (
    <GeneralConfig template={template} onChange={saveTemplate} />
  );
};

const TabPanelStructure = () => {
  const [{ structure }, dispatch] = useStateValue();

  const saveStructure = React.useCallback(
    ({ widths, colSpacing, bgColor, padding, borderWidth, borderColor, borderStyle }, userClick, styleClicked) => {
      dispatch({
        type: "saveStructureConfig",
        payload: { widths, colSpacing, bgColor, padding, borderWidth, borderColor, borderStyle },
        userClick: userClick,
        styleClicked: styleClicked
      });
    },
    [dispatch]
  );

  return (
    <>
      {structure && <StructureConfig structure={structure} onChange={saveStructure} />}
    </>
  );
};

const TabPanelComponent = () => {
  const [
    { component },
    dispatch,
  ] = useStateValue();


  const saveComponent = React.useCallback(
    (attributes) => {      
      dispatch({
        type: "saveComponentConfig",
        payload: attributes,
      });
    },
    [dispatch]
  );


  return (<ComponentConfig component={component} onChange={saveComponent} />);
};

export default ({ index, handleClose }) => {
  const [tabIndex, setTabIndex] = React.useState(index);
  const { innerWidth: width } = window;
  const [{ componentId, component }] = useStateValue();
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingLeft: "16px", paddingRight: "16px", display: "flex", justifyContent: "space-between", background: "#F4F6F8" }} >
        <Tabs
          value={tabIndex}
          onChange={(e, index) => {
            setTabIndex(index);
          }}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label={width > 768 ? "Layout" : "Layout"} sx={{minWidth: "0px !important"}}/>
          <Tab label={width > 768 ? "Columns" : "Columns"} sx={{minWidth: "0px !important"}}/>
          {componentId >= 1000 && 
          <Tab label={component.name} sx={{minWidth: "0px !important"}}/>
          }
          
        </Tabs>
        <IconButton sx={{marginRight: "-8px"}} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <TabPanel value={tabIndex} index={0}>
        <TabPanelGeneral />
      </TabPanel>

      <TabPanel value={tabIndex} index={1}>
        <TabPanelStructure />
      </TabPanel>

      {componentId >= 1000 && 
      <TabPanel value={tabIndex} index={2}>
        <TabPanelComponent />
      </TabPanel>
      }
      
    </>
  );
};
