import { Card, TableContainer, Table, Grid, TableHead, TableRow, TableCell, TableBody, IconButton } from '@mui/material';
import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { Link } from 'react-router-dom';
import EmptyContent from '../../components/EmptyContent';

export default function DisclaimerList({disclaimers}) {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Card sx={{pt: 1}}>
                    {disclaimers && Object.entries(disclaimers).length > 0 ? 
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow >
                                    <TableCell>
                                        Name
                                    </TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.entries(disclaimers).sort((a, b) => a[1].createdAt < b[1].createdAt ? 1 : -1).map(([disclaimerKey, disclaimerValue]) => (
                                    <DisclaimerItem key={disclaimerKey} path={disclaimerValue._id} disclaimer={disclaimerValue} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    :
                    <Grid item sx={{width: "100%", display: "flex", justifyContent: "center"}}><EmptyContent title="Empty content" /></Grid>
                    }
                </Card>
            </Grid>
        </Grid>
    );
}

const DisclaimerItem = ({path, disclaimer}) => {
    return (
        <TableRow component={Link} to={`/disclaimers/${path}`} key={path} sx={{textDecoration: "none"}}>
            <TableCell component="th" width="80%">{disclaimer.title}</TableCell>
            <TableCell component="th" width="20%" align='right'>
                <IconButton color="default" component="span">
                    <InfoIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    )
}
