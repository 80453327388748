const applyFilter = ({name, jobTitleList, departmentList, orgUnitList}, users) => {
    
    if (name !== '') {
        users = users.filter(user =>  
            user.name.fullName.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()
            .indexOf(name.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase()) > -1)
    }

    if (jobTitleList && jobTitleList.length) {
        users = users
        .filter(({organizations}) => organizations)
        .filter(({organizations: [{title}]}) => jobTitleList.indexOf(title) > -1)
    }
    
    if (departmentList && departmentList.length) {
        users = users
        .filter(({organizations}) => organizations)
        .filter(({organizations: [{department}]}) => departmentList.indexOf(department) > -1)
    }

    if (orgUnitList && orgUnitList.length) {
        users = users
        .filter(({orgUnit}) => orgUnitList.indexOf(orgUnit) > -1)
    }

    return users;
}

export {applyFilter};