import { Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import { QuillEditor } from '../../components/editor';
import Loading from '../../Screens/Loading';
import './DisclaimerDetails.css';

export default function DisclaimerDetails({disclaimer, loading}) {

    return (
        <Grid>
            {loading ? <Loading /> : 
                <>
                    <TextField
                        name="title"
                        contentEditable="false"
                        readOnly="true"
                        label="Disclaimer title"
                        fullWidth
                        value={disclaimer.title}
                        sx={{mb: 4}}
                    />
                    <Typography>Content</Typography>
                    <QuillEditor
                        id="product-description-without-toolbar"
                        value={disclaimer.text ? disclaimer.text : null}
                        readOnly="true"
                    />
                </>
            }
        </Grid>
    );
}
