import { renderToStaticMarkup } from "react-dom/server";
import React from "react";
import Sign from "./Preview/Sign";

//
// Split id to subcomponent ids.
//
// Example:
// 1 => Row 0 => [0]
// 2 => Row 1 => [1]
// 101 => Row 0, Column 0 => [0, 0]
// 102 => Row 0, Column 1 => [0, 1]
// 10101 => Row 0, Column 0, Item 0 => [0, 0, 0]
// 10102 => Row 0, Column 0, Item 1 => [0, 0, 1]
//
export const splitId = (id) => {
  let items = [];

  if (id >= 10000) {
    items.push(Math.trunc(id / 10000) - 1);
    id = id % 10000;
  }
  if (id >= 100) {
    items.push(Math.trunc(id / 100) - 1);
    id = id % 100;
  }
  items.push(id - 1);

  return items;
};

/**
 * Render Sign component to HTML String.
 *
 * @param {Object} template Template Schema.
 * @param {Object} conversions Conversions for replacing variables if needed.
 * @returns {String} Html String.
 */
export const renderSignHtml = (template, conversions) => {
  return renderToStaticMarkup(
    <Sign template={template} conversions={conversions} />
  );
};
