import React, { useReducer, useEffect } from 'react';
import {
  useNavigate,
  useParams
} from "react-router-dom";
 
import * as SignatureReducer from '../Reducers/Signature';

import { Grid, Box, Card, Container } from '@mui/material';

import Page from '../components/Page';
import Drawer from '../UI/Base/Drawer';
import Loading from '../Screens/Loading';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';

import SignatureEditor, {
  Editor,
  Preview,
  Configurator,
  SaveSignature,
  helpers
} from '../UI/SignatureEditor';

import SignatureTabs from '../UI/SignatureTabs';
import { PATH_SIGNATURES } from '../Navigation';

import * as Gapi from '../Helpers/Gapi';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { warnUserChanges, userConfirmNavigation } from "../Actions/Navigation";
import useSettings from '../hooks/useSettings';
import { useSnackbar } from 'notistack';
import { NOT_PROVIDED, NOT_PROVIDED_COMPANY } from '../config/globals';

var selectedBanners = [];
const getBannersFromSchema = (schema, banners) => {
  selectedBanners = [];
  _findBanners(schema);
  return selectedBanners;
}

const _findBanners = (theObject) => {
  if (theObject instanceof Array) {
     for (var i = 0; i < theObject.length; i++) {
        _findBanners(theObject[i]);
     }
  } else {
     for (var prop in theObject) {
        if (prop === 'name') {
           if (theObject[prop] === "Banner") {
            selectedBanners.push(theObject.value);
           }
        }
        if (theObject[prop] instanceof Object || theObject[prop] instanceof Array) {
          _findBanners(theObject[prop]);
        }
     }
  }
  return;
}

var selectedDisclaimers = [];
const _findDisclaimers = (theObject) => {
  if (theObject instanceof Array) {
    for (var i = 0; i < theObject.length; i++) {
      _findDisclaimers(theObject[i]);
    }
 } else {
    for (var prop in theObject) {
       if (prop === 'name') {
          if (theObject[prop] === "Disclaimer") {
            selectedDisclaimers.push({title: theObject.title, value: theObject.value});
          }
       }
       if (theObject[prop] instanceof Object || theObject[prop] instanceof Array) {
        _findDisclaimers(theObject[prop]);
       }
    }
 }
 return;
}

const getDisclaimersFromSchema = (schema, disclaimers) => {
  selectedDisclaimers = [];
  _findDisclaimers(schema);
  var getDisclaimers = disclaimers.filter(disclaimer => isSelectedDisclaimer(disclaimer, selectedDisclaimers))
  return getDisclaimers;
}

const isSelectedDisclaimer = (disclaimer, selectedDisclaimers) => {
  for (var i = 0; i < selectedDisclaimers.length; i++) {
    if (selectedDisclaimers[i].title === disclaimer.title && selectedDisclaimers[i].value === disclaimer.text) return true;
  }
  return false;
}

const updateSignature = (
  key,
  signatureName,
  selectedDates,
  enqueueSnackbar,
  Gapi,
  context,
  state
) => (schema, setLoadingFalse) => {
  const path = key ? key.replace(/^\//, "") : null;
  const html = helpers.renderSignHtml(schema);

  const from = selectedDates[0] ? new Date(selectedDates[0].setHours(0,0,0,0)).getTime() : undefined;
  const to = selectedDates[1] ? new Date(selectedDates[1].setHours(23,59,59,999)).getTime() : undefined;

  if (path) {
    const signatureData = {
      [path]: {
        schema: JSON.stringify(schema),
        name: signatureName,
        html,
        from,
        to,
        banners: getBannersFromSchema(schema, state.banners),
        disclaimers: getDisclaimersFromSchema(schema, state.disclaimers)
      }
    };
  
    return (
      Gapi.saveSignature(signatureData, context.domain)
        .then(async (res) => {
          console.log("RES:", res);
          setLoadingFalse();
          
          enqueueSnackbar('The signature is saved successfully. Please apply the signature to users in the Users menu section!', { variant: 'success' });
        })
        .catch((e) => {
          enqueueSnackbar('Saving the signature was unsuccessful. Please try again later.', { variant: 'error' });
          console.error(e.message)
        })
    );
  } else {
    return (Gapi.createSignature({
      schema: JSON.stringify(schema),
      name: signatureName,
      html,
      from,
      to,
      banners: getBannersFromSchema(schema, state.banners),
      disclaimers: getDisclaimersFromSchema(schema, state.disclaimers)
    }, context.domain)
      .then(async (res) => {
        console.log("RES:", res);
        setLoadingFalse();
        
        enqueueSnackbar('The signature was created successfully. Please apply the signature to users in the Users menu section!', { variant: 'success' });
      })
      .catch((e) => {
        enqueueSnackbar('Creating the signature was unsuccessful. Please try again later.', { variant: 'error' });
        console.error(e.message)
      }));
  }
  
};

const DEFAULT_SCHEMA = {
  rows: [
    {
      width: 568,
      columns: [{ width: 568, contents: [], colSpacing: 0 }]
    }
  ]
}

const Signature = ({ context, warnUserChanges, userConfirmNavigation }) => {

  let { signaturePath } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate()

  const [loading, setLoading] = React.useState(false);
  const [currentSignatureSchema, setCurrentSignatureSchema] = React.useState();
  const { reducer, initialState } = SignatureReducer;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [selectedDates, setSelectedDates] = React.useState([null, null]);
  const currentSignatureKey = signaturePath ? `/${signaturePath}` : null;
  const [signatureName, setSignatureName] = React.useState('');
  const [newNameError, setNewNameError] = React.useState('');
  const [tabPosition, setTabPosition] = React.useState(0);
  const [showSelectedDates, setShowSelectedDates] = React.useState(false);
  const [conversions, setConversions] = React.useState({});
  const [usersTotal, setUsersTotal] = React.useState([]);
  const [isDefault, setIsDefault] = React.useState(false);
  const { themeStretch } = useSettings(); 

  const today = new Date(new Date().setHours(0,0,0,0));
  const tomorrow = today;
  tomorrow.setDate(today.getDate() + 1);

  const setSignatureNameAndCheckIfIsValid = (signatureName) => {
    setSignatureName(signatureName);
    signatureName ? setNewNameError("") : setNewNameError("can't be blank");
  }

  const getHtmlByPhoneType = (phones, phoneType) => {
    console.log("getHtmlByPhoneType", {phones, phoneType})
    const selectedPhones = phones.filter(phone => phone.type === phoneType).map(phone => phone.value)
    
    let html = ''
    selectedPhones.forEach(phone => html += phone+', ')
    return html.slice(0, -2)
  }
  
  const saveSignature = updateSignature(
    currentSignatureKey,
    signatureName,
    selectedDates,
    enqueueSnackbar,
    Gapi,
    context,
    state
  );

  const getAlias = (email) => {
    const alias = email.split("@")[0];
    return `${alias}@${context.domain}`;
  }

  useEffect(() => {
    if (state && state.company && state.user) {
      setConversions({
        companyName: state.company.companyName ? state.company.companyName : NOT_PROVIDED_COMPANY,
        companyLogo: state.company.imageUrl ? state.company.imageUrl : 'https://avatars.dicebear.com/api/identicon/acme.svg',
        webpage: state.company.webpage ? state.company.webpage : NOT_PROVIDED_COMPANY,
        instagram: state.company.instagram ? `<a href=${state.company.instagram}><img src="/static/icons/socials/ic_instagram.png" width="24" height="24" /></a>` : NOT_PROVIDED_COMPANY,
        linkedin: state.company.linkedin ? `<a href=${state.company.linkedin}><img src="/static/icons/socials/ic_linkedin.png" width="24" height="24" /></a>` : NOT_PROVIDED_COMPANY,
        twitter: state.company.twitter ? `<a href=${state.company.twitter}><img src="/static/icons/socials/ic_twitter.png" width="24" height="24" /></a>` : NOT_PROVIDED_COMPANY,
        facebook: state.company.facebook ? `<a href=${state.company.facebook}><img src="/static/icons/socials/ic_facebook.png" width="24" height="24" /></a>` : NOT_PROVIDED_COMPANY,
        youtube: state.company.youtube ? `<a href=${state.company.youtube}><img src="/static/icons/socials/ic_youtube.png" width="24" height="24" /></a>` : NOT_PROVIDED_COMPANY,
        email: state.user.primaryEmail ? state.user.primaryEmail : NOT_PROVIDED,
        firstName: state.user.name ? state.user.name.givenName ? state.user.name.givenName : NOT_PROVIDED : NOT_PROVIDED,
        lastName: state.user.name ? state.user.name.familyName ? state.user.name.familyName : NOT_PROVIDED : NOT_PROVIDED,
        personal_phones: state.user.phones ? getHtmlByPhoneType(state.user.phones, 'home') ? getHtmlByPhoneType(state.user.phones, 'home') : NOT_PROVIDED : NOT_PROVIDED,
        work_phones: state.user.phones ? getHtmlByPhoneType(state.user.phones, 'work') ? getHtmlByPhoneType(state.user.phones, 'work') : NOT_PROVIDED : NOT_PROVIDED,
        mobile_phones: state.user.phones ? getHtmlByPhoneType(state.user.phones, 'mobile') ? getHtmlByPhoneType(state.user.phones, 'mobile') : NOT_PROVIDED : NOT_PROVIDED,
        department:state.user.organizations ? state.user.organizations[0].department ? state.user.organizations[0].department : NOT_PROVIDED : NOT_PROVIDED,
        jobTitle: state.user.organizations ? state.user.organizations[0].title ? state.user.organizations[0].title : NOT_PROVIDED : NOT_PROVIDED,
        orgunit: state.user.orgUnitPath ? state.user.orgUnitPath : NOT_PROVIDED,
        disclaimer: NOT_PROVIDED,
        spacer: '[SPACER]',
        userPhoto: state.user.thumbnailPhotoUrl ? state.user.thumbnailPhotoUrl : 'https://avatars.dicebear.com/api/male/john.svg?mood[]=happy',
        userSocialMedia: NOT_PROVIDED,
        userFullName: state.user.name ? state.user.name.fullName ? state.user.name.fullName : NOT_PROVIDED : NOT_PROVIDED,
      })
    }
  }, [state]);

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
  
      if (currentSignatureKey) {
        const signature = await Gapi.getSignatureByPath(currentSignatureKey, context.domain);
        setIsDefault(signature.isDefault);
        setCurrentSignatureSchema(
          signature.schema ? JSON.parse(signature.schema) : DEFAULT_SCHEMA
        );
        setSignatureName(signature.name ? signature.name : currentSignatureKey);
        if(signature.from) {
          let from = new Date(parseInt(signature.from));
          let to = new Date(parseInt(signature.to));
          setSelectedDates([from, to]);
          setShowSelectedDates(true);
        } else {
          setSelectedDates([null, null]);
          setShowSelectedDates(false);  
        } 
      } else {
        setCurrentSignatureSchema(
          DEFAULT_SCHEMA
        );
        setNewNameError("can't be blank")
      }
      
      
  
      /*const images = await Gapi.getImages(context.domain);
      SignatureReducer.addImages(dispatch)(images);*/
  
      const banners = await Gapi.getBanners(context.domain);
      SignatureReducer.addBanners(dispatch)(banners);
  
      const disclaimers = await Gapi.getDisclaimers(context.domain);
      SignatureReducer.addDisclaimers(dispatch)(disclaimers);
  
      // In edit template
      const user = await Gapi.getGsuiteCurrentProfile();
      SignatureReducer.setUserForPreview(dispatch)({...user, primaryEmail: getAlias(user.primaryEmail)});
  
      const allUsers = await Gapi.getGsuiteUsers();
      setUsersTotal(allUsers);
  
      // In edit template
      SignatureReducer.setCompanyForPreview(dispatch)(context.company);
  
      const companyColors = await Gapi.getCompanyColors(context.domain);
      SignatureReducer.addCompanyColors(dispatch)(companyColors);
  
      setLoading(false);
    }
    context.domain && loadData();
  }, [context.domain]);

  const [isAutomaticUpdate, setIsAutomaticUpdate] = React.useState(true);

  useEffect(() => {
    if(!loading) {
      if (isAutomaticUpdate) {
        setIsAutomaticUpdate(false);
        return;
      }
      if (context && !context.warnUserChanges) warnUserChanges(); 
    }
  }, [signatureName, selectedDates, showSelectedDates]);

  

  return (
    <Drawer>
      <Page title="SignPro | Signature builder">
        <Container maxWidth={false}>
          <>
            {loading ? (
                <Loading />
              ) : (
              <>
                <HeaderBreadcrumbs
                    heading="Signature builder"
                    links={[{ name: 'Signatures', href: "/signatures" }, { name: 'Signature builder' }]}
                  />
                  {currentSignatureSchema && (
                    <SignatureEditor
                      template={currentSignatureSchema}
                      //images={Object.values(state.images)}
                      banners={Object.values(state.banners)}
                      bannerKeys={Object.keys(state.banners)}
                      disclaimers={Object.values(state.disclaimers)}
                      companyColors={state.companyColors}
                      disclaimerKeys={Object.keys(state.disclaimers)}
                      warnUserChanges={warnUserChanges}
                    >
                      <Configurator />
                      <Grid container spacing={3}>
                        <Grid item xs={12} mb={4}>
                          <Card>
                            <SignatureTabs
                              onUserSelected={user => { 
                                const _user = {...user, primaryEmail: getAlias(user.primaryEmail)}
                                SignatureReducer.setUserForPreview(dispatch)(_user)
                              }}
                              isDefault={isDefault}
                              conversions={conversions}
                              tabPosition={tabPosition}
                              setTabPosition={setTabPosition}
                              signatureName={signatureName}
                              setSelectedDates={setSelectedDates}
                              selectedDates={selectedDates}
                              setSignatureName={setSignatureNameAndCheckIfIsValid}
                              currentNameError={newNameError}
                              setShowSelectedDates={(_showSelectedDates) => {
                                if(!_showSelectedDates) setSelectedDates([null, null]);
                                else setSelectedDates([today, tomorrow]); 
                                setShowSelectedDates(_showSelectedDates);
                              }}
                              showSelectedDates={showSelectedDates}
                              usersTotal={usersTotal}
                              />
                          </Card>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} >
                          <Card>
                            <Editor />
                          </Card>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <Card sx={{minHeight: "100%"}}>
                            <Preview conversions={conversions}/>
                          </Card>
                        </Grid>
                        <Grid item xs={12} justify='center' alignItems='center'>
                          <Box style={{textAlign: "center", marginTop: "20px"}}>
                            <SaveSignature
                              disable={!currentSignatureSchema || newNameError !== ""}
                              onSaveClick={(template) => {
                                saveSignature(template, () => setLoading(false)).then(() => {
                                  userConfirmNavigation();
                                  navigate(PATH_SIGNATURES)
                                })
                              }}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </SignatureEditor>
                  )}
              </>
            )}
          </>
        </Container>
      </Page>
    </Drawer>
  );
};

const mapStateToProps = ({context}) => ({context});
const mapDispatchToProps = dispatch => bindActionCreators({ warnUserChanges, userConfirmNavigation }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Signature);