import React, { useEffect, useReducer, useState } from 'react';
import { Box, Grid, BottomNavigationAction, BottomNavigation } from '@mui/material';
import * as SettingsReducer from '../Reducers/Settings';
import Drawer from '../UI/Base/Drawer';
import Loading from './Loading';
import DataTable from '../UI/settings/DataTable';

const Images = ({ Gapi, context }) => {
  const { reducer, initialState } = SettingsReducer;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);

  useEffect(() => {  
    const loadData = async () => {
      setLoading(true);


      const images = await Gapi.getImages();
      SettingsReducer.addImages(dispatch)(images);

      setLoading(false);
    }
    loadData();
  }, [Gapi]);



  return (
    <Drawer Gapi={Gapi} signOut={Gapi.signOut} title='Settings'>
      {loading ? (
        <Loading />
      ) : (
        <Box>
          <Grid
            container
            justify='center'
            alignItems='center'
          >
            <BottomNavigation
              value={value}
              onChange={(e, newValue) => {
                setValue(newValue);
              }}
              showLabels
            >
              <BottomNavigationAction label="Images" />
            </BottomNavigation>
          </Grid>
          <Box marginTop={5}>
            <DataTable
              type={selectType(value)}
              banners={state.banners}
              disclaimers={state.disclaimers}
              images={state.images}
              dispatch={dispatch}
            />
          </Box>
        </Box>
      )}
    </Drawer>
  );
}

export default Images;

const selectType = (index) => {
  switch (index) {
    case 0:
      return 'image';
    default:
      return null;
  }
}
