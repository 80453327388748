const initialState = {
  companyName: '',
  webpage: '',
  imageUrl: '',
  linkedin: '',
  instagram: '',
  twitter: '',
  facebook: '',
  youtube: '',
  submitting: false,
};

const companyForm = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_COMPANY_FORM':
      return !action.companyForm ? 
        { ...initialState } : 
        {
          ...state,
          companyName: action.companyForm.companyName ? action.companyForm.companyName : '',
          webpage: action.companyForm.webpage ? action.companyForm.webpage : '',
          imageUrl: action.companyForm.imageUrl ? action.companyForm.imageUrl : '',
          linkedin: action.companyForm.linkedin ? action.companyForm.linkedin : '',
          instagram: action.companyForm.instagram ? action.companyForm.instagram : '',
          twitter: action.companyForm.twitter ? action.companyForm.twitter : '',
          facebook: action.companyForm.facebook ? action.companyForm.facebook : '',
          youtube: action.companyForm.youtube ? action.companyForm.youtube : ''
        };
    case 'SUBMITTING_FORM_START':
      return {...state, submitting: true};
    case 'SUBMITTING_FORM_END':
      return {...state, submitting: false}; 
    case 'SignOut':
      return {...initialState};  
    default:
      return state;
  }
}

export default companyForm;