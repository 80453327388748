import { FormHelperText, Grid, TextField, Typography } from '@mui/material';
import React, {useState} from 'react';
import { LoadingButton } from '@mui/lab';
import { QuillEditor } from '../../components/editor';

export default function DisclaimerForm({disclaimer, setDisclaimer, submit, submitting}) {

    console.log("DisclaimerForm", disclaimer)
    
    const [errors, setErrors] = useState({
        title: null,
        text: null
    });

    const removeEmptyTags = (content) => {
        return content.replace(/(((<\w+>)+[ \n(<br>)]*(<\/\w+>)+)+)|<br>/, "");
    }

    const validateAndSubmit = () => {
        disclaimer.text = removeEmptyTags(disclaimer.text);
        console.log("validateAndSubmit", disclaimer);
        var submit = true;
        var _errors = {
            title: null,
            text: null
        };

        if (disclaimer.title === "") {
            _errors.title = "This field must be filled.";
            submit = false;
        } 

        if (disclaimer.text === "") {
            _errors.text = "This field must be filled.";
            submit = false;
        } 

        if (!submit) {
            setErrors({..._errors});
        }

        return submit
    }

    return (
        <Grid>
            <TextField
                name="title"
                label="Disclaimer title"
                error={errors.title !== null}
                helperText={errors.title}
                fullWidth
                value={disclaimer.title}
                required={true}
                onChange={(evt) => setDisclaimer({...disclaimer, title: evt.target.value})}
                sx={{mb: 4}}
            />

            <Typography>Content *</Typography>
            <QuillEditor
                id="product-description"
                value={disclaimer.text ? disclaimer.text : null}
                onChange={(val) => {
                    console.log("QUILL CHANGE: ", disclaimer);
                    setDisclaimer({...disclaimer, text: val})
                }}
                error={Boolean(errors.text)}
                />
                {errors.text && (
                <FormHelperText error sx={{ px: 2 }}>
                    {errors.text}
                </FormHelperText>
                )}

            <Grid sx={{mt: 4, textAlign: "right"}}>
                <LoadingButton sx={{px: 5}} onClick={() => validateAndSubmit() && submit()} variant="contained" loading={submitting}>
                    Save
                </LoadingButton>
            </Grid>
        </Grid>
    );
}
