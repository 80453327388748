import { Box, MenuItem, Select } from "@mui/material";
import React from "react";

const ConfiguratorTextSizeWidget = ({ label, value, onChange }) => {
  const handleChange = (evt) => onChange(evt.target.value);

  const text_sizes = [
    {value: 'inherit', label: 'Inherit'},
    {value: 'x-small', label: 'Small'},
    {value: 'medium', label: 'Normal'},
    {value: 'large', label: 'Large'},
    {value: 'xx-large', label: 'Huge'}
  ];

  return (
    <>
      <Box flexGrow={1}>
        <h5>{label}</h5>
      </Box>
      <Box>
        <Select
          labelId="text-size-widget-label"
          id="text-size-widget-select"
          value={value || "inherit"}
          sx={{width: "194px", fontSize: "14px"}}
          size="small"
          onChange={handleChange}
        >
          {text_sizes.map((size, index) => (
            <MenuItem key={index} value={size.value} style={{fontSize:size.value}}>{size.label}</MenuItem>
          ))}
        </Select>
      </Box>
    </>
  )
}

export default ConfiguratorTextSizeWidget;