import React from 'react'
import {Tab, Box} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import UsersSignatureList from './UsersSignatureList';
import UsersDefaultSignature from './UsersDefaultSignature';

const UsersOperations = ({selectedUsers, tabValueSelected, signaturesInUse, setTabValueSelected, signatures, defaultSignature, domain, action, onDisableDefaultSignature, onEnableDefaultSignature, defaultSignatureLoading}, ref) => {
  const [loading, setLoading] = React.useState(false);

  React.useImperativeHandle(
    ref,
    () => ({
        setCurrent: () => setTabValueSelected('default'),
    }),
  )

  const selectedUsersAliasList = selectedUsers
    .reduce((list, user) => list.concat(user.emails.map(({address}) => address)), [])
    .filter(address => address.indexOf(domain) >= 0);

  

  const getSelectedUserSignatures = () => {
    let selectedUsersSignatures = Object.values(signaturesInUse).map(({users, path}) => users.find((user) => selectedUsersAliasList.includes(user)) && path)
    selectedUsersSignatures = [...new Set(selectedUsersSignatures)];

    console.log("selectedUsersSignatures A", selectedUsersSignatures);
    console.log("selectedUsersSignatures B", selectedUsersAliasList);
    console.log("selectedUsersSignatures C", Object.values(signaturesInUse));

    return signatures.filter((signature) => selectedUsersSignatures.includes(signature._id));
  };

  const selectedUsersCurrentSignaturesFilteredByDomain = getSelectedUserSignatures();
  
  const handleChange = (event, newValue) => {
    setTabValueSelected(newValue);
  };

  const handleAction = (button, signature) => {
      (button !== 'removeAll') || setLoading(true);
      action(button, {
        selectedUsers, 
        signature, 
        selectedUsersAliasList
      })
  } 

  const isExpiredSignature = (signature) => {
    if (signature.to) {
      let currentDate = new Date().setHours(0,0,0,0);
      let toDate = new Date(signature.to).setHours(0,0,0,0);
      return toDate < currentDate;
    }
    return false;
  }

  const getDefaultSignatureItem = (signatures, _defaultSignature) => {
    if (!signatures) return {}
    return signatures.filter(signature => signature._id === defaultSignature)[0];
  }

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={tabValueSelected}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingLeft: "35px", paddingRight: "35px", display: "flex", justifyContent: "space-between", background: "#F4F6F8" }} >
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab sx={{textTransform: "none"}} label="Default signature" value="default" />
            <Tab sx={{textTransform: "none"}} label="Currently applied signatures" value="current" />
            <Tab sx={{textTransform: "none"}} label="Choose and apply the signature" value="choose" />
          </TabList>
        </Box>
          <TabPanel value="default">
            <UsersDefaultSignature 
              defaultSignature={getDefaultSignatureItem(signatures, defaultSignature)}
              loading={defaultSignatureLoading}
              onDisable={onDisableDefaultSignature}
              onEnable={onEnableDefaultSignature}
              />
          </TabPanel>
          <TabPanel value="current">
            <UsersSignatureList 
              domain={domain}
              defaultSignature={defaultSignature}
              buttons={['unapply']} 
              action={handleAction}
              signatures={selectedUsersCurrentSignaturesFilteredByDomain.filter(signature => !isExpiredSignature(signature))}>
            </UsersSignatureList>
          </TabPanel>
          <TabPanel value="choose">
            <UsersSignatureList 
              domain={domain}
              defaultSignature={defaultSignature}
              signatures={signatures.filter(signature => signature._id !== defaultSignature && !isExpiredSignature(signature))} 
              buttons={['apply']} 
              action={handleAction}>
            </UsersSignatureList>
          </TabPanel>
      </TabContext>
    </Box>
  )
}


export default React.forwardRef(UsersOperations);

