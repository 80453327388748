// import React from 'react';
// ----------------------------------------------------------------------

export default function Container() {
  return {
    MuiContainer: {
      styleOverrides: {
        root: {}
      }
    }
  };
}
