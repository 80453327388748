import React, { useState, useEffect } from "react";
import { List, ListItem } from '@mui/material';


import ConfiguratorColorWidget from "./Components/ConfiguratorColorWidget";
import ConfiguratorFontWidget from "./Components/ConfiguratorFontWidget";
import ConfiguratorLineHeightWidget from "./Components/ConfiguratorLineHeightWidget";
import ConfiguratorNumberWidget from "./Components/ConfiguratorNumberWidget";
import ConfiguratorTextSizeWidget from "./Components/ConfiguratorTextSizeWidget";
import ConfiguratorBorderWidget from "./Components/ConfiguratorBorderWidget";
import ConfiguratorBorderStyleWidget from "./Components/ConfiguratorBorderStyleWidget";

export default ({ template, onChange }) => {
  const [width, setWidth] = useState(template.width || 568);
  const [bgColor, setBgColor] = useState(template.bgColor || "inherit");
  const [textColor, setTextColor] = useState(template.textColor || "inherit");
  const [lineHeight, setLineHeight] = useState(
    template.lineHeight || "inherit"
  );
  const [fontFamily, setFontFamily] = useState(
    template.fontFamily || "inherit"
  );
  const [textSize, setTextSize] = useState(template.textSize || "inherit");
  const [borderWidth, setBorderWidth] = useState(template.borderWidth || "0");
  const [borderColor, setBorderColor] = useState(template.borderColor || "#000");
  const [borderStyle, setBorderStyle] = useState(template.borderStyle || "solid");
  const [userClick, setUserClick] = useState(false);
  const [styleClicked, setStyleClicked] = useState(null);

  useEffect(() => {
    onChange({
      width,
      bgColor,
      textColor,
      lineHeight,
      fontFamily,
      textSize,
      borderWidth,
      borderColor,
      borderStyle
    }, userClick, styleClicked);
    setUserClick(false);
    setStyleClicked(null);
  }, [onChange, width, bgColor, textColor, lineHeight, fontFamily, textSize, borderColor, borderStyle, borderWidth]);

  return (
    <List style={{ width: "100%" }} onClick={() => setUserClick(true)}>
      <ListItem sx={{paddingY: "4px"}}>
        <ConfiguratorNumberWidget
          label={"Width"}
          value={width}
          onChange={setWidth}
          min={0}
          max={568}
        />
      </ListItem>
      <ListItem sx={{paddingY: "12px"}} onClick={() => setStyleClicked("bgColor")}>
        <ConfiguratorColorWidget
          label="Background colour"
          value={bgColor}
          onChange={setBgColor}
        />
      </ListItem>
      <ListItem sx={{paddingY: "12px"}} onClick={() => setStyleClicked("textColor")}>
        <ConfiguratorColorWidget
          label="Text colour"
          value={textColor}
          onChange={setTextColor}
        />
      </ListItem>
      <ListItem sx={{paddingY: "4px"}} onClick={() => setStyleClicked("fontFamily")}>
        <ConfiguratorFontWidget
          label={"Font"}
          value={fontFamily}
          onChange={setFontFamily}
        />
      </ListItem>
      <ListItem sx={{paddingY: "4px"}} onClick={() => setStyleClicked("textSize")}>
        <ConfiguratorTextSizeWidget
          label={"Text size"}
          value={textSize}
          onChange={setTextSize}
        />
      </ListItem>
      <ListItem sx={{paddingY: "4px"}} onClick={() => setStyleClicked("lineHeight")}>
        <ConfiguratorLineHeightWidget
          label={"Line height"}
          value={lineHeight}
          onChange={setLineHeight}
          customiseSwitch={false}
        />
      </ListItem>
      <ListItem sx={{paddingY: "4px"}} onClick={() => setStyleClicked("borderWidth")}>
        <ConfiguratorBorderWidget
          label="Border"
          value={borderWidth}
          onChange={setBorderWidth}
        />
      </ListItem>
      <ListItem sx={{paddingY: "4px"}} onClick={() => setStyleClicked("borderColor")}>
        <ConfiguratorColorWidget
          label="Border colour"
          value={borderColor}
          onChange={setBorderColor}
        />
      </ListItem>
      <ListItem sx={{paddingY: "4px"}} onClick={() => setStyleClicked("borderStyle")}>
        <ConfiguratorBorderStyleWidget
          label="Border style"
          value={borderStyle}
          onChange={setBorderStyle}
        />
      </ListItem>
    </List>
  );
};
