import React from 'react';

import { Box, Button, Container, styled, Typography, Link } from '@mui/material';
import { signIn } from '../Helpers/Gapi';
import GoogleSignIn from '../UI/GoogleSignIn';

const ContentStyle = styled('div')(() => ({
  maxWidth: 800,
  margin: 'auto',
  display: 'flex',
  textAlign: 'center',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: 0
}));

const NotInstalled = ({ redirectTo }) => {
  const signInWrapper = async () => {
    await signIn();
    redirectTo('/company');
  };

  return (
    <Container>
      <ContentStyle>
        <Box
          sx={{
            mb: 3,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: 2
          }}
        >
          <Typography variant="h3">
            Dear guest, <br />
            unfortunately log in has failed :(
          </Typography>
          <Typography variant="body1" sx={{ color: 'text.secondary' }}>
            To log in to app.signpro.app, You need to install the application from Google Workspace Marketplace 💚
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2, mb: 8 }}
            component={Link}
            href="https://workspace.google.com/marketplace/app/signpro/976918976743"
            target="_blank"
          >
            Install SignPro app
          </Button>
          <GoogleSignIn sx={{ width: '500px' }} onClick={signInWrapper} >
            Use another account
          </GoogleSignIn>
        </Box>
      </ContentStyle>
    </Container>
  );
};

export default NotInstalled;
