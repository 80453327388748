import React, { useEffect, useRef, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Navigate,
  useLocation,
  Routes,
  useNavigate,
} from "react-router-dom";
import { bindActionCreators } from 'redux'


import Dashboard       from '../Screens/Dashboard';
import Company         from '../Screens/Company';
import Signature       from '../Screens/Signature';
import SignatureList   from '../Screens/SignatureList';
import Users           from '../Screens/Users/Users';
import SingleUser      from '../Screens/SingleUser/SingleUser';
import Settings        from '../Screens/Settings';
import Banners         from "../Screens/Banners";
import Images from "../Screens/Images";
import NewBanner from "../Screens/NewBanner";
import BannerDetails from "../Screens/BannerDetails";
import * as PATH from "./index";
import { connect } from "react-redux";
import DisclaimersList from "../Screens/Disclaimers/DisclaimersList";
import DisclaimersNew from "../Screens/Disclaimers/DisclaimersNew";
import DisclaimersEdit from "../Screens/Disclaimers/DisclaimersEdit";
import NotFound from "../Screens/NotFound";
import ServerError from "../Screens/ServerError";
import DisclaimersShow from "../Screens/Disclaimers/DisclaimersShow";
import { redirectSuccess, userConfirmNavigation } from "../Actions/Navigation";
import { useCallbackPrompt } from "../hooks/useCallbackPrompt";
import DialogUserWarn from "../components/DialogUserWarn";


function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  },[value]);
  return ref.current;
}
const mapDispatchToProps = dispatch => bindActionCreators({ redirectSuccess, userConfirmNavigation }, dispatch);
const mapStateToProps = ({context, navigation}) => ({context, navigation});
const Guard = connect(mapStateToProps, mapDispatchToProps)(({children, context, isCompany, navigation, redirectSuccess, userConfirmNavigation, child}) => {
  const navigate = useNavigate();
  const prevDomain = usePrevious(context.domain);
  const [wait, setWait] = useState(true);  
  useEffect(() => {
    navigation.path && (() => {
      redirectSuccess();
      navigate(navigation.path);
    })();
  }, [navigation.path])

  useEffect(() => {
    if(prevDomain && prevDomain != context.domain) {
      switch(child) {
        case 'nb':  navigate(PATH.PATH_BANNERS);     break;
        case 'bd':  navigate(PATH.PATH_BANNERS);     break;
        case 'dn':  navigate(PATH.PATH_DISCLAIMERS); break;
        case 'ds':  navigate(PATH.PATH_DISCLAIMERS); break;
        case 'se':  navigate(PATH.PATH_SIGNATURES);  break;
        case 'sn':  navigate(PATH.PATH_SIGNATURES);  break;
      }
      
    }
    setWait(false);
  },[context.domain]);

  return wait ? <></> : context.error ? <NotFound /> : 
        context.navigationAvailable || isCompany ? 
          <>
            {context.warnUserChanges && <Prompt showDialog={context.warnUserChanges} userConfirmNavigation={userConfirmNavigation} />}
            ${children}
          </> 
        : <Navigate to={PATH.PATH_COMPANY} />
});

const Prompt = ({showDialog, userConfirmNavigation}) => {
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog, useLocation, useNavigate, userConfirmNavigation);
  return (
    <DialogUserWarn open={showPrompt} onClose={confirmNavigation} onCancel={cancelNavigation} onConfirm={confirmNavigation} />
  );
};

const SignatureEditor = () => <Signature/>
const SignatureNew = () => <Signature/>

const Screens = () =>  {
  return (
    <Router>
      <Routes>
        <Route path={PATH.PATH_ERROR} element={<ServerError />} />

        <Route exact path="" element={<Navigate to={PATH.PATH_COMPANY} />}></Route>
        {/* <Route path={PATH_PRIVACY} element={ <PrivacyRoute />}/> */}
        {/* <Route path={PATH_TERMS} element={ <TermsOfServiceRoute />}/> */}
        <Route exact path={PATH.PATH_SIGNATURES} element={<Guard child="sl"><SignatureList/></Guard>}/>
        <Route path={PATH.PATH_SIGNATURES_EDIT} element={<Guard child="se"><SignatureEditor/></Guard>}/>
        <Route path={PATH.PATH_SIGNATURES_NEW} element={<Guard child="sn"><SignatureNew/></Guard>}/>
        <Route exact path={PATH.PATH_DASHBOARD} element={<Guard child="ds"><Dashboard/></Guard>}/>
        <Route exact path={PATH.PATH_COMPANY} element={<Guard isCompany={true}><Company/></Guard>}/>
        <Route exact path={PATH.PATH_USERS} element={<Guard child="us"><Users /></Guard>}/>
        <Route exact path={PATH.PATH_SINGLE_USER} element={<Guard child="su"><SingleUser/></Guard>}/>
        <Route path={PATH.PATH_BANNERS} element={<Guard child="bn"><Banners/></Guard>}/>
        <Route path={PATH.PATH_BANNERS_NEW} element={<Guard child="nb"><NewBanner /></Guard>}/>
        <Route path={PATH.PATH_BANNERS_SHOW} element={<Guard child="bd"><BannerDetails/></Guard>}/>
        <Route path={PATH.PATH_DISCLAIMERS} element={<Guard child="dl"><DisclaimersList/></Guard>}/>
        <Route path={PATH.PATH_DISCLAIMERS_SHOW} element={<Guard child="ds"><DisclaimersShow/></Guard>}/>
        <Route path={PATH.PATH_DISCLAIMERS_NEW} element={<Guard child="dn"><DisclaimersNew/></Guard>}/>
        <Route path={PATH.PATH_DISCLAIMERS_EDIT} element={<Guard child="de"><DisclaimersEdit/></Guard>}/>
        <Route exact path={PATH.PATH_IMAGES} element={<Guard child="im"><Images /></Guard>}/>
        <Route path="/login" element={<Navigate to={PATH.PATH_COMPANY} />} />
        <Route path="*" element={<Guard><NotFound /></Guard>} />
      </Routes>
    </Router>
  );
} 

export default Screens;