import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import { loadBanner } from '../../Actions/Banners';
import Loading from '../../Screens/Loading';

import BannerItemExtended from '../components/BannerItemExtended';

const mapStateToProps = ({banners}) => ({banners});
const mapDispatchToProps = dispatch => bindActionCreators({ loadBanner }, dispatch);


const BannerDetailsContainer = connect(mapStateToProps, mapDispatchToProps)(({bannerPath, loadBanner, banners}) => {
    const [banner, setBanner] = useState(false);
    useEffect(() => setBanner(banners.banner),[banners.banner]);
    useEffect(() => loadBanner(bannerPath),[bannerPath]);
    return banner ?  <BannerItemExtended imageUrl={banner.imageUrl} title={banner.title} link={banner.link} trackable={banner.track} path={bannerPath} signatures={banner.signatures} /> : <Loading />    
});

export default BannerDetailsContainer;
