import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import DialogContent from '@material-ui/core/DialogContent';
import { Dialog } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';


const NewDisclaimerDialog = ({
    open, handleClose, handleCreate
  }) => {
    const [inputError, setInputError] = useState({});
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
  
    const checkError = () => {
      setInputError({...inputError, ...{title: title === ''}, ...{text: text === ''}})
    }
  
    const handleClick = () => {
      checkError();
      if (title !== '' && text !== '') {
        handleClose();
        handleCreate({ title: title, text: text });
      }
    }
  
    return (
      <Dialog
        fullWidth
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        style={{ zIndex: 1301 }}
      >
        <DialogContent dividers>
          <Box marginBottom={2}>
            <TextField
              error={inputError.title}
              value={title}
              onChange={(e) => (setTitle(e.target.value))}
              label="title"
              helperText={inputError.title && "Can't be blank"}
            />
          </Box>
          <Box marginBottom={2}>
            <TextField
              error={inputError.text}
              value={text}
              onChange={(e) => (setText(e.target.value))}
              multiline
              rows={5}
              variant="outlined"
              label="text"
              style={{width: '100%'}}
              helperText={inputError.text && "Can't be blank"}
            />
          </Box>
          <Grid container direction='row' justify='flex-end' alignItems='center'>
            <Button variant="contained" color="primary" style={{marginRight: 8}} onClick={handleClick}>
              Ok
            </Button>
            <Button variant="contained" onClick={handleClose}>
              Close
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
  export default NewDisclaimerDialog;