export const clientId = process.env.REACT_APP_CLIENT_ID;

export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const scopes = [
  'https://www.googleapis.com/auth/admin.directory.domain.readonly',
  'https://www.googleapis.com/auth/admin.directory.group.readonly',
  'https://www.googleapis.com/auth/admin.directory.user.readonly',
  'https://www.googleapis.com/auth/admin.directory.orgunit.readonly',
  'openid',
  'https://www.googleapis.com/auth/userinfo.email',
  'https://www.googleapis.com/auth/userinfo.profile',
  'https://www.googleapis.com/auth/gmail.settings.basic'
];
export const discoveryDocs = [
  'https://www.googleapis.com/discovery/v1/apis/admin/directory_v1/rest',
  'https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest'
];