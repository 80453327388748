import React, { useState } from 'react';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import * as Gapi from '../../Helpers/Gapi';
import NewDisclaimerDialog from './NewDisclaimerDialog';
import NewBannerDialog from './NewBannerDialog';
import Resizer from 'react-image-file-resizer';

const AddNewData = ({
    type, 
    onSaveBannerSucess,
    onSaveImageSucces,
    onSaveDisclaimerSucces
}) => {
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const [inputKey, setInputKey] = useState(0);

    const getBase64 = (file) => new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const getImageSizeFromBase64 = (data) => new Promise((resolve, reject) => {
        let image = new Image();
        image.onload = () => {
            resolve([image.width, image.height]);
        }
        image.onerror = error => reject(error);
        image.src = data;
    });

    const resizeFile = (file, width, height) => new Promise(resolve => {
        let imageRatio = height/width;
        Resizer.imageFileResizer(
            file,
            Math.min(800, width),
            Math.min(800, width) * imageRatio,
            'JPEG',
            60,
            0,
            uri => {
            resolve(uri);
            },
            'base64'
        );
    })

    const handleUploadClick = (type) => async (e) => {
        if (e.target.files[0]) {
            let imageFile = e.target.files[0]
            let uploadImageData = await getBase64(imageFile);
            let [width, height] = await getImageSizeFromBase64(uploadImageData);
            let imageSize = imageFile.size;
            if (imageSize / 1024 > 50) {
                if (window.confirm('Image is too big, max allowed file size is 50KB. Image will be compressed and saved. Do you want to continue?')) {
                    uploadImageData = await resizeFile(imageFile, width, height);
                } else { 
                    alert('Image is too heavy, allowed max file size is 50KB. Please upload new image.');
                    setInputKey(inputKey+1)
                    return
                }
            } 

            if (type === 'banner')
                Gapi.saveBanner(uploadImageData).then((banner) => {
                    onSaveBannerSucess(banner);
            }).catch((err) => console.error(err))
            else
                Gapi.saveImage(uploadImageData).then((image) => {
                    onSaveImageSucces(image);
                }).catch((err) => console.error(err))
        }
    };

    const handleDisclaimerCreate = (disclaimer) => {
        Gapi.saveDisclaimer(disclaimer).then((disclaimer) => {
            onSaveDisclaimerSucces(disclaimer);
        }).then((err) => {
        console.error(err);
        })
    }

    const handleBannerCreate = (banner) => {
        Gapi.saveBanner(banner).then((banner) => {
            onSaveBannerSucess(banner);
        //SettingsReducer.addBanner(dispatch)(banner);
    }).catch((err) => console.error(err))
    }

    if (type === 'disclaimer') {
        return (
        <>
            <Fab color="primary" className={classes.fab} component='span' onClick={() => setOpen(true)}>
            <AddIcon color="white" />
            </Fab>
            <NewDisclaimerDialog open={open} handleClose={() => setOpen(false)} handleCreate={handleDisclaimerCreate} />
        </>
        );
    } else if (type === 'banner') {
        return(
        <>
            <Fab color="primary" className={classes.fab} component='span' onClick={() => setOpen(true)}>
            <AddIcon color="white" />
            </Fab>
            <NewBannerDialog open={open} handleClose={() => setOpen(false)} handleCreate={handleBannerCreate} />
        </>
        );

    }
    else {
        return (
        <label
            htmlFor="contained-button-file"
  
        >
            <input
            accept="image/*"
            style={{ display: "none" }}
            id="contained-button-file"
            key={inputKey}
            multiple
            type="file"
            onChange={handleUploadClick(type)}
            />
            <Fab color="primary" className={classes.fab} component='span'>
            <AddIcon color="white" />
            </Fab>
        </label>
        );
    } 
}

const useStyles = makeStyles({
    fab: {
      right: "32px",
      bottom: "32px",
      position: "absolute",
      width: "56px",
      height: "56px"
    }
  })

export default AddNewData;