const initialState = {
  loading: false,
  banners: [],
  banner: undefined
};

const banners = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING_BANNERS':
      return {...state, loading: true}
    case 'LOAD_BANNERS_SUCCESS':
      return {...state, banners: action.banners, loading: false};
    case 'LOAD_BANNER_SUCCESS':
        return {...state, banner: action.banner, loading: false};
    default:
      return state;
  }
}

export default banners;