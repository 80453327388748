import React from 'react'
import PropTypes from 'prop-types'
import { Grid, List, ListItem, Stack, Typography } from '@mui/material'
import Label from '../../components/Label'
import LoadingButton from '@mui/lab/LoadingButton'
import { getSignatureWithBannerModel } from '../../Helpers/BannerHelper';

function UsersDefaultSignature({defaultSignature, loading, onDisable, onEnable}) {

    const _signature = getSignatureWithBannerModel(defaultSignature);

    console.log("UsersDefaultSignature", _signature)

    return (
        <Grid container spacing={3} mt={2} px={5}>
            <Grid sx={{px: 3, mb: 3}}>
                <Typography variant="subtitle2" sx={{mb: 1}}>
                    Default signature application
                </Typography>
                <Typography variant="body2">
                    Enabled default signature will be automatically applied to selected users. <br />
                    Enabled default signature will be applied also in the next cases: <br />
                    <ul style={{paddingLeft: "18px"}}>
                        <li>when scheduled signatures have not been assigned to anybody;</li>
                        <li>when the assigned scheduled signature expires;</li>
                        <li>when signatures are not created and assigned.</li>
                    </ul>
                    Each user in the user's list above has an indicative tag, whether the default signature is enabled or disabled.
                </Typography>
            </Grid>
            
            <Stack key={defaultSignature.name} direction="row" alignItems="center" spacing={2} sx={{px: 3, width: "100%", mb: 3}}>
                <Grid item xs={5}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <img style={{height: "42px", width: "42px", objectFit: "cover"}} src={_signature.banner != null ? _signature.banner.imageUrl : 'https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty.jpg'} alt="SignPro" />
                        <Stack>
                            <Typography variant="subtitle2" noWrap>{_signature.name}</Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>{_signature.banner != null ? _signature.banner.title : "No banner included"}</Typography>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={4} textAlign="center">
                    <Label
                        color='success'
                    >
                        DEFAULT SIGNATURE
                    </Label>
                </Grid>
                <Grid item xs={3} textAlign="right">
                    <LoadingButton
                        key="disable"
                        loading={loading}
                        variant="contained"
                        sx={{marginRight: 2, color: "#fff"}}
                        color="warning"
                        onClick={onDisable}>
                        {"Disable"}
                    </LoadingButton>
                    <LoadingButton
                        key="enable"
                        loading={loading}
                        variant="contained"
                        onClick={onEnable}>
                        {"Enable"}
                    </LoadingButton>
                </Grid>
            </Stack>
        </Grid>
    )
}

UsersDefaultSignature.propTypes = {
    defaultSignature: PropTypes.object,
    loading: PropTypes.bool,
    onDisable: PropTypes.func,
    onEnable: PropTypes.func
}

export default UsersDefaultSignature

