import React from 'react';
import { SwipeableDrawer } from '@mui/material';

import { useStateValue } from '../Context';

import ConfiguratorTabs from './ConfiguratorTabs';

export default () => {
  const [{ config, component, structure, componentId }, dispatch] = useStateValue();

  const toggleDrawer = (anchor, a) => {
    if (a === false) {
      dispatch({
        type: 'discardChanges'
      });
    }
  };

  const handleClose = () => {
    if ((component !== null && (component.type === "disclaimer" && component.title === "disclaimer")) || component !== null && component.value === "") {
      if (window.confirm("This component has no values, are you sure you want to exit? The component will be removed.")) {
        dispatch({
          type: "removeItem",
          item: { id: componentId },
        })
        toggleDrawer('left', false)
      }
    } else {
      toggleDrawer('left', false)
    }
  }

  return (
    <React.Fragment key={'left'}>
      <SwipeableDrawer
        className='custom-drawer'
        anchor={'left'}
        open={!!config}
        onClose={handleClose}
        onOpen={() => toggleDrawer('left', true)}
      >
        <ConfiguratorTabs
          index={componentId < 1000 ? 1 : 2}
          general={true}
          handleClose={handleClose}
          structure={structure}
          component={component}
        />
      </SwipeableDrawer>
    </React.Fragment>
  );
};
