const initialState = {
  link: '',
  title: '',
  image: '',
  track: false,
  submitting: false
};

const bannerForm = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_BANNER_FORM':
      return !action.bannerForm ? 
        { ...initialState } : 
        {
          ...state,
          image: action.bannerForm.image ? action.bannerForm.image : '',
          link: action.bannerForm.link ? action.bannerForm.link : '',
          title: action.bannerForm.title ? action.bannerForm.title : '',
          track: action.bannerForm.track ? action.bannerForm.track : false
        };
    case 'SUBMITTING_BANNER_FORM_START':
      return {...state, submitting: true};
    case 'SUBMITTING_BANNER_FORM_END':
      return {...state, submitting: false, image: '', link: '', title: '', track: false}; 
    default:
      return state;
  }
}

export default bannerForm;