import React, {useState, useEffect} from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import { setBannerForm, submitBannerForm } from '../../Actions/Banners';
import { warnUserChanges, userConfirmNavigation } from '../../Actions/Navigation';
import BannerForm from '../components/BannerForm';

const mapStateToProps = ({bannerForm, context}) => ({bannerForm, context});
const mapDispatchToProps = dispatch => bindActionCreators({ setBannerForm, submitBannerForm, warnUserChanges, userConfirmNavigation }, dispatch);


const BannerFormContainer = connect(mapStateToProps, mapDispatchToProps)(({context, bannerForm, setBannerForm, submitBannerForm, warnUserChanges, userConfirmNavigation}) => {
    
    return (
        <BannerForm formData={bannerForm} context={context} domainSelected={context.domain} setFormData={setBannerForm} submitForm={submitBannerForm} warnUserChanges={warnUserChanges} userConfirmNavigation={userConfirmNavigation}  />
    )
});

export default BannerFormContainer;
