import * as React from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { visuallyHidden } from '@mui/utils';
import stableSort from '../Helpers/Compatibility';

import Loadsh from 'lodash';
import InfoIcon from '@mui/icons-material/Info';
import { IconButton, Box, Avatar, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
// import Link from '@material-ui/core/Link';
import { PATH_SINGLE_USER } from '../Navigation';
import Label from './Label';

/**
 * EnchancedTableHead Component ( auxiliar component)
 * @param {} param0 
 * @returns 
 */
function EnhancedTableHead({ onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            align={headCell.field === "defaultEnabled" ? "center" : "left"}
            sx={{padding: "0px", paddingLeft: "24px"}}
            key={headCell.field}
            // align={headCell.numeric ? 'right' : 'left'}
            // padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : 'asc'}
              onClick={createSortHandler(headCell.field)}
            >
              {headCell.headerName}
              {orderBy === headCell.field ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell padding="none"></TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.array.isRequired,
};



/**
 * CUSTOM DATA GRID COMPONENT
 * @param {*} param0 
 * @returns 
 */

function CustomDataGrid({columns, rows, onChange, pageSize, showDenseControl=false}, ref) {
  React.useImperativeHandle(
    ref,
    () => ({
        selectAll() { setSelected(rows) },
        unselectAll() { setSelected([]) }
    }),
  )
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(pageSize);

  React.useEffect(() => onChange(selected), [selected]);

  const descendingComparator = (a, b, orderBy) => b[orderBy] < a[orderBy] ? -1 : b[orderBy] > a[orderBy] ? 1 : 0

  const getComparator = (order, orderBy) => order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => setSelected(event.target.checked ? rows : []);
   
  const handleClick = (event, row) => setSelected(event.target.checked ? [row].concat(selected) : selected.filter((item) => item.id !== row.id));
   
  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = event => setDense(event.target.checked);

  const isSelected = row => selected.find((item) => item.id === row.id) ? true : false;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%' }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={columns}
            />
            <TableBody>
              {
                stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row,index) => {
                    const isItemSelected = isSelected(row);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return ( <TableRow
                              hover
                              onClick={(event) => handleClick(event, row)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                            >
                            <TableCell padding="none">
                                <Checkbox color="primary" checked={isItemSelected} inputProps={{'aria-labelledby': labelId,}}/>
                                
                            </TableCell>
                            {columns.map(({field}) => field == "fullName" ? 
                                <TableCell
                                    key={field}
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    padding="none"
                                    sx={{paddingLeft: "24px", paddingY: "4px"}}
                                    align='left'
                                >
                                  <Stack direction="row" alignItems="center">
                                    <Box
                                      sx={{
                                        width: 48,
                                        height: 48,
                                        flexShrink: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        mr: 1
                                      }}
                                    >
                                      <Avatar alt="Avatar" imgProps={{referrerPolicy:"no-referrer"}} src={row.thumbnailPhotoUrl ? row.thumbnailPhotoUrl : null} width={48} height={48} sx={{ mx: 'auto' }} />
                                    </Box>
                                    {Loadsh.get(row,field)}
                                  </Stack>
                                </TableCell> : field == "defaultEnabled" ? 
                                <TableCell
                                  key={field}
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                  sx={{paddingLeft: "24px", paddingY: "4px"}}
                                  align='center'
                                >
                                  <Label color={Loadsh.get(row,field) ? "success" : "warning"}>{Loadsh.get(row,field) ? "Enabled" : "Disabled"}</Label> 
                                </TableCell> :
                                <TableCell
                                    key={field}
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    padding="none"
                                    sx={{paddingLeft: "24px", paddingY: "4px"}}
                                    align='left'
                                >{Loadsh.get(row,field)}</TableCell>
                            )}

                            <TableCell padding="none" align='right'>
                              <IconButton component={Link} to={`/users/${row.id}`}>
                                <InfoIcon />
                              </IconButton>
                            </TableCell>
                            </TableRow>)
                })}

              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {showDenseControl && <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />}
    </Box>
  );
}

export default React.forwardRef(CustomDataGrid);