import React, {useState, useEffect} from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'
import { loadBanners } from '../../Actions/Banners';
import Loading from '../../Screens/Loading';
import BannerGrid from '../components/BannerGrid';

const mapStateToProps = ({banners, context}) => ({banners, context});
const mapDispatchToProps = dispatch => bindActionCreators({ loadBanners }, dispatch);


const BannersContainer = connect(mapStateToProps, mapDispatchToProps)(({context, banners, loadBanners}) => {
    useEffect(() => {
        loadBanners(context.domain);
    }, [loadBanners]);
    
    return (
        <>{banners.loading ? <Loading /> : <BannerGrid banners={banners.banners} />}</>
    )
});

export default BannersContainer;
