import _get from 'lodash/get';
import _omit from 'lodash/omit';

const initialState = {
  signatures: {},
  editor: '',
  editorPreview: '',
  banners: {},
  disclaimers: {},
  images: [],
  user: {},
  company: {},
  companyColors: []
};

function reducer(state, action) {
  switch (action.type) {
    case 'setUserForPreview': {
      const { user } = action.payload
      return {
        ...state,
        user
      };
    }
    case 'setCompanyForPreview': {
      const { company } = action.payload
      return {
        ...state,
        company
      };
    }
    case 'generatePreview': {
      return {
        ...state,
        editorPreview: _parseSignature(state.editor, { ...state.user, ...state.company })
      };
    }
    case 'setEditorSignature': {
      const { signature } = action.payload
      return {
        ...state,
        editor: signature,
        editorPreview: _parseSignature(signature, { ...state.user, ...state.company })
      };
    }
    case 'addSignature': {
      const { key, signature } = action.payload
      state.signatures[key] = _getBannerModel(signature)
      return {
        ...state
      }
    }
    case 'addSignatures': {
      var { signatures, defaultSignatureKey } = action.payload
      signatures = _getBannerModelByList(signatures)
      signatures = _setDefaultFirst(signatures, defaultSignatureKey);
      return {
        ...state,
        signatures
      };
    }
    case 'addBanners': {
      const { banners } = action.payload
      return {
        ...state,
        banners
      };
    }
    case 'addBanner': {
      const { banner } = action.payload
      return {
        ...state,
        banners: { ...state.banners, ...banner }
      };
    }
    case 'addDisclaimer': {
      const { disclaimer } = action.payload
      return {
        ...state,
        disclaimers: { ...state.disclaimers, ...disclaimer }
      };
    }
    case 'updateDisclaimer': {
      const { key, disclaimer } = action.payload
      return {
        ...state,
        disclaimers: {..._omit(state.disclaimers, key), ...{ [key]: disclaimer }}
      };
    }
    case 'deleteDisclaimer': {
      const { key } = action.payload
      return {
        ...state,
        disclaimers: _omit(state.disclaimers, key)
      };
    }
    case 'deleteBanner': {
      const { key } = action.payload
      return {
        ...state,
        banners: _omit(state.banners, key)
      };
    }
    case 'deleteImage': {
      const { key } = action.payload
      return {
        ...state,
        images: _omit(state.images, key)
      };
    }
    case 'addDisclaimers': {
      const { disclaimers } = action.payload
      return {
        ...state,
        disclaimers
      };
    }
    case 'addImages': {
      const { images } = action.payload
      return {
        ...state,
        images
      };
    }
    case 'addImage': {
      const { image } = action.payload
      return {
        ...state,
        images: {...state.images, ...image}
      };
    }
    case 'addCompanyColors': {
      const { colors } = action.payload
      return {
        ...state,
        companyColors: colors
      };
    }
    case 'addCompanyColor': {
      const { color } = action.payload
      return {
        ...state,
        companyColors: [...state.companyColors, color]
      };
    }
    case 'SignOut':
      return {...initialState};  
    default:
      throw new Error();
  }
}

const _getBannerModelByList = (signatures) => {
  if (Object.keys(signatures).length === 0) return [];
  var _signatures = signatures;
  Object.keys(signatures).forEach(key => _signatures[key] = _getBannerModel(_signatures[key]))
  return _signatures;
}

const _getBannerModel = (signature) => {
  const schema = JSON.parse(signature.schema);
  const banner = _findBanner(schema);
  return {...signature, banner: banner};
}

const _setDefaultFirst = (signatures, state) => {
  console.log(signatures, state);
  return signatures;
}

const _findBanner = (theObject) => {
  var result = null;
  if (theObject instanceof Array) {
     for (var i = 0; i < theObject.length; i++) {
        result = _findBanner(theObject[i]);
        if (result) {
           break;
        }
     }
  } else {
     for (var prop in theObject) {
        if (prop === 'name') {
           if (theObject[prop] === "Banner") {
              return theObject.value;
           }
        }
        if (theObject[prop] instanceof Object || theObject[prop] instanceof Array) {
           result = _findBanner(theObject[prop]);
           if (result) {
              break;
           }
        }
     }
  }
  return result;
}

const _parseSignature = (signature, obj) =>
  "<style>figure > img { max-width: 100% }</style>" + signature.replace(/{email}/g, _get(obj, 'primaryEmail', ''))
    .replace(/{firstName}/g, _get(obj, 'name.givenName', ''))
    .replace(/{lastName}/g, _get(obj, 'name.familyName', ''))
    .replace(/{phone}/g, _get(obj, 'phones[0].value', ''))
    .replace(/{mobile}/g, _get(obj, 'phones[0].value', ''))
    .replace(/{address}/g, _get(obj, 'addresses[0].formatted', ''))
    .replace(/{department}/g, _get(obj, 'organizations[0].department', ''))
    .replace(/{jobTitle}/g, _get(obj, 'organizations[0].title', ''))
    .replace(/{orgunit}/g, _get(obj, 'orgUnitPath', ''))
    .replace(/{userTwitter}/g, _get(obj, 'userTwitter', ''))
    .replace(/{customField}/g, _get(obj, 'customField', ''))
    .replace(/{companyName}/g, _get(obj, 'companyName', ''))
    .replace(/{companyLogo}/g, `<figure class="image"><img src=${_get(obj, 'imageUrl', '')} /></figure>`)
    .replace(/{webpage}/g, _get(obj, 'webpage', ''))
    .replace(/{instagram}/g, _get(obj, 'instagram', '') === '' ? '' : `<a href=${_get(obj, 'instagram', '')}><img src="/static/icons/socials/ic_instagram.png" width="24" height="24" /></a>`)
    .replace(/{twitter}/g, _get(obj, 'twitter', '') === '' ? '' : `<a href=${_get(obj, 'twitter', '')}><img src="/static/icons/socials/ic_twitter.png" width="24" height="24" /></a>`)
    .replace(/{facebook}/g, _get(obj, 'facebook', '') === '' ? '' : `<a href=${_get(obj, 'facebook', '')}><img src="/static/icons/socials/ic_facebook.png" width="24" height="24" /></a>`)
    .replace(/{linkedin}/g, _get(obj, 'linkedin', '') === '' ? '' : `<a href=${_get(obj, 'linkedin', '')}><img src="/static/icons/socials/ic_linkedin.png" width="24" height="24" /></a>`)
    .replace(/{youtube}/g, _get(obj, 'youtube', '') === '' ? '' : `<a href=${_get(obj, 'youtube', '')}><img src="/static/icons/socials/ic_youtube.png" width="24" height="24" /></a>`)

const addBanner = (dispatch) =>
  (banner) => dispatch({ type: 'addBanner', payload: { banner }});

const addBanners = (dispatch) =>
  (banners = {}) => dispatch({ type: 'addBanners', payload: { banners }});

const addImage = (dispatch) =>
  (image) => dispatch({ type: 'addImage', payload: { image }});

const addImages = (dispatch) =>
  (images = {}) => dispatch({ type: 'addImages', payload: { images }});

const addDisclaimer = (dispatch) =>
  (disclaimer) => dispatch({ type: 'addDisclaimer', payload: { disclaimer }});

const addDisclaimers = (dispatch) =>
  (disclaimers = {}) => dispatch({ type: 'addDisclaimers', payload: { disclaimers }});

const setEditorSignature = (dispatch) => 
  (signature) => dispatch({ type: 'setEditorSignature', payload: { signature }})

const addSignature = (dispatch) =>
  (signature, key) => dispatch({ type: 'addSignature', payload: { key, signature } })

const addSignatures = (dispatch, ) =>
  (signatures = {}, defaultSignatureKey) => dispatch({ type: 'addSignatures', payload: { signatures, defaultSignatureKey }})

const setUserForPreview = (dispatch) =>
  (user) => dispatch({ type: 'setUserForPreview', payload: { user }})

const setCompanyForPreview = (dispatch) =>
  (company) => dispatch({ type: 'setCompanyForPreview', payload: { company }})

const deleteDisclaimer = (dispatch) =>
  (key) => dispatch({ type: 'deleteDisclaimer', payload: { key }});

const deleteImage = (dispatch) =>
  (key) => dispatch({ type: 'deleteImage', payload: { key }});

const deleteBanner = (dispatch) =>
  (key) => dispatch({ type: 'deleteBanner', payload: { key }});

const updateDisclaimer = (dispatch) =>
  (key, disclaimer) => dispatch({ type: 'updateDisclaimer', payload: { key, disclaimer } })

const addCompanyColors = (dispatch) =>
  (colors) => {
    dispatch({ type: 'addCompanyColors', payload: { colors } })
  }

const generatePreview = (dispatch) =>
  () => dispatch({ type: 'generatePreview' })

export {
  initialState,
  reducer,
  addBanner,
  addBanners,
  addDisclaimer,
  addDisclaimers,
  addImage,
  addImages,
  addSignature,
  addSignatures,
  deleteBanner,
  deleteDisclaimer,
  deleteImage,
  generatePreview,
  setCompanyForPreview,
  setEditorSignature,
  setUserForPreview,
  updateDisclaimer,
  addCompanyColors
};
