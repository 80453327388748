import React from "react";
import { Grid } from '@mui/material';

import NumberInputComponent from "./NumberInputComponent";

const PreviewBox = ({ rowWidth, columnWidths }) => (
  <div
    style={{
      width: "100%",
      border: "1px solid #e5e5e5",
    }}
  >
    <table width="100%" cellPadding={0} cellSpacing={8}>
      <tbody>
        <tr>
          {columnWidths.map((cvalue, index) => (
            <td
              key={index}
              style={{
                border: "1px dashed #8ab7ec",
                backgroundColor: "#d5e8ff",
                boxSizing: "border-box",
                width: (cvalue / rowWidth) * 100 + "%",
                height: 32,
                fontSize: ".75em",
                color: "#8ab7ec",
                textAlign: "center",
              }}
            >
              {index + 1}
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  </div>
);

const PaddingInput = ({ label, value, delta, onChange }) => (
  <Grid item container direction="row" alignItems="center">
    <Grid item xs={6}>
      <h5>{label}</h5>
    </Grid>
    <Grid item xs={6} style={{ textAlign: "right" }}>
      <NumberInputComponent value={value} step={delta} onChange={onChange} />
    </Grid>
  </Grid>
);

export default ({ label, width, widths, colSpacing, onChange }) => {
  const handleChangeValue = (index, value) => {
    let newValues = [...widths];
    newValues[index] = Math.max(value, 30);

    let sum = newValues.reduce((total, value) => (total += value));

    let diff = width - colSpacing * (newValues.length - 1) - sum;
    let idx = (index + 1) % widths.length;
    newValues[idx] = newValues[idx] + diff;
    // setWidths(newValues);
    onChange(newValues, colSpacing);
  };

  const handleChangePadding = (colSpacing) => {
    colSpacing = Math.min(40, Math.max(0, colSpacing)); // [0,40]
    let newValues = [...widths];

    const padding = colSpacing * (newValues.length - 1);
    let sum = newValues.reduce((total, value) => (total += value));

    let diff = width - padding - sum;

    newValues = newValues.map((v) => {
      let delta = Math.round(diff / newValues.length);
      let value = Math.max(30, v + delta);

      return value;
    });
    sum = newValues.reduce((total, v) => (total += v));
    if (sum !== width - padding) {
      let lastElement = newValues[newValues.length - 1];
      lastElement = lastElement + (width - padding - sum);
      newValues[newValues.length - 1] = lastElement;
    }

    // setWidths(newValues);
    // setColSpacing(colSpacing);
    onChange(newValues, colSpacing);
  };

  return (
    <Grid
      container
      direction="column"
      spacing={3}
      style={{ paddingY: 26 }}
    >
      <Grid item>
        <div>{label}</div>
        <PreviewBox rowWidth={width} columnWidths={widths} />
      </Grid>
      <Grid item container direction="column" spacing={1}>
        {widths.map((cvalue, index) => (
          <PaddingInput
            key={index}
            label={`Column ${index + 1}`}
            value={cvalue}
            delta={10}
            onChange={(value) => handleChangeValue(index, value)}
          />
        ))}
        <PaddingInput
          label={`Padding between columns`}
          value={colSpacing}
          delta={10}
          onChange={(value) => handleChangePadding(value)}
        />
      </Grid>
    </Grid>
  );
};
