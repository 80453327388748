import React from 'react'
import PropTypes from 'prop-types'
// material
import { Typography, Grid, Stack  } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { getSignatureWithBannerModel } from '../../Helpers/BannerHelper';
import { fDate } from '../../utils/formatTime';
import Label from '../../components/Label';



UsersSignatureListItem.propTypes = {
    signature: PropTypes.object,
    domain: PropTypes.string,
    buttons: PropTypes.arrayOf(PropTypes.string),
    action: PropTypes.func
};

export default function UsersSignatureListItem({signature,domain,buttons,action}) {
    //   const filterUsersByDomain = (users, domain) => users.filter((user) => user.indexOf(domain) > 0 )
    const [loading, setLoading] = React.useState(false);
    console.log("UsersSignatureListItem", {signature,domain,buttons,action})
    
    const _signature = getSignatureWithBannerModel(signature);
    console.log("UsersSignatureListItem", _signature);

    const actionHandler = (button, signature) => {
        setLoading(true);
        action(button, signature)
    }

    const getValidityPeriod = (signature) => {
        return (signature.from && signature.to) ? `${fDate(signature.from)} - ${fDate(signature.to)}` : ""
    }

  return (
    <Stack key={_signature.name} direction="row" alignItems="center" spacing={2} sx={{px: 3, width: "100%", mb: 3}}>
        <Grid item xs={7}>
            <Stack direction="row" alignItems="center" spacing={2}>
                <img style={{height: "42px", width: "42px", objectFit: "cover"}} src={_signature.banner != null ? _signature.banner.imageUrl : 'https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty.jpg'} alt="SignPro" />
                <Stack>
                    <Typography variant="subtitle2" noWrap>{_signature.name}</Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>{_signature.banner != null ? _signature.banner.title : "No banner included"}</Typography>
                </Stack>
            </Stack>
        </Grid>
        <Grid item xs={3} textAlign="center">
            {getValidityPeriod(_signature)}
        </Grid>
        <Grid item xs={2} textAlign="center">
            {buttons.map((button) =>
                <LoadingButton
                    key={button}
                    loading={loading}
                    variant="contained"
                    onClick={() => actionHandler(button, _signature)}>
                    {button}
                </LoadingButton>
            )}
        </Grid>
    </Stack>
  );
}