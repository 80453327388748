import React from "react";
import { connect } from "react-redux";
import NavSection from "../../components/NavSection";
import { getStripeManagementPortal } from "../../Helpers/Gapi";

const mapStateToProps = ({ context }) => ({ context });

const NavContainer = connect(mapStateToProps)(
  ({ context, navConfig, isShow }) => {
    const subscription = context.company ? context.company.subscription : {};
    const showBilling = subscription && subscription.isStripeCustomer;

    const navigationAvailable = context.navigationAvailable && subscription.licensed;

    const onBillingClick = showBilling ? () => {
      getStripeManagementPortal(context.domain, {
        returnUrl: window.location.href
      }).then((res) => {
        // open res.manageUrl in new tab
        const win = window.open(res.manageUrl, "_blank");
        win.focus();
      });
    } : null;

    return (
      <NavSection
        onBillingClick={onBillingClick}
        navigationAvailable={navigationAvailable}
        navConfig={navConfig}
        isShow={isShow}
      />
    );
  },
);

export default NavContainer;
