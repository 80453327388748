import React from 'react';
import { Card, Button, Typography, Box, Stack, Grid, CardHeader, CardContent, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import Label from '../../components/Label';
import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/edit-fill';

import { Link } from 'react-router-dom';
import { fDate } from '../../utils/formatTime';
import EmptyContent from '../../components/EmptyContent';

const ProductImgStyle = styled('img')({
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute'
  });

export default function BannerItemExtended({imageUrl, title, trackable, link, path, signatures}) {

    const [preparedSignatures, setPreparedSignatures] = React.useState([]);
    const STATUS_LIST = {inactive: "Inactive", live: "Live", unassigned: "Unassigned", scheduled: "Scheduled"}

    const prepareSignatures = () => {
        if (!signatures || signatures.length === 0) setPreparedSignatures([]);
        
        var _preparedSignatures = [];
        signatures.forEach(signature => {
            if (!signature) return;
            var _signature = {...signature, status: getStatus(signature)};
            _signature = _getBannerModel(_signature);
            _preparedSignatures.push(_signature);
        });

        setPreparedSignatures(_preparedSignatures);
    }

    const getStatus = (signature) => {
        if (signature.isDefaultSignature) {
          // DEFAULT SIGNATURE
          if (!signature.usersTotal || signature.usersTotal <= 0) {
            return STATUS_LIST.unassigned;
          } else {
            let found = signature.aliases.find(alias => alias.currentSignature == null || alias.currentSignature == "");
            if (found)
              return STATUS_LIST.live;
            else
              return STATUS_LIST.inactive;
          }
        } else {
          // CUSTOM SIGNATURE
          let currentDate = new Date().setHours(0,0,0,0);
          if (signature.to) {
            let toDate = new Date(signature.to).setHours(0,0,0,0);
            if (currentDate > toDate) {
              return STATUS_LIST.inactive;
            }
          }

          if (!signature.usersTotal || signature.usersTotal <= 0) {
            return STATUS_LIST.unassigned;
          } else {
            if (signature.from) {
              let fromDate = new Date(signature.from).setHours(0,0,0,0);
              if (currentDate < fromDate) {
                return STATUS_LIST.scheduled;
              }
            } 
            
            if (!signature.usersLive || signature.usersLive < 1) {
              return STATUS_LIST.inactive;
            } else {
              return STATUS_LIST.live;
            }
          }
        }
      }
      
    const _getBannerModel = (signature) => {
        const schema = JSON.parse(signature.schema);
        const banner = _findBanner(schema);
        return {...signature, banner: banner};
    }
    
    const _findBanner = (theObject) => {
        var result = null;
        if (theObject instanceof Array) {
            for (var i = 0; i < theObject.length; i++) {
                result = _findBanner(theObject[i]);
                if (result) {
                    break;
                }
            }
        } else {
            for (var prop in theObject) {
                if (prop == 'name') {
                    if (theObject[prop] == "Banner") {
                    return theObject.value;
                    }
                }
                if (theObject[prop] instanceof Object || theObject[prop] instanceof Array) {
                    result = _findBanner(theObject[prop]);
                    if (result) {
                    break;
                    }
                }
            }
        }
        return result;
    }

    const getValidityPeriod = (signature) => {
        return (signature.from && signature.to) ? `${fDate(signature.from)} - ${fDate(signature.to)}` : ""
    }

    React.useEffect(() => {prepareSignatures();},[signatures]);

    return (
        <Grid container spacing={8}>
            <Grid item xs={12}>
                <Card >
                    <Stack spacing={2} p={3}>
                        <Typography variant="h4">
                            {title}
                        </Typography>
                        <Typography>
                            {link}
                        </Typography>
                        <Typography>
                            {trackable && <Label color='success'>
                                Trackable
                            </Label>}
                        </Typography>
                        <Box sx={{ pt: '300px', position: 'relative' }}>
                            <ProductImgStyle alt={title} src={imageUrl} />
                        </Box> 
                        
                    </Stack> 
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="h6">Associated Signatures</Typography>
                        <Grid container spacing={3} mt={5} px={10} justifyContent="center">
                            {preparedSignatures.length ? 
                            <>
                                {preparedSignatures.sort((a, b) => b.isDefaultSignature ? 1 : -1).map(signature => 
                                    <Stack key={signature.name} direction="row" alignItems="center" spacing={2} sx={{px: 3, width: "100%", mb: 3}}>
                                        <Grid item xs={5}>
                                            <Stack direction="row" alignItems="center" spacing={2}>
                                                <img style={{height: "42px", width: "42px", objectFit: "cover"}} src={signature.banner != null ? signature.banner.imageUrl : 'https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty.jpg'} alt="SignPro" />
                                                <Stack>
                                                    <Typography variant="subtitle2" noWrap>{signature.name}</Typography>
                                                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>{signature.banner != null ? signature.banner.link : ""}</Typography>
                                                </Stack>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={3} textAlign="center">
                                            {signature.isDefaultSignature ?
                                                <Label
                                                    color='success'
                                                >
                                                    DEFAULT SIGNATURE
                                                </Label>
                                            : 
                                                <>
                                                    {getValidityPeriod(signature)}
                                                </>
                                            }
                                        </Grid>
                                        <Grid item xs={3} textAlign={"right"}>
                                            <Label
                                                color={signature.status === STATUS_LIST.live ? 'success' : signature.status === STATUS_LIST.inactive ? 'default' : signature.status === STATUS_LIST.unassigned ? 'warning' : 'info'}
                                            >
                                                {signature.status}
                                            </Label>
                                        </Grid>
                                        <Grid item xs={1} textAlign={"right"}>
                                            <Link
                                                to={`/signatures/${signature._id}`}
                                                >
                                                <IconButton color="default" aria-label="edit signature" component="span">
                                                    <Icon icon={editFill} width={20} height={20} />
                                                </IconButton>
                                            </Link>
                                        </Grid>
                                    </Stack>
                                )}
                            </> 
                            : 
                            <>
                                <EmptyContent title="No Associated Signatures" />
                            </>}
                            
                        </Grid>
                    </Box>
                </Card>
            </Grid>
        </Grid>
        
    );
}
